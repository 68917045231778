import React from "react";
import AdminOrderItem from "./components/AdminOrderItem";
import "./styles.scss";
import Earnings from "./components/Earnings";

const AdminOrderList = ({orders}) => {
  if (orders.length < 1) return;

  const renderOrders = () => {
    return orders.map((order, index) => {
      return <AdminOrderItem key={index} order={order} />;
    });
  };

  return (
    <>
      <Earnings orders={orders} />
      <ul className="admin-order-list">{renderOrders()}</ul>
    </>
  );
};

export default AdminOrderList;
