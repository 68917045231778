export const iconConfig = {
  googleIcon: {
    style: {
      fontSize: '40px',
      marginRight: '20px',
    },
    sx: {
      '&:hover': {
        color: 'red',
      },
    },
  },
  appleIcon: {
    style: {
      fontSize: '40px',
    },
    sx: {
      '&:hover': {
        color: 'white',
      },
    },
  },
};
