import MainLayout from "../layouts/MainLayout";

import WithAdminAuth from "./hoc/WithAdminAuth";

import Home from "../scenes/Home";
import Login from "../scenes/Login";
import ManageOrders from "../scenes/ManageOrders";
import OrderCheckout from "../scenes/OrderCheckout";
import TakeOrder from "../scenes/TakeOrder";
import Admin from "../scenes/Admin";
import Kitchen from "../scenes/Kitchen";
import KitchenLayout from "../layouts/KitchenLayout";

export const routesConfig = [
  {
    element: (
      <WithAdminAuth>
        <MainLayout />
      </WithAdminAuth>
    ),
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/commandes/gestion",
        element: <ManageOrders />,
      },
      {
        path: "/commandes/prise-de-commande",
        element: <TakeOrder />,
      },
      {
        path: "/commandes/recap/:order",
        element: <OrderCheckout />,
      },
      {
        path: "/administration",
        element: <Admin />,
      },
    ],
  },
  {
    element: (
      <WithAdminAuth>
        <KitchenLayout />
      </WithAdminAuth>
    ),
    children: [
      {
        path: "/cuisine",
        element: <Kitchen />,
      },
    ],
  },
  {
    path: "/connexion",
    element: <Login />,
  },
];
