import orderTypes from "./orderTypes";

/**
 * Pending orders
 */
export const getPendingOrdersStart = () => ({
  type: orderTypes.GET_PENDING_ORDERS_START,
});
export const setPendingOrders = (orders) => ({
  type: orderTypes.SET_PENDING_ORDERS,
  payload: orders,
});
export const clearOrders = () => ({
  type: orderTypes.CLEAR_ORDERS,
});

/**
 * Current order products
 */
export const addProductToCurrentOrder = (nextCartItem, numberOfItems) => ({
  type: orderTypes.ADD_TO_CART,
  payload: {nextCartItem, numberOfItems},
});
export const removeProductFromCurrentOrder = (item) => ({
  type: orderTypes.REMOVE_ITEM,
  payload: item,
});
export const reduceOrderItem = (item) => ({
  type: orderTypes.REDUCE_ITEM,
  payload: item,
});
export const clearCurrentOrder = () => ({
  type: orderTypes.CLEAR_CURRENT_ORDER,
});
export const deleteOrderStart = (order) => ({
  type: orderTypes.DELETE_ORDER,
  payload: order,
});

/**
 * Current order menus
 */
export const addMenuToOrder = (menu) => ({
  type: orderTypes.ADD_MENU_TO_ORDER,
  payload: menu,
});
export const removeMenuFromOrder = (menu) => ({
  type: orderTypes.REMOVE_MENU_FROM_ORDER,
  payload: menu,
});

/**
 * Current order details
 */
export const setPaymentMethod = (paymentMethod) => ({
  type: orderTypes.SET_PAYMENT_METHOD,
  payload: paymentMethod,
});
export const setOrderNumber = (orderNumber) => ({
  type: orderTypes.SET_ORDER_NUMBER,
  payload: orderNumber,
});
export const getOrderNumberStart = () => ({
  type: orderTypes.GET_ORDER_NUMBER_START,
});
export const saveCurrentOrderUser = (customer) => ({
  type: orderTypes.SAVE_ORDER_CUSTOMER,
  payload: customer,
});

/**
 * Set current order
 */
export const fetchOrderStart = (orderId) => ({
  type: orderTypes.FETCH_ORDER_START,
  payload: orderId,
});
export const setOrder = (order) => ({
  type: orderTypes.SET_ORDER,
  payload: order,
});

/**
 * Order database functions
 */
export const sendOrder = (order) => ({
  type: orderTypes.SEND_ORDER_START,
  payload: order,
});
export const closeOrderStart = (order) => ({
  type: orderTypes.CLOSE_ORDER_START,
  payload: order,
});

/** ANCHOR Customer Sign up actions */
export const signUpStart = (customer) => ({
  type: orderTypes.CUSTOMER_SIGN_UP_START,
  payload: customer,
});

export const signUpSuccess = (user) => ({
  type: orderTypes.CUSTOMER_SIGN_UP_SUCCESS,
  payload: user,
});

export const fetchOrdersByDate = (startDate, endDate) => ({
  type: orderTypes.FETCH_ORDERS_BY_DATE,
  payload: {startDate, endDate},
});

export const setOrdersByDate = (orders) => ({
  type: orderTypes.SET_ORDERS_BY_DATE,
  payload: orders,
});

export const resetDaysOrders = () => ({
  type: orderTypes.RESET_DAYS_ORDERS,
});

export const addRewardToOrder = (reward) => ({
  type: orderTypes.ADD_REWARD_TO_ORDER,
  payload: reward,
});

export const removeRewardFromOrder = () => ({
  type: orderTypes.REMOVE_REWARD_FROM_ORDER,
});
