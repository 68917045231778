import React from "react";
import {MenuOption, MenuTypes} from "../../../../../../../../utilities/enums";
import "./styles.scss";

const RewardOptions = ({
  selectedReward,
  selectedOption,
  setSelectedOption,
  menuOption,
  setMenuOption,
  menu,
  setDrink,
  setDessert,
  setSnack,
  setMeal,
  setAccompaniment,
  setSecondAccompaniment,
  setSandwich,
  setSauce,
}) => {
  const renderRewardOptions = (activeReward) => {
    if (!activeReward) return;

    const menuItems = [];

    if (activeReward.name === MenuTypes.TiMenu) {
      menuItems.push(
        {type: MenuOption.Snack, value: menu.snack},
        {type: MenuOption.Meal, value: menu.meal},
        {type: MenuOption.Accompaniment, value: menu.accompaniment},
        {type: MenuOption.Dessert, value: menu.dessert}
      );
    } else if (activeReward.name === MenuTypes.MenuFilao) {
      menuItems.push(
        {type: MenuOption.Snack, value: menu.snack},
        {type: MenuOption.Meal, value: menu.meal},
        {type: MenuOption.Accompaniment, value: menu.accompaniment},
        {type: MenuOption.Drink, value: menu.drink},
        {type: MenuOption.Dessert, value: menu.dessert}
      );
    } else if (activeReward.name === MenuTypes.MenuKonple) {
      menuItems.push(
        {type: MenuOption.Snack, value: menu.snack},
        {type: MenuOption.Meal, value: menu.meal},
        {type: MenuOption.Accompaniment, value: menu.accompaniment},
        {type: MenuOption.SecondAccompaniment, value: menu.secondAccompaniment},
        {type: MenuOption.Dessert, value: menu.dessert},
        {type: MenuOption.Drink, value: menu.drink}
      );
    } else if (activeReward.name === MenuTypes.MenuSandwich) {
      menuItems.push(
        {type: MenuOption.Sandwich, value: menu.sandwich},
        {type: MenuOption.Accompaniment, value: menu.accompaniment},
        {type: MenuOption.Drink, value: menu.drink},
        {type: MenuOption.Dessert, value: menu.dessert}
      );
    } else if (activeReward.options.length) {
      return activeReward.options.map((option, index) => (
        <li
          key={index}
          className={`reward-option-item ${
            selectedOption === option ? "selected" : ""
          }`}
          onClick={() => setSelectedOption(option)}
        >
          {option}
        </li>
      ));
    }

    return menuItems.map(({type, value}, index) => (
      <li
        key={index}
        className={`reward-option-item ${
          menuOption === type ? "selected" : ""
        }`}
        onClick={() => setMenuOption(type)}
      >
        {value || type}
      </li>
    ));
  };

  return selectedReward ? (
    <ul className="reward-options">{renderRewardOptions(selectedReward)}</ul>
  ) : null;
};

export default RewardOptions;
