import React from 'react';
import {Link} from 'react-router-dom';

import LogoTitle from '../../assets/logo-title.png';
import NavbarMenu from './components/NavbarMenu';

import './styles.scss';

const Navbar = () => {
  return (
    <div className="navbar">
      <Link to="/">
        <img src={LogoTitle} alt="O Ti Filao" className="navbar-logo" />
      </Link>
      <NavbarMenu />
    </div>
  );
};

export default Navbar;
