import userTypes from './userTypes';

const INITIAL_STATE = {
  currentUser: null,
  customerList: [],
  customerLoyalty: {
    loyaltyLevel: null,
    loyaltyPoints: null,
  },
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case userTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
      };
    case userTypes.SIGN_OUT_SUCCESS:
      return {
        ...state,
        currentUser: null,
      };
    case userTypes.SET_CUSTOMER_LIST:
      return {
        ...state,
        customerList: action.payload,
      };
    case userTypes.CLEAR_CUSTOMER_LIST:
      return {
        ...state,
        customerList: [],
      };

    case userTypes.SET_CUSTOMER_LOYALTY:
      return {
        ...state,
        customerLoyalty: action.payload,
      };

    case userTypes.RESET_CUSTOMER_LOYALTY:
      return {
        ...state,
        customerLoyalty: {
          loyaltyLevel: null,
          loyaltyPoints: null,
        },
      };

    default:
      return state;
  }
};

export default userReducer;
