import rewardTypes from './rewardTypes';

const INITIAL_STATE = {
  rewards: [],
};

const rewardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case rewardTypes.SET_REWARDS:
      return {
        ...state,
        rewards: action.payload,
      };
    case rewardTypes.CLEAR_REWARDS:
      return {
        ...state,
        rewards: [],
      };
    default:
      return state;
  }
};

export default rewardReducer;
