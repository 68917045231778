import React from "react";

import ColorCaption from "../../../../components/ColorCaption";

import "./styles.scss";

import KitchenOrderTicket from "./components/KitchenOrderTicket";

const KitchenOrders = ({orders}) => {
  const sortAndRenderPendingOrders = () => {
    orders.sort((a, b) => {
      if (a.scheduled && b.scheduled) {
        return a.scheduled - b.scheduled;
      } else if (a.scheduled) {
        return 1; // a comes before b because b.scheduledFor is null
      } else if (b.scheduled) {
        return -1; // b comes before a because a.scheduledFor is null
      } else {
        return a.orderCreatedDate - b.orderCreatedDate; // both scheduledFor are null, fallback to createdDate
      }
    });

    return orders.map((order, index) => {
      return <KitchenOrderTicket key={index} order={order} />;
    });
  };

  return (
    <div className="kitchen-orders">
      <ColorCaption />

      {orders.length > 0 ? (
        <div className="kitchen-order-list">{sortAndRenderPendingOrders()}</div>
      ) : (
        <p className="no-pending-orders">Aucune commande en attente.</p>
      )}
    </div>
  );
};

export default KitchenOrders;
