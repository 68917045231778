import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";

import TicketModal from "../TicketModal";
import OrderRecapTicket from "./components/OrderRecapTicket/OrderRecapTicket";

import {clearCurrentOrder} from "../../redux/order/orderActions";
import {calculateTotal, isCartEmpty} from "../../utilities/functions";

import {createStructuredSelector} from "reselect";
import {
  selectMenuTotal,
  selectOrderTotal,
} from "../../redux/order/orderSelectors";

import "./styles.scss";
import ConfirmButton from "./components/ConfirmButton";

const mapState = createStructuredSelector({
  cartTotal: selectOrderTotal,
  menuTotal: selectMenuTotal,
});

const OrderRecap = () => {
  const {cartTotal, menuTotal} = useSelector(mapState);
  const {currentOrder} = useSelector((mapState) => mapState.orderData);
  const dispatch = useDispatch();

  const totalPrice = useMemo(
    () => calculateTotal(cartTotal, menuTotal).toFixed(2),
    [cartTotal, menuTotal]
  );

  const [hideModal, setHideModal] = useState(true);

  const toggleModal = () => {
    setHideModal(!hideModal);
  };

  const handleValidate = () => {
    const orderId = currentOrder.documentId
      ? currentOrder.documentId
      : "nouvelle-commande";
  };

  const configModal = {hideModal, toggleModal};

  useEffect(() => {
    const {orderItems, menus} = currentOrder;

    if (orderItems.length < 1 && menus.length < 1) {
      dispatch(clearCurrentOrder());
    }
  }, [currentOrder.orderItems.length, currentOrder.menus.length]);

  const isEmptyCart = isCartEmpty(currentOrder);

  return (
    <div className="order-recap-component">
      <div className="order-recap">
        <ConfirmButton
          classname="order-recap-validate"
          isDisabled={isEmptyCart}
          orderId={
            currentOrder.documentId
              ? currentOrder.documentId
              : "nouvelle-commande"
          }
        />
        <p className="order-recap-price" onClick={() => toggleModal()}>
          <ShoppingBagIcon className="shopping-bag-icon" />
          {totalPrice}€
        </p>
      </div>

      <TicketModal {...configModal}>
        <OrderRecapTicket order={currentOrder} />
      </TicketModal>
    </div>
  );
};

export default OrderRecap;
