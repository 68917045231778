import {
  handleAddMenuToOrder,
  handleAddToCart,
  handleReduceCartItem,
  handleRemoveItem,
  handleRemoveMenu,
} from "./orderHelpers";
import orderTypes from "./orderTypes";

const INITIAL_STATE = {
  pendingOrders: [],
  currentOrder: {
    orderItems: [],
    menus: [],
    customer: null,
    paymentMethod: null,
    orderNumber: null,
    reward: null,
  },
  daysOrders: [],
};

const orderReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    /**
     * Pending orders
     * */
    case orderTypes.SET_PENDING_ORDERS:
      return {
        ...state,
        pendingOrders: action.payload,
      };
    case orderTypes.CLEAR_ORDERS:
      return {
        ...state,
        pendingOrders: [],
      };

    /**
     * Current order products
     */
    case orderTypes.ADD_TO_CART:
      return {
        ...state,
        currentOrder: {
          ...state.currentOrder,
          orderItems: handleAddToCart({
            prevCartItems: state.currentOrder.orderItems,
            nextCartItem: action.payload.nextCartItem,
            numberOfItems: action.payload.numberOfItems,
          }),
        },
      };

    case orderTypes.REMOVE_ITEM:
      return {
        ...state,
        currentOrder: {
          ...state.currentOrder,
          orderItems: handleRemoveItem({
            prevCartItems: state.currentOrder.orderItems,
            itemToRemove: action.payload,
          }),
        },
      };

    case orderTypes.REDUCE_ITEM:
      return {
        ...state,
        currentOrder: {
          ...state.currentOrder,
          orderItems: handleReduceCartItem({
            prevCartItems: state.currentOrder.orderItems,
            cartItemToReduce: action.payload,
          }),
        },
      };

    case orderTypes.CLEAR_CURRENT_ORDER:
      return {
        ...state,
        currentOrder: {
          orderItems: [],
          menus: [],
          customer: null,
          paymentMethod: null,
          orderNumber: null,
          reward: null,
        },
      };

    /**
     * Current order menus
     */
    case orderTypes.ADD_MENU_TO_ORDER:
      return {
        ...state,
        currentOrder: {
          ...state.currentOrder,
          menus: handleAddMenuToOrder(state.currentOrder, action.payload),
        },
      };

    case orderTypes.REMOVE_MENU_FROM_ORDER:
      return {
        ...state,
        currentOrder: {
          ...state.currentOrder,
          menus: handleRemoveMenu(state.currentOrder, action.payload),
        },
      };

    /**
     * Current order details
     */
    case orderTypes.SET_PAYMENT_METHOD:
      return {
        ...state,
        currentOrder: {
          ...state.currentOrder,
          paymentMethod: action.payload,
        },
      };

    case orderTypes.SET_ORDER_NUMBER:
      return {
        ...state,
        currentOrder: {
          ...state.currentOrder,
          orderNumber: action.payload,
        },
      };

    case orderTypes.SAVE_ORDER_CUSTOMER:
      return {
        ...state,
        currentOrder: {
          ...state.currentOrder,
          customer: action.payload,
        },
      };

    /**
     * Set current order
     */
    case orderTypes.SET_ORDER:
      return {
        ...state,
        currentOrder: action.payload,
      };

    case orderTypes.SET_ORDERS_BY_DATE:
      return {
        ...state,
        daysOrders: action.payload,
      };

    case orderTypes.RESET_DAYS_ORDERS:
      return {
        ...state,
        daysOrders: [],
      };

    /**
     * Reward
     */
    case orderTypes.ADD_REWARD_TO_ORDER:
      return {
        ...state,
        currentOrder: {
          ...state.currentOrder,
          reward: action.payload,
        },
      };

    case orderTypes.REMOVE_REWARD_FROM_ORDER:
      return {
        ...state,
        currentOrder: {
          ...state.currentOrder,
          reward: null,
        },
      };
    default:
      return state;
  }
};

export default orderReducer;
