import {firestore} from "../../services/firestore";
import {OrderStatus} from "../../utilities/enums";
import {apiInstance, maxProducts} from "../../utilities/";
import {store} from "../createStore";
import {addOneDay} from "../../utilities/functions";

// export const existingCartItem = ({prevCartItems, nextCartItem}) => {
//   return prevCartItems.find(
//     (cartItem) =>
//       cartItem.documentId === nextCartItem.documentId &&
//       cartItem.selectedField === nextCartItem.selectedField
//   );
// };

const areSelectedFieldsEqual = (field1, field2) => {
  if (!field1 || !field2) return false;
  return (
    field1.salePrice === field2.salePrice &&
    field1.productPrice === field2.productPrice &&
    field1.productQuantity === field2.productQuantity
  );
};

export const existingCartItem = ({prevCartItems, nextCartItem}) => {
  return prevCartItems.find((cartItem) => {
    const isSameDocument = cartItem.documentId === nextCartItem.documentId;

    const isSameField = areSelectedFieldsEqual(
      cartItem.selectedField,
      nextCartItem.selectedField
    );

    return isSameDocument && isSameField;
  });
};

export const handleGetPendingOrders = () => {
  return new Promise((resolve, reject) => {
    let ref = firestore
      .collection("ordersv2")
      .orderBy("orderCreatedDate", "desc");

    ref = ref.where("orderStatus", "==", OrderStatus.Pending);

    ref
      .get()
      .then((snap) => {
        const data = [
          ...snap.docs.map((doc) => {
            return {
              ...doc.data(),
              documentId: doc.id,
            };
          }),
        ];

        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleGetOrder = (orderId) => {
  return new Promise((resolve, reject) => {
    let ref = firestore
      .collection("ordersv2")
      .doc(orderId)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          resolve({...snapshot.data(), documentId: snapshot.id});
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleAddToCart = ({
  prevCartItems,
  nextCartItem,
  numberOfItems,
}) => {
  const quantityIncrement = numberOfItems;
  const cartItemExists = existingCartItem({prevCartItems, nextCartItem});

  if (cartItemExists) {
    if (cartItemExists.cartQuantity >= maxProducts) {
      console.log(
        "vous ne pouvez pas avoir plus de 20 produits de cette sorte. Pour passer une commande spéciale, merci de nous contacter au..."
      );
      return prevCartItems;
    }

    return prevCartItems.map((cartItem) =>
      cartItem.documentId === nextCartItem.documentId &&
      areSelectedFieldsEqual(cartItem.selectedField, nextCartItem.selectedField)
        ? {
            ...cartItem,
            cartQuantity:
              cartItem.cartQuantity + quantityIncrement >= maxProducts
                ? maxProducts
                : cartItem.cartQuantity + quantityIncrement,
          }
        : cartItem
    );
  }
  return [
    ...prevCartItems,
    {
      ...nextCartItem,
      cartQuantity: quantityIncrement,
    },
  ];
};

export const handleRemoveItem = ({prevCartItems, itemToRemove}) => {
  return prevCartItems.filter((item) => item !== itemToRemove);
};

export const handleReduceCartItem = ({prevCartItems, cartItemToReduce}) => {
  const existingCartItem = prevCartItems.find(
    (cartItem) => cartItem.documentId === cartItemToReduce.documentId
  );

  if (existingCartItem.cartQuantity === 1) {
    return prevCartItems.filter(
      (cartItem) => cartItem.documentId !== existingCartItem.documentId
    );
  }

  return prevCartItems.map((cartItem) =>
    cartItem.documentId === existingCartItem.documentId
      ? {
          ...cartItem,
          cartQuantity: cartItem.cartQuantity - 1,
        }
      : cartItem
  );
};

export const handleAddOrder = (order) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("ordersv2")
      .doc()
      .set(order)
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleUpdateOrder = (order) => {
  return new Promise((resolve, reject) => {
    let orderRef = firestore.collection("ordersv2").doc(order.documentId);

    try {
      orderRef.set(order, {merge: true});
      resolve();
    } catch (err) {
      reject(err);
    }
  });
};

export const fetchCustomerByEmail = (customerEmail) => {
  const state = store.getState();
  const customerList = state.userData.customerList;

  const currentCustomer = customerList.find(
    (customer) => customer.email === customerEmail
  );
  return currentCustomer;
};

export const handleAddMenuToOrder = (currentOrder, menuToAdd) => {
  if (currentOrder && currentOrder.menus && currentOrder.menus.length > 0) {
    return [...currentOrder.menus, menuToAdd];
  } else {
    return [menuToAdd];
  }
};

export const handleRemoveMenu = (currentOrder, menuToDelete) => {
  return currentOrder.menus.filter((menu) => menu.id !== menuToDelete);
};

export const registerCustomer = (firstName, lastName, email) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .post("/signin-customer", {email, firstName, lastName})
      .then(async (r) => {
        let customer = {...r.data.customer};
        if (r.status === 201) {
          customer.isNewCustomer = true;
          resolve(customer);
        }
      })
      .catch((err) => {
        if (err.response.data.statusMessage === "auth/email-already-in-use") {
          let customer = {
            email: email,
            firstName: firstName,
            lastName: lastName,
            documentId: err.response.data.uid,
            isNewCustomer: false,
          };

          resolve(customer);
        } else {
          reject(err);
        }
      });
  });
};

export const handleDeleteOrder = (order) => {
  return new Promise((resolve, reject) => {
    let orderRef = firestore.collection("ordersv2").doc(order.documentId);

    try {
      orderRef.delete();
      resolve();
    } catch (err) {
      reject(err);
    }
  });
};

export const handleGetOrdersByDate = (date) => {
  return new Promise((resolve, reject) => {
    const nextStartDay = addOneDay(date.startDate);
    const nextEndDay = date.endDate ? addOneDay(date.endDate) : nextStartDay;

    const fetchedOrders = [];

    firestore
      .collection("ordersv2")
      .where("scheduled", ">", date.startDate)
      .where("scheduled", "<", nextEndDay)
      .get()
      .then((snap) => {
        const data = [
          ...snap.docs.map((doc) => {
            return {
              ...doc.data(),
              documentId: doc.id,
            };
          }),
        ];
        fetchedOrders.push(...data);
      })
      .catch((err) => {
        reject(err);
      });

    firestore
      .collection("ordersv2")
      .where("orderCreatedDate", ">", date.startDate)
      .where("orderCreatedDate", "<", nextEndDay)
      .get()
      .then((snap) => {
        const data = [
          ...snap.docs.map((doc) => {
            return {
              ...doc.data(),
              documentId: doc.id,
            };
          }),
        ];
        const orders = data.filter((order) => !order.scheduled);
        fetchedOrders.push(...orders);
        resolve(fetchedOrders);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// export const getLastOrderNumber = () => {
//   return new Promise((resolve, reject) => {
//     firestore
//       .collection("orders")
//       .orderBy("orderCreatedDate", "desc")
//       .limit(1)
//       .get()
//       .then((snap) => {
//         resolve(snap.docs[0].data().orderNumber);
//       })
//       .catch((err) => reject(err));
//   });
// };

export const getLastOrderNumber = () => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("ordersv2")
      .orderBy("orderCreatedDate", "desc")
      .limit(1)
      .get()
      .then((snap) => {
        if (snap.empty) {
          // If there are no orders, resolve with 0
          resolve(0);
        } else {
          const lastOrder = snap.docs[0].data().orderNumber;
          const onlyNumber = lastOrder.substring(6); // Extract number part
          resolve(parseInt(onlyNumber, 10)); // Resolve with integer
        }
      })
      .catch((err) => reject(err));
  });
};

export const checkOrderNumberExists = async (orderNumber) => {
  const querySnapshot = await firestore
    .collection("ordersv2")
    .where("orderNumber", "==", orderNumber)
    .get();

  return !querySnapshot.empty;
};
