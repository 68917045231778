import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import Homebar from '../../components/Homebar';
import PendingOrderList from './components/PendingOrderList';

import {clearCurrentOrder} from '../../redux/order/orderActions';

import './styles.scss';

const Home = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearCurrentOrder());
  }, []);

  return (
    <div className="homepage">
      <Homebar title={'Commandes en cours'} />
      <PendingOrderList />
    </div>
  );
};

export default Home;
