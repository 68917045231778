import React from "react";
import RedChili from "./assets/piment-rouge.png";
import GreyChili from "./assets/piment-rouge-gris.png";

import "./styles.scss";

const ChiliLevel = ({chiliLevel, size = "normal"}) => {
  const displayChiliLevel = () => {
    return Array(3)
      .fill(null)
      .map((_, index) => {
        if (index < chiliLevel) {
          return (
            <img
              src={RedChili}
              className={`chili chili-${size} red-chili`}
              key={index}
              alt=""
            />
          );
        } else {
          return (
            <img
              src={GreyChili}
              className={`chili chili-${size} grey-chili`}
              key={index}
              alt=""
            />
          );
        }
      });
  };

  return <div className="chili-level">{displayChiliLevel()}</div>;
};

export default ChiliLevel;
