import React from "react";
import {MenuPrice, MenuTypes} from "../../../../../../../../utilities/enums";

import "./styles.scss";

const MenuChoice = ({setMenuType, setMenuPrice, resetMenu}) => {
  const handleClick = (menuType) => {
    setMenuType(menuType);
    setMenuPrice(handleMenuPrice(menuType));
    resetMenu();
  };

  const handleMenuPrice = (menuType) => {
    switch (menuType) {
      case MenuTypes.MenuSandwich:
        return MenuPrice.MenuSandwich;
      case MenuTypes.TiMenu:
        return MenuPrice.TiMenu;
      case MenuTypes.MenuFilao:
        return MenuPrice.MenuFilao;
      case MenuTypes.MenuKonple:
        return MenuPrice.MenuKonple;
      case MenuTypes.SeaMenu:
        return MenuPrice.SeaMenu;
      default:
        return 0;
    }
  };

  return (
    <div className="menu-choice">
      <button onClick={() => handleClick(MenuTypes.TiMenu)}>
        <p>{MenuTypes.TiMenu}</p>
        <p>{MenuPrice.TiMenu.toFixed(2)}€</p>
      </button>
      <button onClick={() => handleClick(MenuTypes.MenuSandwich)}>
        <p>{MenuTypes.MenuSandwich}</p>
        <p>{MenuPrice.MenuSandwich.toFixed(2)}€</p>
      </button>
      <button onClick={() => handleClick(MenuTypes.MenuFilao)}>
        <p>{MenuTypes.MenuFilao}</p>
        <p>{MenuPrice.MenuFilao.toFixed(2)}€</p>
      </button>
      <button onClick={() => handleClick(MenuTypes.MenuKonple)}>
        <p>{MenuTypes.MenuKonple}</p>
        <p>{MenuPrice.MenuKonple.toFixed(2)}€</p>
      </button>
      <button onClick={() => handleClick(MenuTypes.SeaMenu)}>
        <p>{MenuTypes.SeaMenu}</p>
        <p>{MenuPrice.SeaMenu.toFixed(2)}€</p>
      </button>
    </div>
  );
};

export default MenuChoice;
