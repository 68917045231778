import moment from "moment";
import {Categories, MenuSnackQuantity, MenuTypes} from "./enums";
import {getLastOrderNumber} from "../redux/order/orderHelpers";

export const formatDate = (date) => {
  return moment(date.toDate()).format("DD/MM/YYYY à HH:mm");
};

export const formatDateOnly = (date) => {
  return moment(date.toDate()).format("DD/MM/YYYY");
};

export const formatHour = (date) => {
  return moment(date.toDate()).format("HH:mm");
};

export const renderProductTitle = (product) => {
  const {category, name, selectedField} = product;

  let titleContent;

  switch (category) {
    case Categories.snack:
    case Categories.sweet:
      titleContent = (
        <div className="ticket-order-item-title">
          <span className="product-quantity">
            {selectedField.productQuantity}
          </span>{" "}
          {name}
        </div>
      );
      break;

    case Categories.meal:
    case Categories.accompaniment:
      titleContent = (
        <div className="ticket-order-item-title">
          <span className="product-quantity">
            {name} ({selectedField.productWeight}g)
          </span>
        </div>
      );
      break;

    default:
      titleContent = <div className="ticket-order-item-title">{name}</div>;
  }

  return titleContent;
};

export const renderProductPrice = (product) => {
  const {selectedField, cartQuantity} = product;
  const {salePrice, productPrice} = selectedField;

  const totalPrice = (salePrice || productPrice) * cartQuantity;
  const formattedTotalPrice = totalPrice.toFixed(2) + "€";

  if (salePrice) {
    const oldTotalPrice = (productPrice * cartQuantity).toFixed(2) + "€";
    return (
      <>
        {" "}
        <span className="old-price">{oldTotalPrice}</span>{" "}
        <span className="sale-price">{formattedTotalPrice}</span>
      </>
    );
  }

  return formattedTotalPrice;
};

export const generatePassword = (passwordLength) => {
  let charset = "";
  let newPassword = "";

  const useSymbols = false;
  const useNumbers = true;
  const useLowerCase = true;
  const useUpperCase = true;

  if (useSymbols) charset += "!@#$%^&*()";
  if (useNumbers) charset += "0123456789";
  if (useLowerCase) charset += "abcdefghijklmnopqrstuvwxyz";
  if (useUpperCase) charset += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  for (let i = 0; i < passwordLength; i++) {
    newPassword += charset.charAt(Math.floor(Math.random() * charset.length));
  }

  return newPassword;
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const generateShortId = () => {
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const randomLetter = alphabet[Math.floor(Math.random() * alphabet.length)];
  const randomNumber1 = Math.floor(Math.random() * 10);
  const randomNumber2 = Math.floor(Math.random() * 10);

  return `${randomLetter}${randomNumber1}${randomNumber2}`;
};

export const getYearAndMonth = () => {
  const now = new Date();
  const year = now.getFullYear().toString().slice(-2); // Last two digits of the year
  const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Month with leading zero
  return year + month;
};

export const formatOrderNumber = (source, orderDate, orderNumber) => {
  const orderNumberToString = String(orderNumber).padStart(4, "0");
  return `${source}${orderDate}-${orderNumberToString}`;
};

export const extractOrderNumber = (orderNumber) => {
  if (!orderNumber) return;
  const parts = orderNumber.split("-");
  return parts[1]; // Returns the XXXX part
};

export const checkIfUserIsAdmin = (currentUser) => {
  if (!currentUser || !Array.isArray(currentUser.userRoles)) return false;
  const {userRoles} = currentUser;

  if (userRoles.includes("admin")) return true;

  return false;
};

export const returnNumberOfSnacks = (menuType) => {
  switch (menuType) {
    case MenuTypes.TiMenu:
      return MenuSnackQuantity.TiMenu;
    case MenuTypes.MenuFilao:
      return MenuSnackQuantity.MenuFilao;
    case MenuTypes.MenuKonple:
      return MenuSnackQuantity.MenuKonple;
    case MenuTypes.SeaMenu:
      return MenuSnackQuantity.SeaMenu;
    default:
      return 0;
  }
};

export const renderProductNameAndQty = (product, menuType) => {
  if (!product) return null;

  if (menuType === MenuTypes.MenuSandwich) {
    // Special handling for sandwich menus
    return (
      <>
        <p>Sandwich: {product.name}</p>
        {product.sauce && <p>Sauce: {product.sauce}</p>}
        {product.drink && <p>Drink: {product.drink.name}</p>}
      </>
    );
  }

  if (Array.isArray(product)) {
    // Handling arrays of snacks
    const productCounts = product.reduce((acc, item) => {
      acc[item.name] = (acc[item.name] || 0) + 1;
      return acc;
    }, {});

    return Object.entries(productCounts).map(([name, count]) => (
      <p key={name}>
        {count} {name}
      </p>
    ));
  }

  // Handling single products
  if (typeof product === "object") {
    if (product.category === Categories.snack) {
      return (
        <p>
          {returnNumberOfSnacks(menuType)} {product.name}
        </p>
      );
    }
    return <p>{product.name}</p>;
  }

  // Handling strings
  return <p>{product}</p>;
};

export const isCartEmpty = (currentOrder) => {
  if (currentOrder.orderItems.length < 1 && currentOrder.menus.length < 1) {
    return true;
  }
  return false;
};

export const calculateTotal = (cartTotal, menuTotal) => {
  let newTotal = 0;
  if (cartTotal && cartTotal > 0) {
    newTotal += cartTotal;
  }

  if (menuTotal && menuTotal > 0) {
    newTotal += menuTotal;
  }

  return parseFloat(newTotal);
};

export const addOneDay = (date = new Date()) => {
  const dateCopy = new Date(date);

  dateCopy.setDate(dateCopy.getDate() + 1);

  return dateCopy;
};

export const stringToSlug = (string) => {
  return string
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");
};

export const checkIfCategoryHasProducts = (category, productList) => {
  return productList.filter((product) => product.category === category);
};

export const isToday = (orderDate) => {
  const timestampInMilliseconds = orderDate.seconds * 1000;

  // Create a Date object
  const dateToCheck = new Date(timestampInMilliseconds);

  if (isNaN(dateToCheck)) {
    console.log("NaN");
  }

  dateToCheck.setHours(0, 0, 0, 0);

  // Get today's date at midnight
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  // Check if the dates are the same
  const isToday = dateToCheck.getTime() === today.getTime();
  return isToday;
};

export const isLessThanXMinutes = (minutes, timestamp) => {
  const milliseconds =
    timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;

  // Create a Date object from the milliseconds
  const date = new Date(milliseconds);

  // Get the current time
  const currentTime = new Date();

  // Calculate the difference in milliseconds between the current time and the provided date
  const differenceInMilliseconds = date - currentTime;

  // Convert milliseconds to minutes
  const differenceInMinutes = differenceInMilliseconds / (1000 * 60);

  // Check if the difference is less than 30 minutes
  return differenceInMinutes < minutes;
};

export const isEven = (number) => number % 2 === 0;

// export const getAllProductsOfOrder = (order) => {
//   const listOfProducts = {};

//   const updateProductQuantity = (productName, category, quantity) => {
//     if (listOfProducts[productName]) {
//       listOfProducts[productName].quantity += quantity;
//     } else {
//       listOfProducts[productName] = {quantity, category};
//     }
//   };

//   const isValidItem = (item) => {
//     return typeof item === "object" && item !== null;
//   };

//   order.orderItems.forEach((item) => {
//     const quantity =
//       parseInt(item.cartQuantity) * (item.selectedField.productQuantity || 1);

//     if (
//       item.category === Categories.meal ||
//       item.category === Categories.accompaniment
//     ) {
//       const name = `${item.name} (${item.selectedField.productWeight}g)`;
//       updateProductQuantity(name, item.category, quantity);
//     } else {
//       updateProductQuantity(item.name, item.category, quantity);
//     }
//   });

//   order.menus.forEach((menu) => {
//     const menuQuantities = {
//       [MenuTypes.TiMenu]: MenuSnackQuantity.TiMenu,
//       [MenuTypes.MenuFilao]: MenuSnackQuantity.MenuFilao,
//       [MenuTypes.MenuKonple]: MenuSnackQuantity.MenuKonple,
//     };

//     if (isValidItem(menu.snack)) {
//       if (Array.isArray(menu.snack)) {
//         // If menu.snack is an array, iterate over each snack item
//         menu.snack.forEach((snack) => {
//           if (isValidItem(snack)) {
//             updateProductQuantity(snack.name, Categories.snack, 1);
//           }
//         });
//       } else {
//         const quantity = menuQuantities[menu.type] || 1;
//         updateProductQuantity(menu.snack.name, Categories.snack, quantity);
//       }
//     }

//     const menuItems = [
//       {item: menu.meal, category: Categories.meal},
//       {item: menu.accompaniment, category: Categories.accompaniment},
//       {item: menu.secondAccompaniment, category: Categories.accompaniment},
//       {item: menu.dessert, category: Categories.dessert},
//       {item: menu.drink, category: Categories.drink},
//       {item: menu.sandwich, category: Categories.sandwich},
//       {item: menu.seasoning, category: Categories.seasoning},
//     ];

//     menuItems.forEach(({item, category}) => {
//       if (isValidItem(item)) {
//         updateProductQuantity(item.name, category, 1);
//       }
//     });
//   });

//   return listOfProducts;
// };

export const getAllProductsOfOrder = (orders) => {
  const listOfProducts = {};

  const updateProductQuantity = (productName, category, quantity) => {
    if (listOfProducts[productName]) {
      listOfProducts[productName].quantity += quantity;
    } else {
      listOfProducts[productName] = {quantity, category};
    }
  };

  const isValidItem = (item) => {
    return typeof item === "object" && item !== null;
  };

  const processOrder = (order) => {
    order.orderItems.forEach((item) => {
      const quantity =
        parseInt(item.cartQuantity) * (item.selectedField.productQuantity || 1);

      if (
        item.category === Categories.meal ||
        item.category === Categories.accompaniment
      ) {
        const name = `${item.name} (${item.selectedField.productWeight}g)`;
        updateProductQuantity(name, item.category, quantity);
      } else {
        updateProductQuantity(item.name, item.category, quantity);
      }
    });

    order.menus.forEach((menu) => {
      const menuQuantities = {
        [MenuTypes.TiMenu]: MenuSnackQuantity.TiMenu,
        [MenuTypes.MenuFilao]: MenuSnackQuantity.MenuFilao,
        [MenuTypes.MenuKonple]: MenuSnackQuantity.MenuKonple,
      };

      if (isValidItem(menu.snack)) {
        if (Array.isArray(menu.snack)) {
          // If menu.snack is an array, iterate over each snack item
          menu.snack.forEach((snack) => {
            if (isValidItem(snack)) {
              updateProductQuantity(snack.name, Categories.snack, 1);
            }
          });
        } else {
          const quantity = menuQuantities[menu.type] || 1;
          updateProductQuantity(menu.snack.name, Categories.snack, quantity);
        }
      }

      const menuItems = [
        {item: menu.meal, category: Categories.meal},
        {item: menu.accompaniment, category: Categories.accompaniment},
        {item: menu.secondAccompaniment, category: Categories.accompaniment},
        {item: menu.dessert, category: Categories.dessert},
        {item: menu.drink, category: Categories.drink},
        {item: menu.sandwich, category: Categories.sandwich},
        {item: menu.seasoning, category: Categories.seasoning},
      ];

      menuItems.forEach(({item, category}) => {
        if (isValidItem(item)) {
          updateProductQuantity(item.name, category, 1);
        }
      });
    });
  };

  if (Array.isArray(orders)) {
    orders.forEach((order) => processOrder(order));
  } else {
    processOrder(orders);
  }

  return listOfProducts;
};

const translations = {
  [Categories.snack]: "de snack",
  [Categories.meal]: "de plat",
  [Categories.accompaniment]: "d'accompagnement",
  [Categories.dessert]: "de dessert",
  [Categories.drink]: "de boisson",
  [Categories.sandwich]: "de sandwich",
  [Categories.seasoning]: "d'assaisonnement",
  secondAccompaniment: "de second accompagnement",
};

export const translateCategory = (category) => {
  return translations[category] || category;
};

export const translateCategoryName = (category) => {
  const translations = {
    snack: "Snack",
    meal: "Plat",
    accompaniment: "Accompagnement",
    dessert: "Dessert",
    drink: "Boisson",
    sandwich: "Sandwich",
    seasoning: "Assaisonnement",
    jar: "Bocaux",
  };

  return translations[category] || category;
};
