import React, {useState} from "react";
import {useDispatch} from "react-redux";

import ProductPrice from "../ProductPrice";
import Halal from "../../../../../../../../assets/halal.png";
import Veggie from "../../../../../../../../assets/veggie.png";

import {
  deleteProductStart,
  editProductStart,
} from "../../../../../../../../redux/product/productActions";

import "./styles.scss";
import Modal from "../../../../../../../../components/Modal";

const AdminProductCard = ({product, openModal, openSaleModal}) => {
  const dispatch = useDispatch();

  const [hideModal, setHideModal] = useState(true);

  const toggleModal = () => {
    setHideModal(!hideModal);
  };
  const configModal = {hideModal, toggleModal};

  const OpenEditModal = () => {
    openModal(product);
  };

  const OpenSalePriceModal = () => {
    openSaleModal(product);
  };

  const setOffer = () => {
    if (!product) return;

    const newProduct = {...product, offer: !product.offer};

    dispatch(editProductStart(newProduct));
  };

  const setForSale = () => {
    if (!product) return;

    const newProduct = {...product, forSale: !product.forSale};

    dispatch(editProductStart(newProduct));
  };

  const handleYes = () => {
    console.log("delete product");
    dispatch(deleteProductStart(product.documentId));
    toggleModal();
  };

  return (
    <li className="admin-product-card">
      <div className="admin-product-card-image">
        <img
          src={product.thumbnail}
          alt={product.name}
          className="card-item-img"
        />
        <div className="food-habits-icons">
          {product.isHalal && (
            <img src={Halal} alt="halal" className="halal-icon" />
          )}

          {product.isVeggie && (
            <img src={Veggie} alt="veggie" className="veggie-icon" />
          )}
        </div>
      </div>

      <div className="admin-product-card-name" onClick={OpenEditModal}>
        <h5 className="card-item-name">{product.name}</h5>
      </div>

      <div className="admin-product-card-status" onClick={() => setForSale()}>
        {product.forSale ? (
          <span className="for-sale">En vente</span>
        ) : (
          <span className="not-for-sale">Pas en vente</span>
        )}
      </div>

      <div className="admin-product-card-price" onClick={OpenSalePriceModal}>
        <ProductPrice product={product} />
      </div>

      <div className="admin-product-card-buttons">
        <button className="offer" onClick={() => setOffer()}>
          {product.offer ? <span>&#10030;</span> : <span>&#10032;</span>}
        </button>
        <button className="remove" onClick={() => toggleModal()}>
          &#10006;
        </button>
      </div>

      <Modal {...configModal}>
        <div className="delete-product-modal-container">
          <p>Voulez-vous vraiment supprimer ce produit ?</p>
          <div className="buttons">
            <button className="yes-btn" onClick={() => handleYes()}>
              Oui
            </button>
            <button className="no-btn" onClick={() => toggleModal()}>
              Non
            </button>
          </div>
        </div>
      </Modal>
    </li>
  );
};

export default AdminProductCard;
