import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import {firebaseConfig} from "./config";

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

import {isProductThumbnailUsed} from "../../redux/product/productHelpers";

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const GoogleProvider = new firebase.auth.GoogleAuthProvider();

export const handleUserProfile = async ({userAuth, additionalData}) => {
  if (!userAuth) return;

  const {uid} = userAuth;
  const userRef = firestore.doc(`users/${uid}`);
  const snapshot = await userRef.get();

  if (!snapshot.exists) {
    const {displayName, email} = userAuth;
    const timestamp = new Date();
    const userRoles = ["member"];

    const firstName = additionalData.firstName ? additionalData.firstName : "";
    const lastName = additionalData.lastName ? additionalData.lastName : "";
    const fromSocial = false;

    try {
      await userRef.set({
        displayName,
        firstName,
        lastName,
        fromSocial,
        email,
        createdDate: timestamp,
        userRoles,
        loyaltyLevel: 1,
        loyaltyPoints: 0,
        canPayOnSpot: false,
        ...additionalData,
      });
    } catch (error) {
      console.log(error);
    }
  } else {
    console.log("deja inscrit");
    try {
      await userRef.update({
        ...additionalData,
      });
    } catch (error) {
      console.log(error);
    }
  }
  return userRef;
};

export const handleAdminLogin = async ({userAuth}) => {
  if (!userAuth) return;

  const {uid} = userAuth;
  const userRef = firestore.doc(`users/${uid}`);
  const snapshot = await userRef.get();

  if (snapshot.exists) {
    if (snapshot.data().userRoles.includes("admin")) {
      return userRef;
    }
  }
};

export const uploadImage = (path, file) => {
  return new Promise((resolve, reject) => {
    const storage = getStorage();
    const storagePath = "images/" + path + "/" + file.name;

    const storageRef = ref(storage, storagePath);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // progrss function ....
        // const progress =
        //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log('Upload is ' + progress + '% done');
      },
      (error) => {
        // error function ....
        reject(error);
      },
      () => {
        // complete function ....
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          // console.log('File available at', downloadURL);
          resolve(downloadURL);
        });
      }
    );
  });
};

export const removeImage = async (refUrl) => {
  const storage = getStorage();
  const imageRef = ref(storage, refUrl);

  isProductThumbnailUsed(refUrl).then(() => {
    console.log("ok delete");
  });

  // if (isProductThumbnailUsed(refUrl)) return;

  // console.log('been deleted');

  // deleteObject(imageRef).catch((error) => {
  //   console.log('Failed to delete image: ', error);
  // });
};
