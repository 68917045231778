import {firestore} from "../../services/firestore";

export const getAllCustomers = () => {
  return new Promise((resolve, reject) => {
    const userCollection = firestore.collection("users");

    userCollection.get().then((snapshot) => {
      const data = [
        ...snapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            documentId: doc.id,
            id: doc.id,
          };
        }),
      ];
      resolve(data);
    });
  });
};

export const addLoyaltyPoints = (userId, pointsToAdd) => {
  return new Promise((resolve, reject) => {
    try {
      const userRef = firestore.collection("users").doc(userId);

      userRef.get().then((snapshot) => {
        if (snapshot.exists) {
          let customerPoints = 0;
          const customer = {...snapshot.data()};
          customerPoints = customer.loyaltyPoints;

          const loyaltyPoints = customerPoints + pointsToAdd;

          if (loyaltyPoints > 200) loyaltyPoints = 200;

          userRef.set({loyaltyPoints}, {merge: true});
          resolve();
        }
        reject("customer not found");
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const removeLoyaltyPoints = (userId, pointsToRemove) => {
  return new Promise((resolve, reject) => {
    try {
      const userRef = firestore.collection("users").doc(userId);

      userRef.get().then((snapshot) => {
        if (snapshot.exists) {
          let customerPoints = 0;
          const customer = {...snapshot.data()};
          customerPoints = customer.loyaltyPoints;

          const loyaltyPoints = customerPoints - pointsToRemove;
          const areLoyaltyPointsBeingUsed = false;

          userRef.set(
            {loyaltyPoints, areLoyaltyPointsBeingUsed},
            {merge: true}
          );
          resolve();
        }
        reject("customer not found");
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const handleSetLoyaltyPointsUsage = (userId) => {
  return new Promise((resolve, reject) => {
    try {
      const userRef = firestore.collection("users").doc(userId);
      const areLoyaltyPointsBeingUsed = true;

      userRef.set({areLoyaltyPointsBeingUsed}, {merge: true});
      resolve();
    } catch (err) {
      reject(err);
    }
  });
};
