import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {editRewardStart} from "../../../../../../../../redux/reward/rewardActions";
import Modal from "../../../../../../../../components/Modal";
import "./styles.scss";

const AdminRewardCard = ({reward, openModal}) => {
  const dispatch = useDispatch();
  const [hideModal, setHideModal] = useState(true);

  const toggleModal = () => {
    setHideModal(!hideModal);
  };
  const configModal = {hideModal, toggleModal};

  const openEditModal = () => {
    openModal(reward);
  };

  const toggleEnable = () => {
    if (!reward) return;

    const newReward = {...reward, enabled: !reward.enabled};
    dispatch(editRewardStart(newReward));
  };

  const handleYes = () => {
    console.log("delete reward");
    // dispatch(editRewardStart({...reward, deleted: true}));
    toggleModal();
  };

  return (
    <li className="admin-reward-card">
      <div className="admin-reward-card-image">
        <img
          src={reward.thumbnail}
          alt={reward.name}
          className="card-item-img"
        />
      </div>

      <div className="admin-reward-card-name" onClick={openEditModal}>
        <h5 className="card-item-name">
          {reward.quantity > 1 && `${reward.quantity} `}
          {reward.name}
        </h5>
      </div>

      <div className="admin-reward-card-status" onClick={() => toggleEnable()}>
        {reward.enabled ? (
          <span className="enabled">Disponible</span>
        ) : (
          <span className="disabled">Non disponible</span>
        )}
      </div>

      <div className="admin-reward-card-buttons">
        <button className="edit" onClick={openEditModal}>
          Modifier
        </button>
        <button className="remove" onClick={() => toggleModal()}>
          &#10006;
        </button>
      </div>

      <Modal {...configModal}>
        <div className="delete-reward-modal-container">
          <p>Voulez-vous vraiment supprimer cette récompense ?</p>
          <div className="buttons">
            <button className="yes-btn" onClick={handleYes}>
              Oui
            </button>
            <button className="no-btn" onClick={toggleModal}>
              Non
            </button>
          </div>
        </div>
      </Modal>
    </li>
  );
};

export default AdminRewardCard;
