import React from "react";
import {Outlet} from "react-router-dom";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

const MainLayout = (props) => {
  return (
    <>
      <Navbar />
      <div className="main">{props.children ? props.children : <Outlet />}</div>
      {/* <Footer /> */}
    </>
  );
};

export default MainLayout;
