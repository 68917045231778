import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {checkIfUserIsAdmin} from '../functions';

const useAdminAuth = () => {
  const navigate = useNavigate();
  const {currentUser} = useSelector((mapState) => mapState.userData);

  useEffect(() => {
    if (!checkIfUserIsAdmin(currentUser)) {
      navigate('/connexion');
    }
  }, [currentUser]);

  return currentUser;
};

export default useAdminAuth;
