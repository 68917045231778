import React, {useEffect, useState} from "react";
import {
  MenuSnackQuantity,
  MenuTypes,
  PaymentMethodEnum,
} from "../../../../../../../../utilities/enums";

import "./styles.scss";

const Earnings = ({orders}) => {
  const [totalEarnings, setTotalEarnings] = useState("0");
  const [creditCardEarnings, setCreditCardEarnings] = useState("0");
  const [cashEarnings, setCashEarnings] = useState("0");
  const [applePayEarnings, setApplePayEarnings] = useState("0");
  const [gPayEarnings, setGPayEarnings] = useState("0");
  const [paypalEarnings, setPaypalEarnings] = useState("0");
  const [unpaidTotal, setUnpaidTotal] = useState("0");

  const [totalRevenue, setTotalRevenue] = useState("0");
  const [totalCost, setTotalCost] = useState("0");
  const [totalMargin, setTotalMargin] = useState("0");
  const [marginPercentage, setMarginPercentage] = useState("0");

  useEffect(() => {
    let total = 0;
    let creditCardTotal = 0;
    let cashTotal = 0;
    let applePayTotal = 0;
    let gPayTotal = 0;
    let paypalTotal = 0;
    let unpaidTotal = 0;

    setUnpaidTotal("0");
    setTotalEarnings("0");
    setCreditCardEarnings("0");
    setCashEarnings("0");
    setApplePayEarnings("0");
    setGPayEarnings("0");
    setPaypalEarnings("0");

    orders.map((order) => {
      if (!order.orderPaid) {
        unpaidTotal += order.orderTotal;
        setUnpaidTotal(unpaidTotal.toFixed(2));
        return;
      }
      if (order.paymentMethod === PaymentMethodEnum.CreditCard) {
        creditCardTotal += order.orderTotal;
      } else if (order.paymentMethod === PaymentMethodEnum.Cash) {
        cashTotal += order.orderTotal;
      } else if (order.paymentMethod === PaymentMethodEnum.ApplePay) {
        applePayTotal += order.orderTotal;
      } else if (order.paymentMethod === PaymentMethodEnum.GPay) {
        gPayTotal += order.orderTotal;
      } else if (order.paymentMethod === PaymentMethodEnum.Paypal) {
        paypalTotal += order.orderTotal;
      }
      total += order.orderTotal;
    });

    const margins = calculateMargins(orders);

    setTotalRevenue(margins.totalRevenue);
    setTotalCost(margins.totalCost);
    setTotalMargin(margins.totalMargin);
    setMarginPercentage(margins.marginPercentage);

    setTotalEarnings(total.toFixed(2));
    setCreditCardEarnings(creditCardTotal.toFixed(2));
    setCashEarnings(cashTotal.toFixed(2));
    setApplePayEarnings(applePayTotal.toFixed(2));
    setGPayEarnings(gPayTotal.toFixed(2));
    setPaypalEarnings(paypalTotal.toFixed(2));
  }, [orders]);

  const calculateMargins = (orders) => {
    let totalRevenue = 0;
    let totalCost = 0;

    orders.forEach((order) => {
      order.orderItems.forEach((item) => {
        const productPrice =
          item.selectedField.salePrice && item.selectedField.salePrice > 0
            ? item.selectedField.salePrice
            : item.selectedField.productPrice;

        const productCost = item.selectedField.productCost || 0;

        const itemRevenue = productPrice * item.cartQuantity;
        const itemCost = productCost * item.cartQuantity;

        totalRevenue += itemRevenue;
        totalCost += itemCost;
      });

      order.menus.forEach((menu) => {
        const menuPrice = menu.price;
        totalRevenue += menuPrice;

        const menuItems = [
          menu.snack,
          menu.meal,
          menu.accompaniment,
          menu.secondAccompaniment,
          menu.dessert,
          menu.drink,
          menu.sandwich,
          menu.seasoning,
        ];

        let quantity = 0;

        if (menu.type === MenuTypes.TiMenu) {
          quantity = MenuSnackQuantity.TiMenu;
        } else if (menu.type === MenuTypes.MenuFilao) {
          quantity = MenuSnackQuantity.MenuFilao;
        } else if (menu.type === MenuTypes.MenuKonple) {
          quantity = MenuSnackQuantity.MenuKonple;
        }

        menuItems.forEach((menuItem) => {
          if (Array.isArray(menuItem)) {
            menuItem.forEach((snack) => {
              const productCost =
                snack.quantityFields && snack.quantityFields.length > 0
                  ? snack.quantityFields[0].productCost
                  : 0;

              const itemCost = productCost * quantity;
              totalCost += itemCost;
            });
          } else if (menuItem) {
            const weightField =
              menuItem.weightFields && menuItem.weightFields.length > 0
                ? menuItem.weightFields[0]
                : 0;

            const productCost = weightField ? weightField.productCost : 0;
            const itemCost = productCost * (menuItem.quantity || 1);
            totalCost += itemCost;
          }
        });
      });
    });

    const totalMargin = totalRevenue - totalCost;
    const marginPercentage =
      totalRevenue > 0 ? (totalMargin / totalRevenue) * 100 : 0;

    return {
      totalRevenue: totalRevenue.toFixed(2),
      totalCost: totalCost.toFixed(2),
      totalMargin: totalMargin.toFixed(2),
      marginPercentage: marginPercentage.toFixed(2),
    };
  };

  return (
    <div className="earnings-container">
      <div className="total-earnings">
        Chiffre de la journée: <span>{totalEarnings}€</span>
      </div>
      <div className="stats">
        <div className="stats-total-revenue">
          Gains totaux: <span>{totalRevenue}€</span>
        </div>
        <div className="stats-total-cost">
          Coût total:<span>{totalCost}€</span>
        </div>
        <div className="stats-total-margin">
          Marge:<span>{totalMargin}€</span>
        </div>
        <div className="stats-margin-percentage">
          Pourcentage de marge:<span>{marginPercentage}%</span>
        </div>
      </div>
      <div className="credit-card-and-cash">
        <div className="credit-card-earnings">
          Par carte bancaire: <span>{creditCardEarnings}€</span>
        </div>
        <div className="cash-earnings">
          En espèces: <span>{cashEarnings}€</span>
        </div>
      </div>
      <div className="apple-and-gpay">
        <div className="apple-pay-earnings">
          Par Apple Pay: <span>{applePayEarnings}€</span>
        </div>
        <div className="gpay-earnings">
          Par Google Pay: <span>{gPayEarnings}€</span>
        </div>
        <div className="paypal-earnings">
          Par Paypal: <span>{paypalEarnings}€</span>
        </div>
      </div>
      <div className="unpaid">
        Non payé: <span>{unpaidTotal}€</span>
      </div>
    </div>
  );
};

export default Earnings;
