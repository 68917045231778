import React from "react";

import "./styles.scss";

const Textarea = ({updateText, name, hasError, value, id, label}) => {
  return (
    <div className={`form-textarea ${hasError && "textarea-error"}`}>
      <label htmlFor={name}>
        {label}
        <textarea
          name={name}
          id={id}
          onChange={(e) => updateText(e.target.value)}
          value={value}
        ></textarea>
      </label>
    </div>
  );
};

export default Textarea;
