import React, {useEffect, useState} from "react";
import AdminOrderItemProduct from "./components/AdminOrderItemProduct";

import {OrderStatus} from "../../../../../../../../utilities/enums";
import {
  extractOrderNumber,
  formatDate,
} from "../../../../../../../../utilities/functions";

import "./styles.scss";
import AdminOrderItemMenu from "./components/AdminOrderItemMenu";
import MailOrderButton from "../../../../../../../../components/MailOrderButton";

const AdminOrderItem = ({order}) => {
  const [hideOrders, setHideOrders] = useState(true);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "h" || event.key === "H") {
        setHideOrders((prevHideOrders) => !prevHideOrders);
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  if (hideOrders && order.hidden) {
    return null;
  }

  const renderMenus = () => {
    if (order.menus.length < 1) return;

    return (
      <ul className="admin-order-item-menus">
        {order.menus.map((menu, index) => {
          return <AdminOrderItemMenu key={index} menu={menu} />;
        })}
      </ul>
    );
  };

  const renderItems = () => {
    if (order.orderItems.length < 1) return;

    return (
      <ul className="admin-order-item-products">
        {order.orderItems.map((item, index) => {
          return <AdminOrderItemProduct key={index} product={item} />;
        })}
      </ul>
    );
  };

  const renderBuyPlace = () => {
    if (order.orderTakenOnTheSpot) {
      return "sur place";
    } else {
      return "internet";
    }
  };

  const renderCompletion = () => {
    if (order.orderStatus === OrderStatus.Pending) {
      return " en cours.";
    } else {
      return " terminée.";
    }
  };

  return (
    <li
      className={`admin-order-item ${
        order.orderTakenOnTheSpot && "admin-order-on-spot"
      }`}
    >
      <MailOrderButton currentOrder={order} />
      <h6>
        Commande {extractOrderNumber(order.orderNumber)} du{" "}
        {formatDate(order.orderCreatedDate)}
      </h6>
      {order.customer && (
        <p className="customer-name">
          Pour {order.customer.firstName} {order.customer.lastName} (
          {Math.floor(order.orderTotal)} points gagnés)
        </p>
      )}
      {renderMenus()}
      {renderItems()}
      <hr />
      <div>
        {order.orderPaid ? (
          <p>Moyen de paiement: {order.paymentMethod}</p>
        ) : (
          <p>Commande non réglée.</p>
        )}
        <p>
          Commande {renderBuyPlace()} {renderCompletion()}{" "}
        </p>
        <p className="admin-order-item-total">
          Total: {order.orderTotal.toFixed(2)}€
        </p>
      </div>
    </li>
  );
};

export default AdminOrderItem;
