import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';

import './styles.scss';
import Clock from '../Clock';

const NavbarMenu = () => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState('');

  useEffect(() => {
    if (location.pathname === '/') {
      setActiveLink('pending-orders');
    } else if (location.pathname === '/commandes/gestion') {
      setActiveLink('manage-orders');
    } else if (location.pathname === '/administration') {
      setActiveLink('administration');
    } else {
      setActiveLink('');
    }
  }, [location.pathname]);

  return (
    <ul className="navbar-menu">
      <Link
        to="/administration"
        className={`navbar-link ${
          activeLink === 'administration' && 'active-link'
        }`}
      >
        <li>Administration</li>
      </Link>
      <Link
        to="/"
        className={`navbar-link ${
          activeLink === 'pending-orders' && 'active-link'
        }`}
      >
        <li>Commandes en cours</li>
      </Link>
      <Link
        to="/commandes/gestion"
        className={`navbar-link ${
          activeLink === 'manage-orders' && 'active-link'
        }`}
      >
        <li>Gestion commandes</li>
      </Link>
      <Link className="navbar-link">
        <li className="clock">
          <Clock />
        </li>
      </Link>
    </ul>
  );
};

export default NavbarMenu;
