import React from "react";

import "./styles.scss";

const TicketModal = ({
  hideModal,
  toggleModal,
  additionnalClass,
  children,
  size,
}) => {
  if (hideModal) return null;

  return (
    <>
      <div className="ticket-modal-overlay" onClick={() => toggleModal()} />
      <div className="ticket-modal-wrap">
        <div className="ticket-modal">{children}</div>
      </div>
    </>
  );
};

export default TicketModal;
