import rewardTypes from './rewardTypes';

export const addRewardStart = (rewardData) => ({
  type: rewardTypes.ADD_NEW_REWARD,
  payload: rewardData,
});

export const editRewardStart = (reward) => ({
  type: rewardTypes.EDIT_REWARD,
  payload: reward,
});

export const deleteRewardStart = (rewardId) => ({
  type: rewardTypes.DELETE_REWARD,
  payload: rewardId,
});

export const fetchRewardsStart = () => ({
  type: rewardTypes.FETCH_REWARDS_START,
});

export const setRewards = (rewards) => ({
  type: rewardTypes.SET_REWARDS,
  payload: rewards,
});

export const clearRewards = () => ({
  type: rewardTypes.CLEAR_REWARDS,
});
