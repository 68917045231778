const rewardTypes = {
  ADD_NEW_REWARD: 'ADD_NEW_REWARD',
  EDIT_REWARD: 'EDIT_REWARD',
  DELETE_REWARD: 'DELETE_REWARD',

  FETCH_REWARDS_START: 'FETCH_REWARDS_START',
  SET_REWARDS: 'SET_REWARDS',
  CLEAR_REWARDS: 'CLEAR_REWARDS',
};

export default rewardTypes;
