import {firestore} from '../../services/firestore';
import {apiInstance} from '../../utilities/index';
import {store} from '../createStore';
import {setFeedbackMessage} from '../app/appActions';

export const handleSendEmailingCampaign = async (
  emailContent,
  emailSubject
) => {
  if (!emailContent || !emailSubject) return;

  const emailCollection = firestore.collection('subscribers');
  let emailAddresses = [];

  emailCollection
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        if (doc.data().subscribed) {
          emailAddresses.push(doc.data().email);
        }
      });
      try {
        const emailData = {
          to: '',
          subject: emailSubject,
          html: emailContent,
        };

        apiInstance
          .post('/send-campaign-email', {emailAddresses, emailData})
          .then(async (r) => {
            if (r.data === 'Success') {
              store.dispatch(setFeedbackMessage('Mails envoyés.'));
            }
            console.log(r);
          });
      } catch (err) {
        console.log(err);
      }
    })
    .catch((error) => {
      console.error('Error fetching email addresses: ', error);
    });
};
