import React from 'react';
import {Link} from 'react-router-dom';

import './styles.scss';

const PreviousButton = () => {
  return (
    <Link to="/commandes/prise-de-commande" className="previous-button">
      Retourner à la liste des produits
    </Link>
  );
};

export default PreviousButton;
