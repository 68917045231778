const productTypes = {
  ADD_NEW_PRODUCT_START: 'ADD_NEW_PRODUCT_START',
  EDIT_PRODUCT_START: 'EDIT_PRODUCT_START',
  DELETE_PRODUCT_START: 'DELETE_PRODUCT_START',

  FETCH_PRODUCTS_START: 'FETCH_PRODUCT_START',
  SET_PRODUCTS: 'SET_PRODUCTS',
  CLEAR_PRODUCTS: 'CLEAR_PRODUCTS',

  PRODUCT_ERROR: 'PRODUCT_ERROR',
  RESET_PRODUCT_ERROR: 'RESET_PRODUCT_ERROR',

  UPDATE_PRODUCTS: 'UPDATE_PRODUCTS',
};

export default productTypes;
