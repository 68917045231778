export const firebaseConfig = {
  apiKey: "AIzaSyB_7tj7w2qVfcq6EMDrgSb59qQCJSXTElY",
  authDomain: "otifilao-f497f.firebaseapp.com",
  projectId: "otifilao-f497f",
  storageBucket: "otifilao-f497f.appspot.com",
  messagingSenderId: "230651449962",
  appId: "1:230651449962:web:8da7627796402458bbb013",
  measurementId: "G-TNGC76K79F",
};

// Base Test

// export const firebaseConfig = {
//   apiKey: "AIzaSyDSVtteoXp7wjTHOqZkDGzsH3omgKa4b3Q",
//   authDomain: "filao-test.firebaseapp.com",
//   projectId: "filao-test",
//   storageBucket: "filao-test.appspot.com",
//   messagingSenderId: "601500123692",
//   appId: "1:601500123692:web:5dda203e3c28b0ecccc789",
// };
