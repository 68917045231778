import React from "react";

import "./styles.scss";

const QuantityPriceFields = ({fields, setFields}) => {
  const handleAddFields = () => {
    if (!canCreateNewFields()) return;

    const newFields = [
      ...fields,
      {productQuantity: 0, productPrice: "", salePrice: 0, productCost: ""},
    ];
    setFields(newFields);
  };

  const handleInputChange = (index, event) => {
    const values = [...fields];
    if (event.target.name === "productQuantity") {
      if (isDuplicate(event.target.value)) return;
      values[index].productQuantity = event.target.value;
    } else if (event.target.name === "productPrice") {
      values[index].productPrice = event.target.value;
    } else if (event.target.name === "productCost") {
      values[index].productCost = event.target.value;
    }
    setFields(values);
  };

  const canCreateNewFields = () => {
    if (fields[fields.length - 1].productPrice === "") return false;

    if (fields.length > 1) {
      if (isDuplicate(fields[fields.length - 1].productQuantity)) return false;
    }

    return true;
  };

  const isDuplicate = (quantity) => {
    return fields.some(
      (field, index) =>
        index !== fields.length - 1 && field.productQuantity === quantity
    );
  };

  const handleDeleteField = (index) => {
    const values = fields.filter((_, i) => i !== index);
    setFields(values);
  };

  return (
    <div className="quantity-fields">
      {fields.map((field, index) => (
        <div className="field-row" key={index}>
          <label>
            <span>Par</span>
            <input
              type="number"
              name="productQuantity"
              min={1}
              max={50}
              step={1}
              id="productPrice"
              value={field.productQuantity}
              onChange={(event) => handleInputChange(index, event)}
              placeholder="Quantité"
            />
          </label>

          <label>
            <span>Prix</span>
            <input
              type="number"
              name="productPrice"
              min={0.01}
              max={10000.01}
              step={0.01}
              value={field.productPrice}
              onChange={(event) => handleInputChange(index, event)}
              placeholder="Prix"
            />
          </label>

          <label>
            <span>Cout</span>
            <input
              type="number"
              name="productCost"
              min={0}
              max={10000.01}
              step={0.01}
              value={field.productCost}
              onChange={(event) => handleInputChange(index, event)}
              placeholder="Cout"
            />
          </label>
          <div
            onClick={() => handleDeleteField(index)}
            className="delete-cross"
          >
            X
          </div>
        </div>
      ))}
      <button type="button" onClick={handleAddFields}>
        Ajouter une quantité
      </button>
    </div>
  );
};

export default QuantityPriceFields;
