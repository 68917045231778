import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import PointsTier from "./components/PointsTier";
import RewardChoice from "./components/RewardChoice";
import RewardOptions from "./components/RewardOptions";
import MenuOptions from "./components/MenuOptions";
import {addRewardToOrder} from "../../../../../../redux/order/orderActions";
import {
  clearRewards,
  fetchRewardsStart,
} from "../../../../../../redux/reward/rewardActions";
import {DotLoader} from "react-spinners";
import "./styles.scss";
import {MenuTypes} from "../../../../../../utilities/enums";

const LoyaltyProducts = ({customerLoyalty, toggleModal}) => {
  const dispatch = useDispatch();
  const {rewards} = useSelector((state) => state.rewardData);
  const [isLoading, setIsLoading] = useState(false);
  const [activePoints, setActivePoints] = useState(0);
  const [selectedReward, setSelectedReward] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [menuOption, setMenuOption] = useState(null);

  const [snack, setSnack] = useState(null);
  const [meal, setMeal] = useState(null);
  const [accompaniment, setAccompaniment] = useState(null);
  const [dessert, setDessert] = useState(null);
  const [sandwich, setSandwich] = useState(null);
  const [drink, setDrink] = useState(null);
  const [sauce, setSauce] = useState(null);

  const menu = {snack, meal, accompaniment, dessert, sandwich, drink, sauce};

  useEffect(() => {
    dispatch(fetchRewardsStart());
    return () => {
      dispatch(clearRewards());
    };
  }, [dispatch]);

  useEffect(() => {
    setSelectedOption(null);
    setMenuOption(null);
    resetMenu();
  }, [selectedReward]);

  const resetMenu = () => {
    setSnack(null);
    setMeal(null);
    setAccompaniment(null);
    setDessert(null);
    setSandwich(null);
    setDrink(null);
    setSauce(null);
  };

  const setMenu = () => {
    if (!selectedReward) return;
    const {name} = selectedReward;

    if (name === MenuTypes.MenuFilao) {
      if (
        menu.snack &&
        menu.meal &&
        menu.accompaniment &&
        menu.drink &&
        menu.dessert
      ) {
        setSelectedOption(
          `${name}: ${menu.snack} / ${menu.meal} / ${menu.accompaniment} / ${menu.drink}  / ${menu.dessert}`
        );
      }
    } else if (name === MenuTypes.MenuSandwich) {
      if (menu.sandwich && menu.drink && menu.sauce) {
        setSelectedOption(
          `${name}: ${menu.sandwich} / ${menu.drink} / Sauce: ${menu.sauce}`
        );
      }
    } else if (name === MenuTypes.TiMenu) {
      if (menu.snack && menu.meal && menu.accompaniment && menu.dessert) {
        setSelectedOption(
          `${name}: ${menu.snack} / ${menu.meal} / ${menu.accompaniment} / ${menu.dessert}`
        );
      }
    } else if (name === MenuTypes.MenuKonple) {
      if (
        menu.snack &&
        menu.meal &&
        menu.accompaniment &&
        menu.secondAccompaniment &&
        menu.drink &&
        menu.dessert
      ) {
        setSelectedOption(
          `${name}: ${menu.snack} / ${menu.meal} / ${menu.accompaniment} / ${menu.dessert} / ${menu.drink}`
        );
      }
    }
  };

  useEffect(() => {
    setMenu();
  }, [menu]);

  const changeActivePoints = (points) => {
    setActivePoints(points);
    setSelectedReward(null);
    setSelectedOption(null);
    setMenuOption(null);
    resetMenu();
  };

  const handleValidate = () => {
    const reward = {
      cost: activePoints,
      element: selectedReward.quantity + " " + selectedOption,
    };
    dispatch(addRewardToOrder(reward));
    toggleModal();
  };

  if (customerLoyalty.areLoyaltyPointsBeingUsed) return;

  if (isLoading) return <DotLoader />;

  return (
    <div className="loyalty-products">
      <h2>
        Utiliser les points (<span>{customerLoyalty.loyaltyPoints}</span> points
        disponibles)
      </h2>
      <div className="loyalty-products-pickup">
        <PointsTier
          rewards={rewards}
          activePoints={activePoints}
          changeActivePoints={changeActivePoints}
          setIsLoading={setIsLoading}
          customerPoints={customerLoyalty.loyaltyPoints}
        />
        <RewardChoice
          rewards={rewards}
          activePoints={activePoints}
          customerLoyalty={customerLoyalty}
          selectedReward={selectedReward}
          setSelectedReward={setSelectedReward}
        />
        <RewardOptions
          selectedReward={selectedReward}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          menuOption={menuOption}
          setMenuOption={setMenuOption}
          menu={menu}
          setDrink={setDrink}
          setDessert={setDessert}
        />
        <MenuOptions
          menuOption={menuOption}
          selectedReward={selectedReward}
          setMenuOption={setMenuOption}
          setSnack={setSnack}
          setMeal={setMeal}
          setAccompaniment={setAccompaniment}
          setSandwich={setSandwich}
          setDrink={setDrink}
          setSauce={setSauce}
          setDessert={setDessert}
        />
      </div>
      {selectedReward && selectedOption && (
        <div className="loyalty-recap">
          <h3>Choix</h3>
          <div className="loyalty-choice">
            <p>{selectedReward.quantity + " " + selectedOption}</p>
            <button onClick={handleValidate}>Valider</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoyaltyProducts;
