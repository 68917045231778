import React from "react";

import "./styles.scss";
import {extractOrderNumber} from "../../../../../../utilities/functions";

const OrderProductsContainer = ({order, orderProducts}) => {
  console.log(order);
  return (
    <div className="order-products">
      <h4>Commande {extractOrderNumber(order.orderNumber)}</h4>
      <ul>
        {Object.entries(orderProducts).map(([key, value]) => {
          return (
            <li key={key} className="product-quantity-ordered">
              <span className="quantity">{value.quantity}</span> {key}
            </li>
          );
        })}
      </ul>
      {order.reward && (
        <div className="order-reward">
          <hr />
          <p className="title">A ajouter</p>
          <p className="content">{order.reward.element}</p>
        </div>
      )}
    </div>
  );
};

export default OrderProductsContainer;
