import React from "react";

import "./styles.scss";
import Dots from "../../../../../../components/Dots";
import {renderProductTitle} from "../../../../../../utilities/functions";

const KitchenTicketItem = ({product}) => {
  return (
    <li className="ticket-order-item">
      <div className="ticket-order-item-name-and-quantity">
        {renderProductTitle(product)}
        <Dots />
        <div className="ticket-order-item-cart-quantity">
          <span>x{product.cartQuantity}</span>
        </div>
      </div>
    </li>
  );
};

export default KitchenTicketItem;
