import React from "react";

import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";

import "./styles.scss";

const ScrollToTopButton = ({componentRef}) => {
  const scrollToTop = () => {
    if (componentRef.current) {
      componentRef.current.scrollIntoView({behavior: "smooth"});
    }
  };

  const configIcon = {
    style: {
      fontSize: "50px",
    },
  };

  return (
    <div className="menu-return-button" onClick={() => scrollToTop()}>
      <KeyboardDoubleArrowUpIcon {...configIcon} />
    </div>
  );
};

export default ScrollToTopButton;
