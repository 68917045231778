import React, {useState} from 'react';
import Modal from '../../../../../../components/Modal';
import LoyaltyProducts from '../LoyaltyProducts';

import './styles.scss';

const LoyaltyProductsButton = ({customerLoyalty}) => {
  const [hideModal, setHideModal] = useState(true);

  const toggleModal = () => {
    if (customerLoyalty.areLoyaltyPointsBeingUsed) return;
    setHideModal(!hideModal);
  };

  const configModal = {hideModal, toggleModal};

  return (
    <>
      {customerLoyalty.loyaltyPoints > 15 && (
        <button
          onClick={() => toggleModal()}
          disabled={customerLoyalty.areLoyaltyPointsBeingUsed}
        >
          {customerLoyalty.areLoyaltyPointsBeingUsed
            ? "Points en cours d'utilisation..."
            : 'Utiliser ?'}
        </button>
      )}

      <Modal {...configModal} size="big" additionnalClass="no-padding">
        <LoyaltyProducts
          toggleModal={toggleModal}
          customerLoyalty={customerLoyalty}
        />
      </Modal>
    </>
  );
};

export default LoyaltyProductsButton;
