import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import EmailEditor from 'react-email-editor';
import PulseLoader from 'react-spinners/PulseLoader';

import FormInput from '../../../../components/FormInput';
import FormButton from '../../../../components/FormButton';

import {resetFeedbackMessage} from '../../../../redux/app/appActions';
import {handleSendEmailingCampaign} from '../../../../redux/subscriber/subscriberHelpers';

import './styles.scss';

const AdminEmail = () => {
  const emailEditorRef = useRef(null);
  const dispatch = useDispatch();
  const {feedbackMessage} = useSelector((mapState) => mapState.appData);
  const [subject, setSubject] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (errorMessage) {
      const updateMessage = setTimeout(() => {
        setErrorMessage('');
      }, 5000);

      return () => clearTimeout(updateMessage);
    }
  }, [errorMessage]);

  useEffect(() => {
    if (feedbackMessage) {
      setIsSending(false);
      const updateFeedbackMessage = setTimeout(() => {
        dispatch(resetFeedbackMessage());
      }, 7000);

      return () => clearTimeout(updateFeedbackMessage);
    }
  }, [feedbackMessage]);

  const exportHtml = () => {
    const unlayer = emailEditorRef.current?.editor;

    unlayer?.exportHtml((data) => {
      const {design, html} = data;
      if (!subject) {
        setErrorMessage('Saisir un sujet de message');
        return;
      }
      setIsSending(true);
      handleSendEmailingCampaign(html, subject);
    });
  };

  const onReady = (unlayer) => {
    setIsLoading(false);
    // editor is ready
    // you can load your template here;
    // the design json can be obtained by calling
    // unlayer.loadDesign(callback) or unlayer.exportHtml(callback)
    // const templateJson = { DESIGN JSON GOES HERE };
    // unlayer.loadDesign(templateJson);
  };

  return (
    <div className="emailing-page">
      <h2>Campagne e-mailing</h2>

      {feedbackMessage.text && (
        <div className="feedback-message-container">
          <p>{feedbackMessage.text}</p>
        </div>
      )}

      <div className="emailing-container">
        <EmailEditor ref={emailEditorRef} onReady={onReady} />
        {!isLoading && (
          <div>
            <div className="mail-subject">
              <FormInput
                label="Sujet du message"
                type="text"
                id="subject"
                name="subject"
                value={subject}
                handleChange={(e) => setSubject(e.target.value)}
              />
              {errorMessage && <p className="error-message">{errorMessage}</p>}
            </div>

            <FormButton type="button" onClick={exportHtml}>
              {isSending ? (
                <PulseLoader className="loader" />
              ) : (
                "Envoyer l'email"
              )}
            </FormButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminEmail;
