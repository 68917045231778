import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import DatePicker from "react-date-picker";

import {sendOrder} from "../../../../redux/order/orderActions";
import {setFeedbackMessage} from "../../../../redux/app/appActions";
import {createStructuredSelector} from "reselect";
import {
  selectMenuTotal,
  selectOrderTotal,
} from "../../../../redux/order/orderSelectors";

import "./styles.scss";
import {calculateTotal} from "../../../../utilities/functions";
import FormInput from "../../../../components/FormInput";

const mapState = createStructuredSelector({
  cartTotal: selectOrderTotal,
  menuTotal: selectMenuTotal,
});

const OrderPaidConfirmMessage = ({closeModal, currentOrder}) => {
  const dispatch = useDispatch();
  const {cartTotal, menuTotal} = useSelector(mapState);
  const [startDate, setStartDate] = useState(null);
  const [orderHour, setOrderHour] = useState("");
  const [minutes, setMinutes] = useState("00");

  const handleConfirmButton = () => {
    let newOrder = {...currentOrder};

    if (newOrder.orderStatus) {
      dispatch(
        setFeedbackMessage({
          type: "warning",
          text: "Commande déjà en cuisine",
        })
      );
      closeModal();
      return;
    }

    if (!newOrder.paymentMethod) {
      dispatch(
        setFeedbackMessage({
          type: "danger",
          text: "Veuillez selectionner un mode de paiement.",
        })
      );
      closeModal();
      return;
    }

    newOrder.orderTotal = calculateTotal(cartTotal, menuTotal);
    newOrder.orderPaid = true;
    newOrder.scheduled = startDate;

    if (newOrder.scheduled !== null) {
      if (orderHour) {
        newOrder.scheduled.setHours(orderHour);
        newOrder.scheduled.setMinutes(minutes);
      } else {
        newOrder.orderCreatedDate.setHours(11);
      }
    } else {
      if (orderHour !== "") {
        newOrder.scheduled = new Date();
        newOrder.scheduled.setHours(orderHour);
        newOrder.scheduled.setMinutes(minutes);
      }
    }

    if (
      (newOrder.orderItems.length < 1 && newOrder.menus.length < 1) ||
      !newOrder.orderTotal ||
      !newOrder.orderPaid
    ) {
      dispatch(
        setFeedbackMessage({
          type: "danger",
          text: "Problème lors de la commande",
        })
      );
      closeModal();
      return;
    }

    dispatch(sendOrder(newOrder));
    closeModal();
  };

  return (
    <div className="order-paid-confirm-message">
      <p>Le paiement a-t-il été effectué par le client ?</p>
      <div className="buttons">
        <button
          className="confirm-button"
          onClick={() => handleConfirmButton()}
        >
          Oui
        </button>{" "}
        <button className="decline-button" onClick={() => closeModal()}>
          Non
        </button>
      </div>
      <div>
        Pour le{" "}
        <DatePicker onChange={(date) => setStartDate(date)} value={startDate} />
        <div className="set-time">
          <span className="at">à</span>
          <FormInput
            type="number"
            min="9"
            max="20"
            step="1"
            id="orderHour"
            name="orderHour"
            halfWidth={true}
            value={orderHour}
            suffix="h"
            handleChange={(e) => setOrderHour(e.target.value)}
          />
          <FormInput
            type="number"
            min="00"
            max="45"
            step="15"
            id="minutes"
            name="minutes"
            suffix="min"
            halfWidth={true}
            value={minutes}
            handleChange={(e) => setMinutes(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default OrderPaidConfirmMessage;
