import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import OrderTicket from "./components/OrderTicket";

import {
  clearOrders,
  getPendingOrdersStart,
} from "../../../../redux/order/orderActions";

import "./styles.scss";

const PendingOrderList = () => {
  const dispatch = useDispatch();
  const {pendingOrders} = useSelector((mapState) => mapState.orderData);

  useEffect(() => {
    dispatch(getPendingOrdersStart());
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getPendingOrdersStart());
    }, 20000);
    return () => clearInterval(interval);
  }, [pendingOrders.length]);

  const sortAndRenderPendingOrders = () => {
    pendingOrders.sort((a, b) => {
      if (a.scheduled && b.scheduled) {
        return a.scheduled - b.scheduled;
      } else if (a.scheduled) {
        return 1; // a comes before b because b.scheduledFor is null
      } else if (b.scheduled) {
        return -1; // b comes before a because a.scheduledFor is null
      } else {
        return a.orderCreatedDate - b.orderCreatedDate; // both scheduledFor are null, fallback to createdDate
      }
    });

    return pendingOrders.map((order, index) => {
      return <OrderTicket key={index} order={order} />;
    });
  };

  return (
    <div className="pending-order-component">
      {pendingOrders.length > 0 ? (
        <div className="pending-order-list">
          {/* {pendingOrders.toReversed().map((order, index) => {
            return <OrderTicket key={index} order={order} />;
          })} */}
          {sortAndRenderPendingOrders()}
        </div>
      ) : (
        <p className="no-pending-orders">Aucune commande en attente.</p>
      )}
    </div>
  );
};

export default PendingOrderList;
