import React from "react";

import ConfirmButton from "../ConfirmButton";
import OrderItem from "../OrderItem";
import OrderMenu from "../OrderMenu";
import OrderTotal from "../OrderTotal";

import {isCartEmpty} from "../../../../utilities/functions";

import "./styles.scss";

const OrderRecapTicket = ({order}) => {
  const renderOrderMenus = () => {
    return order.menus.map((menu, index) => {
      return <OrderMenu key={index} menu={menu} />;
    });
  };

  const renderOrderItems = () => {
    return order.orderItems.map((item, index) => {
      return <OrderItem key={index} product={item} />;
    });
  };

  const renderReward = () => {
    if (!order.reward) return null;
    return (
      <div className="reward-item">
        <p>{order.reward.element}</p>
        <p>{order.reward.cost} points</p>
      </div>
    );
  };

  const isEmptyCart = isCartEmpty(order);

  return (
    <div className="order-recap-ticket">
      <h4>Récapitulatif de commande</h4>
      <div className="order-recap-ticket-content">
        {isEmptyCart ? (
          <p className="no-product">Aucun produit dans le panier.</p>
        ) : (
          <>
            <ul>{renderOrderMenus()}</ul>
            <ul>{renderOrderItems()}</ul>
            {renderReward()}
          </>
        )}
      </div>

      <OrderTotal />

      <ConfirmButton
        isDisabled={isEmptyCart}
        orderId={order.documentId ? order.documentId : "nouvelle-commande"}
      />
    </div>
  );
};

export default OrderRecapTicket;
