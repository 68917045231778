import React from "react";
import Dots from "../../../../../../../../../../components/Dots";

import "./styles.scss";
import {
  renderProductPrice,
  renderProductTitle,
} from "../../../../../../../../../../utilities/functions";

const AdminOrderItemProduct = ({product}) => {
  return (
    <li className="admin-order-item-product">
      <div>{renderProductTitle(product)}</div>
      <div className="quantity-and-price">
        <span className="cart-quantity">x{product.cartQuantity}</span>
        <Dots />
        <span className="price">{renderProductPrice(product)}</span>
      </div>
    </li>
  );
};

export default AdminOrderItemProduct;
