import React, {useState} from 'react';

import './styles.scss';

const MultipleInputArray = ({
  label,
  name,
  array,
  addToArray,
  removeFromArray,
  hasError,
  title,
  placeholder,
}) => {
  const [inputValue, setInputValue] = useState(''); // State to store the current input value

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleAddValue = () => {
    if (inputValue.trim() !== '') {
      addToArray([...array, inputValue]); // Add the input value to the array
      setInputValue(''); // Clear the input field
    }
  };

  return (
    <div
      className={`form-multiple-container ${
        hasError && 'form-multiple-error'
      } `}
    >
      <label htmlFor={name}>
        {label}
        <div className="form-multiple-input-and-button">
          <input
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            placeholder={placeholder ? placeholder : 'Ajouter un ingrédient'}
          />
          <button type="button" onClick={handleAddValue}>
            Ajouter
          </button>
        </div>
      </label>

      {array.length > 0 && (
        <div className="ingredients">
          <span className="ingredients-title">
            {title ? title : 'Liste des ingrédients'}
          </span>
          <ul className="ingredients-list">
            {array.map((item, index) => (
              <li key={index} onClick={() => removeFromArray(item)}>
                {item}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MultipleInputArray;
