const appTypes = {
  SET_FEEDBACK_MESSAGE: 'SET_FEEDBACK_MESSAGE',
  RESET_FEEDBACK_MESSAGE: 'RESET_FEEDBACK_MESSAGE',

  FETCH_MESSAGE_START: 'FETCH_MESSAGE_START',
  SET_MESSAGES: 'SET_MESSAGES',
  DELETE_MESSAGE_START: 'DELETE_MESSAGE_START',

  MESSAGE_ERROR: 'MESSAGE_ERROR',
  RESET_MESSAGE_ERROR: 'RESET_MESSAGE_ERROR',
};

export default appTypes;
