import React from 'react';
import {useDispatch} from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';

import {deleteMessage} from '../../../../redux/app/appActions';
import {formatDate} from '../../../../utilities/functions';

import './styles.scss';

const AdminMessages = ({customerMessages}) => {
  const dispatch = useDispatch();

  const handleDeleteMessage = (messageId) => {
    dispatch(deleteMessage(messageId));
  };

  if (customerMessages.length < 1)
    return <p className="no-messages">Aucun message.</p>;

  return (
    <div className="messages-page">
      <h2>Messages</h2>

      <div className="messages-container">
        <ul className="message-list">
          {customerMessages &&
            customerMessages.map((message, index) => {
              return (
                <li key={index} className="message-list-item">
                  <div
                    className="close-icon"
                    onClick={() => handleDeleteMessage(message.documentId)}
                  >
                    <CloseIcon />
                  </div>

                  <p className="message-from">
                    De {message.name} ({message.from}) le{' '}
                    {formatDate(message.createdAt)}
                  </p>
                  <p className="message-content"> {message.content}</p>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default AdminMessages;
