import React, {useEffect} from 'react';
import {useRoutes} from 'react-router-dom';

import FeedbackMessage from './components/FeedbackMessage';

import {routesConfig} from './utilities/routesConfig';

import './styles/default.scss';
import {useDispatch, useSelector} from 'react-redux';
import {resetCustomerLoyalty} from './redux/user/userActions';

const App = () => {
  const routes = useRoutes(routesConfig);
  const dispatch = useDispatch();
  const {currentOrder} = useSelector((mapState) => mapState.orderData);

  useEffect(() => {
    if (!currentOrder.customer) {
      dispatch(resetCustomerLoyalty());
    }
  }, [currentOrder]);

  return (
    <div className="App">
      <FeedbackMessage />
      {routes}
    </div>
  );
};

export default App;
