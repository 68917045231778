import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {createStructuredSelector} from "reselect";
import {
  selectMenuTotal,
  selectOrderTotal,
  selectOrderTotalWithoutSale,
} from "../../../../redux/order/orderSelectors";
import {calculateTotal} from "../../../../utilities/functions";
import "./styles.scss";

const mapState = createStructuredSelector({
  cartTotal: selectOrderTotal,
  totalWithoutReduction: selectOrderTotalWithoutSale,
  menuTotal: selectMenuTotal,
});

const OrderTotal = () => {
  const {cartTotal, totalWithoutReduction, menuTotal} = useSelector(mapState);

  const totalWithoutReductionCalculated = (
    (totalWithoutReduction || 0) + (menuTotal || 0)
  ).toFixed(2);

  const reduction = ((totalWithoutReduction || 0) - (cartTotal || 0)).toFixed(
    2
  );

  // Calculate total points and total
  const totalPoints = Math.floor(calculateTotal(cartTotal, menuTotal));
  const total = calculateTotal(cartTotal, menuTotal).toFixed(2);

  return (
    <div className="order-total">
      <p className="fidelity-points">{totalPoints} points ajoutés au compte</p>
      <hr />
      <h3 className="title">Total</h3>
      <div className="first-line">
        <p className="total-without-reduction">
          <span>Total sans réduction</span>
          <span>{totalWithoutReductionCalculated}€</span>
        </p>
        <p className="reduction">
          <span>Réduction</span>
          <span>{reduction}€</span>
        </p>
      </div>
      <p className="total">
        <span>Total</span>
        <span className="total-amount">{total}€</span>
      </p>
    </div>
  );
};

export default OrderTotal;
