import React from 'react';
import './styles.scss';

const FormSelect = ({
  options,
  value,
  handleChange,
  label,
  hasError,
  ...otherProps
}) => {
  if (!Array.isArray(options) || options.length < 1) return null;
  return (
    <div className={`formRow ${hasError && 'select-error'}`}>
      {label && (
        <label>
          {label}
          <select
            className="formSelect"
            value={value}
            onChange={handleChange}
            {...otherProps}
          >
            {options.map((option, index) => {
              const {value, name, disabled} = option;

              return (
                <option key={index} value={value} disabled={disabled}>
                  {name}
                </option>
              );
            })}
          </select>
        </label>
      )}
    </div>
  );
};

export default FormSelect;
