import React from 'react';
import {useDispatch} from 'react-redux';

import GoogleIcon from '@mui/icons-material/Google';

import {iconConfig} from './iconConfig';
import {googleSignInStart} from '../../../../redux/user/userActions';

import './styles.scss';

const Socials = () => {
  const dispatch = useDispatch();

  const handleGoogleSignIn = () => {
    dispatch(googleSignInStart());
  };

  return (
    <>
      <div className="separation-line">
        <hr />
        <p>Ou</p>
      </div>

      <div className="socials">
        <GoogleIcon {...iconConfig.googleIcon} onClick={handleGoogleSignIn} />
      </div>
    </>
  );
};

export default Socials;
