import React, {useState} from "react";
import {useDispatch} from "react-redux";

import FormInput from "../../../../../../../../components/FormInput";
import FormButton from "../../../../../../../../components/FormButton";

import {editProductStart} from "../../../../../../../../redux/product/productActions";

import {
  calculatePercentageUsed,
  returnPercentageReduction,
} from "../../../../../../../../utilities/productHelpers";

import "./styles.scss";
import {Categories} from "../../../../../../../../utilities/enums";

const ProductPriceChange = ({product, toggleModal}) => {
  const dispatch = useDispatch();
  const [fields, setFields] = useState(
    product.category === Categories.snack ||
      product.category === Categories.sweet ||
      product.category === Categories.dessert
      ? product.quantityFields
      : product.weightFields
  );

  const handleSalePriceChange = (e, index) => {
    const updatedFields = [...fields];
    updatedFields[index].salePrice = parseFloat(e.target.value);
    setFields(updatedFields);
  };

  const handlePercentageChange = (e, index) => {
    const updatedFields = [...fields];
    const reduction = returnPercentageReduction(
      e.target.value,
      updatedFields[index].productPrice
    );
    updatedFields[index].salePrice = Number(
      updatedFields[index].productPrice - reduction
    ).toFixed(2);
    updatedFields[index].percentage = e.target.value;
    setFields(updatedFields);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedProduct = {
      ...product,
      [product.category === Categories.snack ||
      product.category === Categories.sweet ||
      product.category === Categories.dessert
        ? "quantityFields"
        : "weightFields"]: fields,
    };
    dispatch(editProductStart(updatedProduct));
    toggleModal();
  };

  const cancelSale = () => {
    const updatedProduct = {
      ...product,
      [product.category === Categories.snack ||
      product.category === Categories.sweet ||
      product.category === Categories.dessert
        ? "quantityFields"
        : "weightFields"]: fields.map((field) => ({
        ...field,
        salePrice: null,
        percentage: null,
      })),
    };
    dispatch(editProductStart(updatedProduct));
    toggleModal();
  };

  const renderFormFields = () => {
    return fields.map((field, index) => (
      <div key={index} className="form-field">
        {console.log(field)}
        <p className="quantity-and-price">
          Pour {field.productQuantity || field.productWeight}
          {product.category !== Categories.snack &&
            product.category !== Categories.sweet &&
            "g"}
          :
          <span className="actual-price">
            {parseFloat(field.productPrice).toFixed(2)}€
          </span>
          {field.salePrice != null && field.salePrice !== 0 && (
            <span className="sale-price-container">
              Tarif promotionnel:{" "}
              <span className="sale-price">
                {parseFloat(field.salePrice).toFixed(2)}€
              </span>
            </span>
          )}
        </p>
        <div className="input-group">
          <FormInput
            label="Tarif promotionnel"
            type="number"
            min="0"
            max={field.productPrice}
            step="0.01"
            value={field.salePrice || ""}
            name={`salePrice-${index}`}
            id={`salePrice-${index}`}
            handleChange={(e) => handleSalePriceChange(e, index)}
          />
          <span className="separator">ou</span>
          <FormInput
            label="Réduction en pourcentage"
            type="number"
            min="0"
            max="100"
            step="5"
            value={field.percentage || ""}
            name={`percentage-${index}`}
            id={`percentage-${index}`}
            handleChange={(e) => handlePercentageChange(e, index)}
          />
        </div>
      </div>
    ));
  };

  return (
    <div className="product-price-change">
      <h4>Tarifs promotionnels pour {product.name}</h4>
      {renderFormFields()}

      <form onSubmit={handleSubmit}>
        <FormButton type="submit">Valider</FormButton>
      </form>

      <button className="cancel-sale-btn" onClick={cancelSale}>
        Annuler la promotion
      </button>
    </div>
  );
};

export default ProductPriceChange;
