import React, {useState} from "react";
import {useDispatch} from "react-redux";

import FormButton from "../../../../components/FormButton";
import Modal from "../../../../components/Modal";
import AddProduct from "./components/AddProduct";
import ProductList from "./components/ProductList";

import {resetProductError} from "../../../../redux/product/productActions";

import "./styles.scss";

const AdminProducts = ({products}) => {
  const dispatch = useDispatch();
  const [hideModal, setHideModal] = useState(true);

  const toggleModal = () => {
    setHideModal(!hideModal);
    dispatch(resetProductError());
  };
  const configModal = {hideModal, toggleModal};

  return (
    <div className="admin-products">
      <h2>Produits</h2>
      <div className="admin-button">
        <FormButton onClick={() => toggleModal()}>
          Ajouter un produit
        </FormButton>
      </div>

      <Modal {...configModal} size="big">
        <AddProduct hideModal={setHideModal} />
      </Modal>

      <ProductList products={products} />
    </div>
  );
};

export default AdminProducts;
