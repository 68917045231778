import React, {useState} from "react";
import AdminRewardCard from "./components/AdminRewardCard";
import Modal from "../../../../../../components/Modal";
import EditReward from "./components/EditReward";

import "./styles.scss";

const RewardList = ({rewards}) => {
  const [hideModal, setHideModal] = useState(true);
  const [rewardToEdit, setRewardToEdit] = useState(null);

  const toggleModal = (rewardReturned) => {
    setHideModal(!hideModal);
    setRewardToEdit(rewardReturned);
  };

  const configModal = {hideModal, toggleModal};

  const renderCategory = (pointsRequired) => {
    return rewards
      .filter((reward) => reward.cost === pointsRequired)
      .map((reward, index) => (
        <AdminRewardCard
          key={index}
          reward={reward}
          fromAdmin={true}
          openModal={toggleModal}
        />
      ));
  };

  const renderPointList = () => {
    if (rewards.length < 1) return null;

    const pointList = [...new Set(rewards.map((reward) => reward.cost))].sort(
      (a, b) => a - b
    );

    return pointList.map((point, index) => (
      <React.Fragment key={index}>
        <h6>{point} points</h6>
        <div className="admin-rewards-category">{renderCategory(point)}</div>
      </React.Fragment>
    ));
  };

  return (
    <div className="admin-reward-list">
      <ul className="reward-item-list">{renderPointList()}</ul>

      <Modal {...configModal} size="big">
        <EditReward hideModal={setHideModal} reward={rewardToEdit} />
      </Modal>
    </div>
  );
};

export default RewardList;
