import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import Loader from "../../../../../../components/Loader";
import FormSelect from "../../../../../../components/FormSelect";
import FormInput from "../../../../../../components/FormInput";
import FormButton from "../../../../../../components/FormButton";
import Textarea from "../../../../../../components/Textarea";
import MultipleInputArray from "../../../../../../components/MultipleInputArray";
import FileUpload from "../../../../../../components/FileUpload";

import {uploadImage} from "../../../../../../services/firestore";
import {
  hasAddProductFormErrors,
  hasInputError,
} from "../../../../../../utilities/formHelpers";
import {addProductStart} from "../../../../../../redux/product/productActions";

import "./styles.scss";
import QuantityPriceFields from "./components/QuantityPriceFields/PriceFields";
import WeightPriceFields from "./components/WeightPriceFields/WeightPriceFields";
import {Categories} from "../../../../../../utilities/enums";

const AddProduct = ({hideModal}) => {
  const dispatch = useDispatch();
  const {productErrors} = useSelector((state) => state.productData);

  const [fields, setFields] = useState({
    quantityFields: [
      {productQuantity: 0, productPrice: "", salePrice: 0, productCost: ""},
    ],
    weightFields: [
      {productWeight: 0, productPrice: "", salePrice: 0, productCost: ""},
    ],
    productCategory: "",
    productName: "",
    productDescription: "",
    productIngredients: [],
    productThumbnail: "",
    productSpiciness: 0,
    isProductSnack: false,
    isProductShop: false,
    inMenu: false,
    isHalal: false,
    isVeggie: false,
    isSea: false,
    isUntransformed: false,
    isLoading: false,
  });

  const handleChange = (field, value) => {
    setFields((prevState) => ({...prevState, [field]: value}));
  };

  const toggleButton = (field, value) => {
    setFields((prevState) => ({...prevState, [field]: value}));
  };

  const removeIngredients = (ingredientToRemove) => {
    setFields((prevState) => ({
      ...prevState,
      productIngredients: prevState.productIngredients.filter(
        (ingredient) => ingredient !== ingredientToRemove
      ),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleChange("isLoading", true);

    let product = {
      category: fields.productCategory,
      name: fields.productName,
      description: fields.productDescription,
      ingredients: fields.productIngredients,
      spiciness: parseInt(fields.productSpiciness),
      isSnack: fields.isProductSnack,
      isShop: fields.isProductShop,
      isHalal: fields.isHalal,
      isVeggie: fields.isVeggie,
      isSea: fields.isSea,
      inMenu: fields.inMenu,
      isUntransformed: fields.isUntransformed,
      forSale: false,
    };

    if (
      product.category === Categories.snack ||
      product.category === Categories.sweet ||
      product.category === Categories.dessert
    ) {
      product.quantityFields = fields.quantityFields.filter(
        (field) => field.productPrice !== ""
      );
    } else {
      product.weightFields = fields.weightFields.filter(
        (field) => field.productPrice !== ""
      );
    }

    try {
      const downloadUrl = await uploadImage(
        "productImages",
        fields.productThumbnail
      );
      product.thumbnail = downloadUrl;

      if (hasAddProductFormErrors(product)) return;
      dispatch(addProductStart(product));
      hideModal(true);
    } catch (err) {
      console.log(err);
    } finally {
      handleChange("isLoading", false);
    }
  };

  const categoryOptions = [
    {value: "", disabled: true, name: "Selectionner une catégorie"},
    {value: "snack", name: "Snack"},
    {value: "sandwich", name: "Sandwich"},
    {value: "meal", name: "Plat"},
    {value: "accompaniment", name: "Accompagnement"},
    {value: "dessert", name: "Dessert"},
    {value: "drink", name: "Boisson"},
    {value: "jar", name: "Bocal"},
    {value: "frozen", name: "Surgelé"},
    {value: "seasoning", name: "Assaisonnement"},
    {value: "jam", name: "Confiture"},
    {value: "sugar", name: "Sucrerie"},
  ];

  if (fields.isLoading) return <Loader />;

  return (
    <div className="add-product-form">
      <form onSubmit={handleSubmit}>
        <h2>Ajout d'un produit</h2>
        <div className="block1">
          <div className="left">
            <FileUpload
              setFile={(file) => handleChange("productThumbnail", file)}
              label="Image du produit"
              name="productThumbnail"
              id="productThumbnail"
              value={fields.productThumbnail}
              hasError={hasInputError(productErrors, "thumbnail")}
            />
          </div>
          <div className="right">
            <FormInput
              label="Nom du produit"
              id="productName"
              name="productName"
              type="text"
              value={fields.productName}
              placeholder="Nom du produit"
              handleChange={(e) => handleChange("productName", e.target.value)}
              hasError={hasInputError(productErrors, "name")}
            />
            <FormSelect
              id="productCategory"
              label="Categorie"
              options={categoryOptions}
              value={fields.productCategory}
              handleChange={(e) =>
                handleChange("productCategory", e.target.value)
              }
              hasError={hasInputError(productErrors, "category")}
            />
            <div className="checkboxes">
              <div className="left">
                <div className="restaurant-checkbox">
                  <p>Restaurant</p>
                  <div className="checkbox">
                    <button
                      type="button"
                      className={fields.isProductSnack ? "active" : ""}
                      onClick={() => toggleButton("isProductSnack", true)}
                    >
                      Oui
                    </button>
                    <button
                      type="button"
                      className={!fields.isProductSnack ? "active" : ""}
                      onClick={() => toggleButton("isProductSnack", false)}
                    >
                      Non
                    </button>
                  </div>
                </div>
                <div className="shop-checkbox">
                  <p>Epicerie</p>
                  <div className="checkbox">
                    <button
                      type="button"
                      className={fields.isProductShop ? "active" : ""}
                      onClick={() => toggleButton("isProductShop", true)}
                    >
                      Oui
                    </button>
                    <button
                      type="button"
                      className={!fields.isProductShop ? "active" : ""}
                      onClick={() => toggleButton("isProductShop", false)}
                    >
                      Non
                    </button>
                  </div>
                </div>
                <div className="menu-checkbox">
                  <p>En menu</p>
                  <div className="checkbox">
                    <button
                      type="button"
                      className={fields.inMenu ? "active" : ""}
                      onClick={() => toggleButton("inMenu", true)}
                    >
                      Oui
                    </button>
                    <button
                      type="button"
                      className={!fields.inMenu ? "active" : ""}
                      onClick={() => toggleButton("inMenu", false)}
                    >
                      Non
                    </button>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="halal-checkbox">
                  <p>Halal</p>
                  <div className="checkbox">
                    <button
                      type="button"
                      className={fields.isHalal ? "active" : ""}
                      onClick={() => toggleButton("isHalal", true)}
                    >
                      Oui
                    </button>
                    <button
                      type="button"
                      className={!fields.isHalal ? "active" : ""}
                      onClick={() => toggleButton("isHalal", false)}
                    >
                      Non
                    </button>
                  </div>
                </div>
                <div className="veggie-checkbox">
                  <p>Végétarien</p>
                  <div className="checkbox">
                    <button
                      type="button"
                      className={fields.isVeggie ? "active" : ""}
                      onClick={() => toggleButton("isVeggie", true)}
                    >
                      Oui
                    </button>
                    <button
                      type="button"
                      className={!fields.isVeggie ? "active" : ""}
                      onClick={() => toggleButton("isVeggie", false)}
                    >
                      Non
                    </button>
                  </div>
                </div>
                <div className="untransformed-checkbox">
                  <p>Produit transformé</p>
                  <div className="checkbox">
                    <button
                      type="button"
                      className={!fields.isUntransformed ? "active" : ""}
                      onClick={() => toggleButton("isUntransformed", false)}
                    >
                      Oui
                    </button>
                    <button
                      type="button"
                      className={fields.isUntransformed ? "active" : ""}
                      onClick={() => toggleButton("isUntransformed", true)}
                    >
                      Non
                    </button>
                  </div>
                </div>
              </div>
              <div className="spicy-checkbox">
                <p>Piment</p>
                <div className="checkbox">
                  {[0, 1, 2, 3].map((level) => (
                    <button
                      key={level}
                      type="button"
                      className={
                        fields.productSpiciness === level ? "active" : ""
                      }
                      onClick={() => handleChange("productSpiciness", level)}
                    >
                      {level}
                    </button>
                  ))}
                </div>
              </div>
              <div className="sea-checkbox">
                <p>Mer ?</p>
                <div className="checkbox">
                  <button
                    type="button"
                    className={fields.isSea ? "active" : ""}
                    onClick={() => toggleButton("isSea", true)}
                  >
                    Oui
                  </button>
                  <button
                    type="button"
                    className={!fields.isSea ? "active" : ""}
                    onClick={() => toggleButton("isSea", false)}
                  >
                    Non
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="block2">
          <div className="left">
            {fields.productCategory === Categories.snack ||
            fields.productCategory === Categories.sweet ||
            fields.productCategory === Categories.dessert ? (
              <QuantityPriceFields
                fields={fields.quantityFields}
                setFields={(data) => handleChange("quantityFields", data)}
              />
            ) : (
              <WeightPriceFields
                fields={fields.weightFields}
                setFields={(data) => handleChange("weightFields", data)}
                isQuantity={false}
              />
            )}
          </div>
          <div className="right">
            <Textarea
              label="Description"
              name="productDescription"
              id="productDescription"
              value={fields.productDescription}
              updateText={(text) => handleChange("productDescription", text)}
              hasError={hasInputError(productErrors, "description")}
            />
          </div>
        </div>
        <div className="ingredients-and-barcode">
          <MultipleInputArray
            id="productIngredients"
            label="Ingrédients"
            name="productIngredients"
            array={fields.productIngredients}
            addToArray={(ingredients) =>
              handleChange("productIngredients", ingredients)
            }
            removeFromArray={removeIngredients}
            hasError={hasInputError(productErrors, "ingredients")}
          />
        </div>
        <FormButton type="submit">Ajouter le produit</FormButton>
      </form>
    </div>
  );
};

export default AddProduct;
