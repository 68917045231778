import appTypes from './appTypes';

const INITIAL_STATE = {
  feedbackMessage: {
    type: undefined,
    text: undefined,
  },
  customerMessages: [],
  messageErrors: [],
};

const appReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case appTypes.SET_FEEDBACK_MESSAGE:
      return {
        ...state,
        feedbackMessage: action.payload,
      };
    case appTypes.RESET_FEEDBACK_MESSAGE:
      return {
        ...state,
        feedbackMessage: '',
      };

    case appTypes.SET_MESSAGES:
      return {
        ...state,
        customerMessages: action.payload,
      };

    case appTypes.MESSAGE_ERROR:
      return {
        ...state,
        messageErrors: [...state.messageErrors, action.payload],
      };
    case appTypes.RESET_MESSAGE_ERROR:
      return {
        ...state,
        messageErrors: [],
      };

    default:
      return state;
  }
};

export default appReducer;
