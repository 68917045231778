import React from "react";
import {renderProductNameAndQty} from "../../../../../../../../../../utilities/functions";

const AdminOrderItemMenu = ({menu}) => {
  return (
    <li className="ticket-order-menu">
      <p className="ticket-order-menu-type">Menu: {menu.type}</p>
      <div className="ticket-order-menu-items">
        <div className="ticket-order-menu-snack">
          {menu.snack && renderProductNameAndQty(menu.snack, menu.type)}
        </div>
        <div className="ticket-order-menu-meal">
          {menu.meal && renderProductNameAndQty(menu.meal, menu.type)}
        </div>
        <div className="ticket-order-menu-accompaniment">
          {menu.accompaniment &&
            renderProductNameAndQty(menu.accompaniment, menu.type)}
        </div>
        <div className="ticket-order-menu-drink">
          {menu.drink && renderProductNameAndQty(menu.drink, menu.type)}
        </div>
        <div className="ticket-order-menu-dessert">
          {menu.dessert && renderProductNameAndQty(menu.dessert, menu.type)}
        </div>
        <div className="ticket-order-menu-dessert">
          {menu.seasoning && renderProductNameAndQty(menu.seasoning, menu.type)}
        </div>
      </div>
    </li>
  );
};

export default AdminOrderItemMenu;
