import React, {useState, useEffect} from 'react';

const Clock = () => {
  const [dateState, setDateState] = useState(new Date());

  useEffect(() => {
    setInterval(() => setDateState(new Date()), 30000);
  }, []);

  return (
    <p>
      {dateState.toLocaleString('fr-FR', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
      })}
    </p>
  );
};

export default Clock;
