import React, {useState} from "react";
import {useSelector} from "react-redux";

import TicketItem from "./components/TicketItem";
import OrderTotal from "../../../../components/OrderRecap/components/OrderTotal";
import TicketMenuItem from "./components/TicketMenuItem";
import MailOrderButton from "../../../../components/MailOrderButton";

import {extractOrderNumber, formatDate} from "../../../../utilities/functions";

import "./styles.scss";
// import Modal from "../../../../components/Modal";

const OrderCheckoutTicket = () => {
  const {currentOrder} = useSelector((mapState) => mapState.orderData);

  const renderOrderMenus = () => {
    if (currentOrder.menus.length < 1) return;

    return currentOrder.menus.map((menu, index) => {
      return <TicketMenuItem key={index} menu={menu} />;
    });
  };

  const renderOrderItems = () => {
    if (currentOrder.orderItems.length < 1) return;

    return currentOrder.orderItems.map((item, index) => {
      return <TicketItem key={index} product={item} />;
    });
  };

  const renderDate = () => {
    if (currentOrder.orderCreatedDate) {
      return (
        <>
          <p className="date">Le {formatDate(currentOrder.orderCreatedDate)}</p>
          {currentOrder.scheduled && (
            <p className="date">Pour le {formatDate(currentOrder.scheduled)}</p>
          )}
        </>
      );
    }
  };

  const renderCustomerName = () => {
    if (
      currentOrder.customer &&
      currentOrder.customer.firstName &&
      currentOrder.customer.lastName
    ) {
      return (
        <p className="customer-name">
          Pour {currentOrder.customer.firstName}{" "}
          {currentOrder.customer.lastName}
        </p>
      );
    }
  };

  const renderReward = () => {
    if (!currentOrder.reward) return;
    return (
      <div className="reward-item">
        <p>{currentOrder.reward.element}</p>
        <p>{currentOrder.reward.cost} points</p>
      </div>
    );
  };

  if (!currentOrder) return;

  return (
    <div className="order-checkout-ticket">
      <h2>Ticket {extractOrderNumber(currentOrder.orderNumber)}</h2>
      {renderDate()}
      {renderCustomerName()}

      <MailOrderButton currentOrder={currentOrder} />

      <div className="order-checkout-ticket-current-order">
        <ul className="current-checkout-ticket-cart-items">
          {renderOrderMenus()}
          {renderOrderItems()}
          {renderReward()}
        </ul>
        <OrderTotal />
        {/* <button onClick={() => toggleModal()}>Appliquer une remise</button> */}
      </div>
      {/* <Modal {...configModal}>Remise</Modal> */}
    </div>
  );
};

export default OrderCheckoutTicket;
