const orderTypes = {
  GET_PENDING_ORDERS_START: "GET_PENDING_ORDERS_START",
  SET_PENDING_ORDERS: "SET_PENDING_ORDERS",
  CLEAR_ORDERS: "CLEAR_ORDERS",

  ADD_TO_CART: "ADD_TO_CART",
  REDUCE_ITEM: "REDUCE_ITEM",
  REMOVE_ITEM: "REMOVE_ITEM",
  CLEAR_CURRENT_ORDER: "CLEAR_CURRENT_ORDER",
  DELETE_ORDER: "DELETE_ORDER",

  ADD_MENU_TO_ORDER: "ADD_MENU_TO_ORDER",
  REMOVE_MENU_FROM_ORDER: "REMOVE_MENU_FROM_ORDER",

  SET_PAYMENT_METHOD: "SET_PAYMENT_METHOD",
  SET_ORDER_NUMBER: "SET_ORDER_NUMBER",
  SAVE_ORDER_CUSTOMER: "SAVE_ORDER_CUSTOMER",

  FETCH_ORDER_START: "FETCH_ORDER_START",
  SET_ORDER: "SET_ORDER",
  SEND_ORDER_START: "SEND_ORDER_START",
  CLOSE_ORDER_START: "CLOSE_ORDER_START",

  CUSTOMER_SIGN_UP_START: "CUSTOMER_SIGN_UP_START",
  CUSTOMER_SIGN_UP_SUCCESS: "CUSTOMER_SIGN_UP_SUCCESS",

  FETCH_ORDERS_BY_DATE: "FETCH_ORDERS_BY_DATE",
  SET_ORDERS_BY_DATE: "SET_ORDERS_BY_DATE",
  RESET_DAYS_ORDERS: "RESET_DAYS_ORDERS",

  ADD_REWARD_TO_ORDER: "ADD_REWARD_TO_ORDER",
  REMOVE_REWARD_FROM_ORDER: "REMOVE_REWARD_FROM_ORDER",

  GET_ORDER_NUMBER_START: "GET_ORDER_NUMBER_START",
};

export default orderTypes;
