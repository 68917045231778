import {takeLatest, all, call, put} from 'redux-saga/effects';
import appTypes from './appTypes';
import {setFeedbackMessage, fetchMessages, setMessages} from './appActions';
import {handleDeleteMessage, handleFetchMessages} from './appHelpers';

export function* GetMessages() {
  try {
    const messages = yield handleFetchMessages();
    yield put(setMessages(messages));
  } catch (err) {
    console.log(err);
  }
}

export function* onFetchMessageStart() {
  yield takeLatest(appTypes.FETCH_MESSAGE_START, GetMessages);
}

export function* deleteMessage({payload}) {
  try {
    yield handleDeleteMessage(payload);
    yield put(fetchMessages());
  } catch (err) {
    console.log(err);
  }
}

export function* onDeleteMessageStart() {
  yield takeLatest(appTypes.DELETE_MESSAGE_START, deleteMessage);
}

export default function* messageSagas() {
  yield all([call(onFetchMessageStart), call(onDeleteMessageStart)]);
}
