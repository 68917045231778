import React, {useState} from "react";
import {useSelector} from "react-redux";

import "./styles.scss";
import {createStructuredSelector} from "reselect";
import {calculateTotal} from "../../../../../../../utilities/functions";
import {
  selectMenuTotal,
  selectOrderTotal,
} from "../../../../../../../redux/order/orderSelectors";

const mapState = createStructuredSelector({
  cartTotal: selectOrderTotal,
  menuTotal: selectMenuTotal,
});

const ChangeDue = ({toggleModal}) => {
  const {cartTotal, menuTotal} = useSelector(mapState);

  const [change, setChange] = useState(0);
  return (
    <div className="change-due-container">
      <h6>Somme donnée par le client</h6>
      <input onChange={(e) => setChange(e.target.value)} />
      {change > 0 && (
        <div className="change">
          A rendre: {(change - calculateTotal(cartTotal, menuTotal)).toFixed(2)}
          €
        </div>
      )}

      <button onClick={() => toggleModal()}>Ok</button>
    </div>
  );
};

export default ChangeDue;
