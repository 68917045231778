import React, {useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ReactSearchAutocomplete} from "react-search-autocomplete";

import {saveCurrentOrderUser} from "../../../../../../redux/order/orderActions";

import "./styles.scss";
import {setCustomerLoyalty} from "../../../../../../redux/user/userActions";

const SearchBar = () => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const {customerList} = useSelector((mapState) => mapState.userData);

  const handleOnSelect = (item) => {
    const customer = {
      id: item.documentId,
      email: item.email,
      firstName: item.firstName,
      lastName: item.lastName,
    };

    const customerLoyalty = {
      loyaltyLevel: item.loyaltyLevel,
      loyaltyPoints: item.loyaltyPoints,
      areLoyaltyPointsBeingUsed: item.areLoyaltyPointsBeingUsed,
    };

    inputRef.current.children[0].children[0].children[0].children[1].blur();

    dispatch(saveCurrentOrderUser(customer));
    dispatch(setCustomerLoyalty(customerLoyalty));
  };

  const handleOnClear = () => {
    const customer = null;

    dispatch(saveCurrentOrderUser(customer));
    inputRef.current.children[0].children[0].children[0].children[1].blur();
  };

  const formatResult = (item) => {
    return (
      <div className="search-result-line">
        <span style={{display: "block", textAlign: "left"}}>{item.email}</span>
        <span>
          {item.firstName} {item.lastName}
        </span>
      </div>
    );
  };

  return (
    <div ref={inputRef} className="search-bar-custom">
      <ReactSearchAutocomplete
        items={customerList}
        onSelect={handleOnSelect}
        onClear={handleOnClear}
        fuseOptions={{
          threshold: 0.0,
          distance: 1000,
          minMatchCharLength: 2,
          keys: ["email", "lastName"],
        }}
        autoFocus={false}
        resultStringKeyName="email"
        showNoResultsText="Aucun résultat"
        formatResult={formatResult}
        // inputSearchString={emailSearch && emailSearch}
      />
    </div>
  );
};

export default SearchBar;
