import React from "react";

import {persistor} from "../../../../redux/createStore";
import "./styles.scss";

const AdminBar = ({activeItem, setActiveItem, numberOfMessages}) => {
  const logout = () => {
    persistor.purge();
    window.location.reload();
  };
  return (
    <div className="admin-bar">
      <ul className="admin-bar-menu">
        <li
          className={`admin-bar-menu-item ${
            activeItem === "products" && "active"
          }`}
          onClick={() => setActiveItem("products")}
        >
          Produits
        </li>
        <li
          className={`admin-bar-menu-item ${
            activeItem === "loyalty" && "active"
          }`}
          onClick={() => setActiveItem("loyalty")}
        >
          Fidélité
        </li>
        <li
          className={`admin-bar-menu-item ${
            activeItem === "orders" && "active"
          }`}
          onClick={() => setActiveItem("orders")}
        >
          Commandes
        </li>
        <li
          className={`admin-bar-menu-item ${
            activeItem === "email" && "active"
          }`}
          onClick={() => setActiveItem("email")}
        >
          Emailing
        </li>
        <li
          className={`admin-bar-menu-item ${
            activeItem === "messages" && "active"
          }`}
          onClick={() => setActiveItem("messages")}
        >
          Messages ({numberOfMessages})
        </li>
      </ul>
      <p className="logout" onClick={() => logout()}>
        Déconnexion
      </p>
    </div>
  );
};

export default AdminBar;
