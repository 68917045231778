import productTypes from './productTypes';

export const addProductStart = (productData) => ({
  type: productTypes.ADD_NEW_PRODUCT_START,
  payload: productData,
});

export const editProductStart = (product) => ({
  type: productTypes.EDIT_PRODUCT_START,
  payload: product,
});

export const deleteProductStart = (productId) => ({
  type: productTypes.DELETE_PRODUCT_START,
  payload: productId,
});

export const fetchProductsStart = () => ({
  type: productTypes.FETCH_PRODUCTS_START,
});

export const setProducts = (products) => ({
  type: productTypes.SET_PRODUCTS,
  payload: products,
});

export const clearProducts = () => ({
  type: productTypes.CLEAR_PRODUCTS,
});

/** ANCHOR Product errors actions */
export const productError = (error) => ({
  type: productTypes.PRODUCT_ERROR,
  payload: error,
});

export const resetProductError = () => ({
  type: productTypes.RESET_PRODUCT_ERROR,
});

export const updateProductList = (editedProduct) => ({
  type: productTypes.UPDATE_PRODUCTS,
  payload: editedProduct,
});
