import React from 'react';

import './styles.scss';

const TicketMenuItem = ({menu}) => {
  return (
    <li className="ticket-menu-item">
      <div className="ticket-menu-type-and-price">
        <span className="ticket-menu-type">{menu.type}</span>
        <span className="ticket-menu-price">{menu.price.toFixed(2)}€</span>
      </div>
    </li>
  );
};

export default TicketMenuItem;
