import React, {useEffect, useState} from "react";

import MenuCategories from "./components/MenuCategories";
import ProductList from "./components/ProductList";
import OrderRecap from "../../components/OrderRecap";

import {useDispatch, useSelector} from "react-redux";
import {
  clearProducts,
  fetchProductsStart,
} from "../../redux/product/productActions";

import "./styles.scss";

const TakeOrder = () => {
  const dispatch = useDispatch();
  const {products} = useSelector((mapState) => mapState.productData);
  const [activeCard, setActiveCard] = useState("");

  useEffect(() => {
    // dispatch(clearProducts());
    dispatch(fetchProductsStart());
  }, []);

  return (
    <div className="take-order">
      <MenuCategories
        products={products}
        activeCard={activeCard}
        updateActiveCard={setActiveCard}
      />
      <ProductList activeCard={activeCard} />
      <OrderRecap />
    </div>
  );
};

export default TakeOrder;
