import React from "react";
import {useDispatch} from "react-redux";

import MenuProductCard from "../MenuProductsCategory/components/MenuProductCard";

import {MenuTypes} from "../../../../../../../../utilities/enums";
import {addMenuToOrder} from "../../../../../../../../redux/order/orderActions";

import "./styles.scss";

const CreatedMenu = ({menu, menuType, clearMenu, setMenuType}) => {
  const dispatch = useDispatch();

  if (
    !menu.snack &&
    !menu.meal &&
    !menu.accompaniment &&
    !menu.secondAccompaniment &&
    !menu.dessert &&
    !menu.drink &&
    !menu.sandwich
  )
    return null;

  const checkValidate = () => {
    switch (menuType) {
      case MenuTypes.TiMenu:
        return !(menu.snack && menu.meal && menu.accompaniment && menu.dessert);
      case MenuTypes.MenuFilao:
        return !(
          menu.snack &&
          menu.meal &&
          menu.accompaniment &&
          menu.dessert &&
          menu.drink
        );
      case MenuTypes.MenuKonple:
        return !(
          menu.snack &&
          menu.meal &&
          menu.accompaniment &&
          menu.secondAccompaniment &&
          menu.dessert &&
          menu.drink
        );
      case MenuTypes.SeaMenu:
        return !(
          menu.snack &&
          menu.meal &&
          menu.accompaniment &&
          menu.secondAccompaniment &&
          menu.dessert &&
          menu.drink
        );

      case MenuTypes.MenuSandwich:
        return !(menu.sandwich && menu.drink && menu.sauce);
      default:
        return true;
    }
  };

  const renderSelectedSnacks = (snackSelection) => {
    const snackCounts = snackSelection.reduce((acc, snack) => {
      acc[snack.name] = (acc[snack.name] || 0) + 1;
      return acc;
    }, {});

    return Object.entries(snackCounts).map(([snackName, count], index) => (
      <p key={index}>
        {count} {snackName}
      </p>
    ));
  };

  const handleValidate = () => {
    const newMenu = {...menu, id: Math.random().toString(16).slice(2)};
    if (newMenu.type !== MenuTypes.MenuSandwich) {
      newMenu.sauce = null;
    }
    dispatch(addMenuToOrder(newMenu));
    clearMenu();
    setMenuType(null);
  };

  const renderProductCard = (product, isSmall = true) => {
    if (typeof product === "string") {
      return (
        <div className="menu-product-card-item menu-product-small no-product-div">
          {product}
        </div>
      );
    } else if (Array.isArray(product)) {
      return (
        <div className="menu-product-card-item menu-product-small mix-snack">
          <h6>Snack Mix</h6>
          <div>{renderSelectedSnacks(product)}</div>
        </div>
      );
    } else {
      return (
        <MenuProductCard
          product={product}
          isSmall={isSmall}
          menuType={menuType}
        />
      );
    }
  };

  return (
    <div className="created-menu-container">
      <h2>Menu créé: {menuType}</h2>
      <div className="created-menu">
        {menu.sandwich && renderProductCard(menu.sandwich)}
        {menu.snack && renderProductCard(menu.snack)}
        {menu.meal && renderProductCard(menu.meal)}
        {menu.accompaniment && renderProductCard(menu.accompaniment)}
        {menu.dessert && renderProductCard(menu.dessert)}
        {menu.drink && renderProductCard(menu.drink)}
        {menu.secondAccompaniment &&
          renderProductCard(menu.secondAccompaniment, true, true)}
        {menu.sandwich && menu.sauce && (
          <p className="sauce">Sauce: {menu.sauce}</p>
        )}
        {menu.seasoning && renderProductCard(menu.seasoning)}
      </div>
      <button
        className="validate-menu-button"
        disabled={checkValidate()}
        onClick={handleValidate}
      >
        Valider le menu ({menu.price.toFixed(2)}€)
      </button>
    </div>
  );
};

export default CreatedMenu;
