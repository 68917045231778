import React, {useRef, useState} from "react";

import AdminProductCard from "./components/AdminProductCard";
import Modal from "../../../../../../components/Modal";
import EditProduct from "../EditProduct";

import {Categories} from "../../../../../../utilities/enums";

import "./styles.scss";
import ProductPriceChange from "./components/ProductPriceChange";
import ScrollToTopButton from "../../../../../../components/ScrollToTopButton";

const ProductList = ({products}) => {
  const [productToEdit, setProductToEdit] = useState(null);
  const [productPriceToChange, setProductPriceToChange] = useState(null);

  const elementsRefs = useRef([]);
  const componentRef = useRef(null);

  const [hideModal, setHideModal] = useState(true);
  const [hideSaleModal, setHideSaleModal] = useState(true);

  const toggleModal = (productReturned) => {
    setHideModal(!hideModal);
    setProductToEdit(productReturned);
  };

  const toggleSaleModal = (productReturned) => {
    setHideSaleModal(!hideSaleModal);
    setProductPriceToChange(productReturned);
  };

  const configModal = {hideModal, toggleModal};

  const renderCategory = (category) => {
    // Create a new array and sort it by the name attribute
    const sortedItems = [...products].sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });

    return sortedItems.map((product, index) => {
      if (product.category === category) {
        return (
          <AdminProductCard
            key={index}
            product={product}
            openModal={toggleModal}
            openSaleModal={toggleSaleModal}
          />
        );
      }
    });
  };

  const scrollToElement = (index) => {
    if (elementsRefs.current[index]) {
      elementsRefs.current[index].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  if (products.length < 1) return;

  return (
    <div className="admin-product-list" ref={componentRef}>
      <ul className="admin-product-menu-nav">
        <li onClick={() => scrollToElement(0)}>Snacks</li>
        <li onClick={() => scrollToElement(1)}>Sandwichs</li>
        <li onClick={() => scrollToElement(2)}>Plats</li>
        <li onClick={() => scrollToElement(3)}>Accompagnements</li>
        <li onClick={() => scrollToElement(4)}>Desserts</li>
        <li onClick={() => scrollToElement(5)}>Boissons</li>
        <li onClick={() => scrollToElement(6)}>Bocaux</li>
        <li onClick={() => scrollToElement(7)}>Surgelés</li>
        <li onClick={() => scrollToElement(8)}>Assaisonements</li>
        <li onClick={() => scrollToElement(9)}>Confitures</li>
        <li onClick={() => scrollToElement(10)}>Sucreries</li>
      </ul>
      <ul className="product-item-list">
        <h6 ref={(el) => (elementsRefs.current[0] = el)}>Snacks</h6>
        <div className="admin-products-category">
          {renderCategory(Categories.snack)}
        </div>

        <h6 ref={(el) => (elementsRefs.current[1] = el)}>Sandwichs</h6>
        <div className="admin-products-category">
          {renderCategory(Categories.sandwich)}
        </div>

        <h6 ref={(el) => (elementsRefs.current[2] = el)}>Plats</h6>
        <div className="admin-products-category">
          {renderCategory(Categories.meal)}
        </div>

        <h6 ref={(el) => (elementsRefs.current[3] = el)}>Accompagnements</h6>
        <div className="admin-products-category">
          {renderCategory(Categories.accompaniment)}
        </div>

        <h6 ref={(el) => (elementsRefs.current[4] = el)}>Desserts</h6>
        <div className="admin-products-category">
          {renderCategory(Categories.dessert)}
        </div>

        <h6 ref={(el) => (elementsRefs.current[5] = el)}>Boissons</h6>
        <div className="admin-products-category">
          {renderCategory(Categories.drink)}
        </div>

        <h6 ref={(el) => (elementsRefs.current[6] = el)}>Bocaux</h6>
        <div className="admin-products-category">
          {renderCategory(Categories.jar)}
        </div>

        <h6 ref={(el) => (elementsRefs.current[7] = el)}>Surgelés</h6>
        <div className="admin-products-category">
          {renderCategory(Categories.frozen)}
        </div>

        <h6 ref={(el) => (elementsRefs.current[8] = el)}>Assaisonements</h6>
        <div className="admin-products-category">
          {renderCategory(Categories.seasoning)}
        </div>

        <h6 ref={(el) => (elementsRefs.current[9] = el)}>Confitures</h6>
        <div className="admin-products-category">
          {renderCategory(Categories.jam)}
        </div>

        <h6 ref={(el) => (elementsRefs.current[10] = el)}>Sucreries</h6>
        <div className="admin-products-category">
          {renderCategory(Categories.sweet)}
        </div>
      </ul>

      <Modal {...configModal} size="big">
        <EditProduct hideModal={setHideModal} product={productToEdit} />
      </Modal>

      <Modal hideModal={hideSaleModal} toggleModal={toggleSaleModal}>
        <ProductPriceChange
          product={productPriceToChange}
          toggleModal={toggleSaleModal}
        />
      </Modal>

      <ScrollToTopButton componentRef={componentRef} />
    </div>
  );
};

export default ProductList;
