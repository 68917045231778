import {takeLatest, put, all, call} from 'redux-saga/effects';

import rewardTypes from './rewardTypes';

import {clearRewards, setRewards} from './rewardActions';
import {
  handleAddReward,
  handleEditReward,
  handleFetchRewards,
} from './rewardHelpers';
import {setFeedbackMessage} from '../app/appActions';

/** ANCHOR fetch rewards */
export function* fetchRewards() {
  try {
    const rewards = yield handleFetchRewards();
    yield put(clearRewards());
    yield put(setRewards(rewards));
  } catch (err) {
    console.log(err);
  }
}

export function* onFetchRewardsStart() {
  yield takeLatest(rewardTypes.FETCH_REWARDS_START, fetchRewards);
}

/**ANCHOR - Add reward */
export function* addReward({payload}) {
  try {
    yield handleAddReward(payload);

    yield put(
      setFeedbackMessage({
        type: 'success',
        text: 'Récompense enregistrée',
      })
    );
    yield put(clearRewards());
  } catch (err) {
    console.log(err);
  }
}

export function* onAddRewardStart() {
  yield takeLatest(rewardTypes.ADD_NEW_REWARD, addReward);
}

/** ANCHOR Edit reward */
export function* editReward({payload}) {
  try {
    yield handleEditReward(payload);
    yield put(clearRewards());
  } catch (err) {
    console.log(err);
  }
}

export function* onEditRewardStart() {
  yield takeLatest(rewardTypes.EDIT_REWARD, editReward);
}

/** ANCHOR reward SAGAS */
export default function* rewardSagas() {
  yield all([
    call(onFetchRewardsStart),
    call(onAddRewardStart),
    call(onEditRewardStart),
  ]);
}
