import userTypes from './userTypes';

/** ANCHOR Sign in actions */
export const emailSignInStart = (userCredentials) => ({
  type: userTypes.EMAIL_SIGN_IN_START,
  payload: userCredentials,
});

export const googleSignInStart = () => ({
  type: userTypes.GOOGLE_SIGN_IN_START,
});

export const signInSuccess = (user) => ({
  type: userTypes.SIGN_IN_SUCCESS,
  payload: user,
});

/** ANCHOR Customer list actions */
export const fetchCustomerListStart = () => ({
  type: userTypes.FETCH_CUSTOMER_LIST_START,
});

export const setCustomerList = (customerList) => ({
  type: userTypes.SET_CUSTOMER_LIST,
  payload: customerList,
});

export const clearCustomerList = () => ({
  type: userTypes.CLEAR_CUSTOMER_LIST,
});

export const setCustomerLoyalty = (customerLoyalty) => ({
  type: userTypes.SET_CUSTOMER_LOYALTY,
  payload: customerLoyalty,
});

export const resetCustomerLoyalty = () => ({
  type: userTypes.RESET_CUSTOMER_LOYALTY,
});
