import React, {useState} from "react";

import "./styles.scss";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {returnNumberOfSnacks} from "../../../../../../../../../../utilities/functions";
import {MenuTypes} from "../../../../../../../../../../utilities/enums";

const MixingContainer = ({snacks, menuType, handleClick, toggleModal}) => {
  const [snackSelection, setSnackSelection] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleValidate = () => {
    // if (snackSelection.length === returnNumberOfSnacks()) {
    //   setErrorMessage(null)
    //   handleClick(snackSelection);
    // } else {
    //   setErrorMessage("Pas assez de snack")
    // }

    handleClick(snackSelection);
    toggleModal();
  };

  const handleAddSnack = (snack) => {
    const maxAllowed = returnNumberOfSnacks(menuType);

    if (snackSelection.length < maxAllowed) {
      // Check if snack already exists in the array
      if (!snackSelection.includes(snack)) {
        setSnackSelection([...snackSelection, snack]); // Add the snack
      } else {
        // Handle adding a duplicate snack
        if (snackSelection.filter((s) => s === snack).length < maxAllowed) {
          setSnackSelection([...snackSelection, snack]);
        }
      }
    }
  };

  const handleRemoveSnack = (snack) => {
    const index = snackSelection.indexOf(snack);
    if (index !== -1) {
      const newSnacks = [...snackSelection];
      newSnacks.splice(index, 1);
      setSnackSelection(newSnacks);
    }
  };

  if (!snacks) return;

  return (
    <div className="mixing-container">
      <h4>Mélange de Snacks ({returnNumberOfSnacks(menuType)} snacks)</h4>
      <div className="mixing-list">
        {snacks.map((snack, index) => {
          if (!snack.inMenu) return;
          const isSelected = snackSelection.includes(snack);
          const selectedCount = snackSelection.filter(
            (s) => s === snack
          ).length;

          // if (menuType === MenuTypes.SeaMenu) {
          //   if (!snack.isSea) return;
          // }

          return (
            <div className="snack-element" key={index}>
              <div className="snack-item">
                <img src={snack.thumbnail} alt={snack.name} />
                <p>
                  {isSelected && (
                    <span className="selected-number">{selectedCount}</span>
                  )}
                  {snack.name}
                </p>
              </div>
              <div className="arrows">
                <div
                  className="arrow arrow-down"
                  onClick={() => handleRemoveSnack(snack)}
                >
                  <KeyboardArrowDownIcon className="icon" />
                </div>
                <div
                  className="arrow arrow-up"
                  onClick={() => handleAddSnack(snack)}
                >
                  <KeyboardArrowUpIcon className="icon" />
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <p>{errorMessage && errorMessage}</p>
      <button onClick={() => handleValidate()} className="validate-button">
        Valider
      </button>
    </div>
  );
};

export default MixingContainer;
