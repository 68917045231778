import React, {useEffect, useState} from 'react';

import './styles.scss';
import {useDispatch} from 'react-redux';
import {signUpStart} from '../../../../../../redux/order/orderActions';

import FormInput from '../../../../../../components/FormInput';
import {capitalizeFirstLetter} from '../../../../../../utilities/functions';
import Loader from '../../../../../../components/Loader';

const CustomerSigninForm = ({toggleModal, currentOrder}) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (currentOrder.customer && currentOrder.customer.email === email) {
      setEmail('');
      setFirstName('');
      setLastName('');
      setIsLoading(false);
      toggleModal();
    }
  }, [currentOrder.customer]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const customer = {
      email: email,
      firstName: capitalizeFirstLetter(firstName),
      lastName: capitalizeFirstLetter(lastName),
    };

    dispatch(signUpStart(customer));
  };

  if (isLoading) return <Loader />;

  return (
    <div className="customer-signin-form">
      <h6>Adresse email du client</h6>
      <form onSubmit={handleSubmit}>
        <FormInput
          type="email"
          placeholder="Adresse email du client..."
          handleChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <FormInput
          type="text"
          placeholder="Prénom du client..."
          handleChange={(e) => setFirstName(e.target.value)}
          value={firstName}
        />
        <FormInput
          type="text"
          placeholder="Nom du client..."
          handleChange={(e) => setLastName(e.target.value)}
          value={lastName}
        />
        {/* <input
          type="email"
          placeholder="Adresse email du client..."
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        /> */}
        <button type="submit">Inscrire</button>
      </form>
    </div>
  );
};

export default CustomerSigninForm;
