import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import Loader from "../../../../../../components/Loader";
import FormSelect from "../../../../../../components/FormSelect";
import FormInput from "../../../../../../components/FormInput";
import FormButton from "../../../../../../components/FormButton";
import Textarea from "../../../../../../components/Textarea";
import MultipleInputArray from "../../../../../../components/MultipleInputArray";
import FileUpload from "../../../../../../components/FileUpload";

import {editProductStart} from "../../../../../../redux/product/productActions";
import {
  hasAddProductFormErrors,
  hasInputError,
} from "../../../../../../utilities/formHelpers";
import {uploadImage} from "../../../../../../services/firestore";

import "./styles.scss";
import QuantityPriceFields from "../AddProduct/components/QuantityPriceFields/PriceFields";
import WeightPriceFields from "../AddProduct/components/WeightPriceFields/WeightPriceFields";
import {Categories} from "../../../../../../utilities/enums";

const EditProduct = ({hideModal, product}) => {
  const dispatch = useDispatch();
  const {productErrors} = useSelector((state) => state.productData);

  const [fields, setFields] = useState({
    quantityFields: product.quantityFields || [],
    weightFields: product.weightFields || [],
    category: product.category || "",
    name: product.name || "",
    description: product.description || "",
    ingredients: product.ingredients || [],
    thumbnail: product.thumbnail || "",
    spiciness: product.spiciness || 0,
    isProductSnack: product.isSnack || false,
    isProductShop: product.isShop || false,
    inMenu: product.inMenu || false,
    isHalal: product.isHalal || false,
    isVeggie: product.isVeggie || false,
    isUntransformed: product.isUntransformed || false,
    isLoading: false,
    isSea: product.isSea || false,
  });

  const handleChange = (field, value) => {
    setFields((prevState) => ({...prevState, [field]: value}));
  };

  const toggleButton = (field, value) => {
    setFields((prevState) => ({...prevState, [field]: value}));
  };

  const removeIngredients = (ingredientToRemove) => {
    setFields((prevState) => ({
      ...prevState,
      ingredients: prevState.ingredients.filter(
        (ingredient) => ingredient !== ingredientToRemove
      ),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleChange("isLoading", true);

    let editedProduct = {
      ...fields,
      spiciness: parseInt(fields.spiciness),
      documentId: product.documentId,
      forSale: product.forSale,
    };

    if (
      editedProduct.category === Categories.snack ||
      editedProduct.category === Categories.sweet ||
      editedProduct.category === Categories.dessert
    ) {
      editedProduct.quantityFields = fields.quantityFields.filter(
        (field) => field.productPrice !== ""
      );
    } else {
      editedProduct.weightFields = fields.weightFields.filter(
        (field) => field.productPrice !== ""
      );
    }

    try {
      if (fields.thumbnail instanceof File) {
        const downloadUrl = await uploadImage(
          "productImages",
          fields.thumbnail
        );
        editedProduct.thumbnail = downloadUrl;
      }
      if (hasAddProductFormErrors(editedProduct)) {
        handleChange("isLoading", false);
        return;
      }
      dispatch(editProductStart(editedProduct));
      hideModal(true);
    } catch (err) {
      console.log(err);
    } finally {
      handleChange("isLoading", false);
    }
  };

  const categoryOptions = [
    {value: "", disabled: true, name: "Selectionner une catégorie"},
    {value: "snack", name: "Snack"},
    {value: "sandwich", name: "Sandwich"},
    {value: "meal", name: "Plat"},
    {value: "accompaniment", name: "Accompagnement"},
    {value: "dessert", name: "Dessert"},
    {value: "drink", name: "Boisson"},
    {value: "jar", name: "Bocal"},
    {value: "frozen", name: "Surgelé"},
    {value: "seasoning", name: "Assaisonnement"},
    {value: "jam", name: "Confiture"},
    {value: "sugar", name: "Sucrerie"},
  ];

  if (fields.isLoading) return <Loader />;

  return (
    <div className="add-product-form">
      <form onSubmit={handleSubmit}>
        <h2>Modification du produit</h2>
        <div className="block1">
          <div className="left">
            <FileUpload
              setFile={(file) => handleChange("thumbnail", file)}
              label="Image du produit"
              name="thumbnail"
              id="thumbnail"
              value={fields.thumbnail}
              hasError={hasInputError(productErrors, "thumbnail")}
            />
          </div>
          <div className="right">
            <FormInput
              label="Nom du produit"
              id="name"
              name="name"
              type="text"
              value={fields.name}
              placeholder="Nom du produit"
              handleChange={(e) => handleChange("name", e.target.value)}
              hasError={hasInputError(productErrors, "name")}
            />
            <FormSelect
              label="Categorie"
              options={categoryOptions}
              value={fields.category}
              handleChange={(e) => handleChange("category", e.target.value)}
              hasError={hasInputError(productErrors, "category")}
            />
            <div className="checkboxes">
              <div className="left">
                <div className="restaurant-checkbox">
                  <p>Restaurant</p>
                  <div className="checkbox">
                    <button
                      type="button"
                      className={fields.isProductSnack ? "active" : ""}
                      onClick={() => toggleButton("isProductSnack", true)}
                    >
                      Oui
                    </button>
                    <button
                      type="button"
                      className={!fields.isProductSnack ? "active" : ""}
                      onClick={() => toggleButton("isProductSnack", false)}
                    >
                      Non
                    </button>
                  </div>
                </div>
                <div className="shop-checkbox">
                  <p>Epicerie</p>
                  <div className="checkbox">
                    <button
                      type="button"
                      className={fields.isProductShop ? "active" : ""}
                      onClick={() => toggleButton("isProductShop", true)}
                    >
                      Oui
                    </button>
                    <button
                      type="button"
                      className={!fields.isProductShop ? "active" : ""}
                      onClick={() => toggleButton("isProductShop", false)}
                    >
                      Non
                    </button>
                  </div>
                </div>
                <div className="menu-checkbox">
                  <p>En menu</p>
                  <div className="checkbox">
                    <button
                      type="button"
                      className={fields.inMenu ? "active" : ""}
                      onClick={() => toggleButton("inMenu", true)}
                    >
                      Oui
                    </button>
                    <button
                      type="button"
                      className={!fields.inMenu ? "active" : ""}
                      onClick={() => toggleButton("inMenu", false)}
                    >
                      Non
                    </button>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="halal-checkbox">
                  <p>Halal</p>
                  <div className="checkbox">
                    <button
                      type="button"
                      className={fields.isHalal ? "active" : ""}
                      onClick={() => toggleButton("isHalal", true)}
                    >
                      Oui
                    </button>
                    <button
                      type="button"
                      className={!fields.isHalal ? "active" : ""}
                      onClick={() => toggleButton("isHalal", false)}
                    >
                      Non
                    </button>
                  </div>
                </div>
                <div className="veggie-checkbox">
                  <p>Végétarien</p>
                  <div className="checkbox">
                    <button
                      type="button"
                      className={fields.isVeggie ? "active" : ""}
                      onClick={() => toggleButton("isVeggie", true)}
                    >
                      Oui
                    </button>
                    <button
                      type="button"
                      className={!fields.isVeggie ? "active" : ""}
                      onClick={() => toggleButton("isVeggie", false)}
                    >
                      Non
                    </button>
                  </div>
                </div>
                <div className="untransformed-checkbox">
                  <p>Produit transformé</p>
                  <div className="checkbox">
                    <button
                      type="button"
                      className={!fields.isUntransformed ? "active" : ""}
                      onClick={() => toggleButton("isUntransformed", false)}
                    >
                      Oui
                    </button>
                    <button
                      type="button"
                      className={fields.isUntransformed ? "active" : ""}
                      onClick={() => toggleButton("isUntransformed", true)}
                    >
                      Non
                    </button>
                  </div>
                </div>
              </div>
              <div className="spicy-checkbox">
                <p>Piment</p>
                <div className="checkbox">
                  {[0, 1, 2, 3].map((level) => (
                    <button
                      key={level}
                      type="button"
                      className={fields.spiciness === level ? "active" : ""}
                      onClick={() => handleChange("spiciness", level)}
                    >
                      {level}
                    </button>
                  ))}
                </div>
              </div>
              <div className="sea-checkbox">
                <p>Mer ?</p>
                <div className="checkbox">
                  <button
                    type="button"
                    className={fields.isSea ? "active" : ""}
                    onClick={() => toggleButton("isSea", true)}
                  >
                    Oui
                  </button>
                  <button
                    type="button"
                    className={!fields.isSea ? "active" : ""}
                    onClick={() => toggleButton("isSea", false)}
                  >
                    Non
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="block2">
          <div className="left">
            {fields.category === Categories.snack ||
            fields.category === Categories.sweet ||
            fields.category === Categories.dessert ? (
              <QuantityPriceFields
                fields={fields.quantityFields}
                setFields={(data) => handleChange("quantityFields", data)}
              />
            ) : (
              <WeightPriceFields
                fields={fields.weightFields}
                setFields={(data) => handleChange("weightFields", data)}
                isQuantity={false}
              />
            )}
          </div>
          <div className="right">
            <Textarea
              label="Description"
              name="description"
              id="description"
              value={fields.description}
              updateText={(text) => handleChange("description", text)}
              hasError={hasInputError(productErrors, "description")}
            />
          </div>
        </div>
        <div className="ingredients-and-barcode">
          <MultipleInputArray
            label="Ingrédients"
            name="ingredients"
            array={fields.ingredients}
            addToArray={(ingredients) =>
              handleChange("ingredients", ingredients)
            }
            removeFromArray={removeIngredients}
            hasError={hasInputError(productErrors, "ingredients")}
          />
        </div>
        <FormButton type="submit">Mettre à jour le produit</FormButton>
      </form>
    </div>
  );
};

export default EditProduct;
