import React from 'react';
import './styles.scss';

const FormButton = ({children, ...otherProps}) => {
  return (
    <button className="form-btn" {...otherProps}>
      {children}
    </button>
  );
};

export default FormButton;
