import React from "react";

import "./styles.scss";

const FileUpload = ({label, value, name, setFile, id}) => {
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const createImage = () => {
    let imageUrl = null;
    if (value instanceof File) {
      imageUrl = URL.createObjectURL(value);
    } else {
      imageUrl = value;
    }

    return imageUrl;
  };

  const removePicture = () => {
    setFile("");
  };

  return (
    <div className="formRow  fileUpload">
      <label htmlFor={name}>
        {label} {value && <img src={createImage()} alt={name} />}
        <input
          id={id}
          className="formInput"
          type="file"
          accept=".png, .jpg, .jpeg"
          onChange={handleFileChange}
        />
      </label>

      {value && (
        <button type="button" onClick={() => removePicture()}>
          Retirer l'image
        </button>
      )}
    </div>
  );
};

export default FileUpload;
