import React from "react";

import "./styles.scss";

const ColorCaption = () => {
  return (
    <div className="color-caption">
      <p>Commandes:</p>
      <div className="shop">
        <p>sur place</p>
        <div className="shop-color" />
      </div>

      <div className="internet">
        <p>internet</p> <div className="internet-color" />
      </div>

      <div className="later">
        <p>pour plus tard</p>
        <div className="later-color" />
      </div>
    </div>
  );
};

export default ColorCaption;
