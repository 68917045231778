import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";

import "./styles.scss";
import {useDispatch} from "react-redux";
import {clearCurrentOrder} from "../../../../../../redux/order/orderActions";

const OrderToManage = ({order}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearCurrentOrder());
  }, []);

  const renderPaidStatus = () => {
    if (order.orderPaid) {
      return <span className="order-paid">Commande payée</span>;
    } else {
      return <span className="order-not-paid">Commande non payée</span>;
    }
  };

  const renderOrder = () => {
    if (order.orderNumber.length < 4) {
      return order.orderNumber;
    } else {
      const firstSixDigits = order.orderNumber.substring(0, 6);
      const restOfString = order.orderNumber.substring(6);
      return (
        <>
          {/* <span className="small-number-details">firstSixDigits</span> */}
          <span className="big-number-details">{restOfString}</span>
        </>
      );
    }
  };

  return (
    <li
      className={`order-to-manage ${
        order.orderTakenOnTheSpot && "order-taken-on-spot"
      }`}
      onClick={() => navigate("/commandes/recap/" + order.documentId)}
    >
      <div className="manage-order-infos">
        <span className="manage-order-number">Commande {renderOrder()}</span>
        <span className="manage-order-total">
          Total: {order.orderTotal.toFixed(2)}€
        </span>
      </div>
      <div className="manage-order-payment">{renderPaidStatus()}</div>
    </li>
  );
};

export default OrderToManage;
