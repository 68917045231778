import {all, call} from 'redux-saga/effects';

import appSagas from './app/appSagas';
import orderSagas from './order/orderSagas';
import productSagas from './product/productSagas';
import userSagas from './user/userSagas';
import rewardSagas from './reward/rewardSagas';

export default function* rootSaga() {
  yield all([
    call(appSagas),
    call(orderSagas),
    call(productSagas),
    call(userSagas),
    call(rewardSagas),
  ]);
}
