import {firestore} from "../../services/firestore";
import {doc, updateDoc} from "firebase/firestore";

export const handleFetchRewards = () => {
  return new Promise((resolve, reject) => {
    let ref = firestore.collection("rewardsv2");

    ref
      .get()
      .then((snapshot) => {
        const data = [
          ...snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              documentId: doc.id,
            };
          }),
        ];
        resolve([...data]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleAddReward = (reward) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("rewardsv2")
      .doc()
      .set(reward)
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleEditReward = async (reward) => {
  const rewardRef = doc(firestore, "rewardsv2", reward.documentId);

  return new Promise((resolve, reject) => {
    updateDoc(rewardRef, reward)
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};
