import React, {useState} from "react";

import CreatedMenu from "./components/CreatedMenu";
import MenuChoice from "./components/MenuChoice";
import MenuProductsCategory from "./components/MenuProductsCategory";
import {
  Categories,
  MenuSnackQuantity,
  MenuTypes,
} from "../../../../../../utilities/enums";

import "./styles.scss";
import SandwichSauce from "./components/SandwichSauce";
import Textarea from "../../../../../../components/Textarea";
import {translateCategory} from "../../../../../../utilities/functions";

const MenuTab = ({products}) => {
  const [selectedProducts, setSelectedProducts] = useState({
    snack: null,
    meal: null,
    accompaniment: null,
    secondAccompaniment: null,
    sandwich: null,
    dessert: null,
    drink: null,
    seasoning: null,
  });

  const [menuPrice, setMenuPrice] = useState(0);
  const [sauceSelected, setSauceSelected] = useState(null);
  const [menuType, setMenuType] = useState(null);
  const [productNote, setProductNote] = useState("");

  const menu = {
    type: menuType,
    price: menuPrice,
    ...selectedProducts,
    sauce: sauceSelected,
    note: productNote,
  };

  const resetMenu = () => {
    setSelectedProducts({
      snack: null,
      meal: null,
      accompaniment: null,
      secondAccompaniment: null,
      sandwich: null,
      dessert: null,
      drink: null,
      seasoning: null,
    });
    setSauceSelected(null);
    setProductNote("");
  };

  const updateSelection = (category) => (product) => {
    setSelectedProducts((prev) => ({...prev, [category]: product}));
  };

  const handleNoSelection = (category) => {
    setSelectedProducts((prev) => ({
      ...prev,
      [category]: `Pas ${translateCategory(category)}`,
    }));
  };

  const menuConfig = {
    [MenuTypes.TiMenu]: [
      {title: `les snacks (x${MenuSnackQuantity.TiMenu})`, category: "snack"},
      {title: "un plat", category: "meal"},
      {title: "un accompagnement", category: "accompaniment"},
      {title: "un dessert", category: "dessert"},
      {title: "un assaisonnement / une sauce", category: "seasoning"},
    ],
    [MenuTypes.MenuFilao]: [
      {
        title: `les snacks (x${MenuSnackQuantity.MenuFilao})`,
        category: "snack",
      },
      {title: "un plat", category: "meal"},
      {title: "un accompagnement", category: "accompaniment"},
      {title: "un dessert", category: "dessert"},
      {title: "une boisson", category: "drink"},
      {title: "un assaisonnement / une sauce", category: "seasoning"},
    ],
    [MenuTypes.MenuKonple]: [
      {
        title: `les snacks (x${MenuSnackQuantity.MenuKonple})`,
        category: "snack",
      },
      {title: "un plat", category: "meal"},
      {title: "un accompagnement", category: "accompaniment"},
      {title: "un second accompagnement", category: "secondAccompaniment"},
      {title: "un dessert", category: "dessert"},
      {title: "une boisson", category: "drink"},
      {title: "un assaisonnement / une sauce", category: "seasoning"},
    ],
    [MenuTypes.SeaMenu]: [
      {
        title: `les snacks (x${MenuSnackQuantity.SeaMenu})`,
        category: "snack",
      },
      {title: "un plat", category: "meal"},
      {title: "un accompagnement", category: "accompaniment"},
      {title: "un second accompagnement", category: "secondAccompaniment"},
      {title: "un dessert", category: "dessert"},
      {title: "une boisson", category: "drink"},
      {title: "un assaisonnement / une sauce", category: "seasoning"},
    ],
    [MenuTypes.MenuSandwich]: [
      {title: "un sandwich", category: "sandwich"},
      {title: "une boisson", category: "drink"},
    ],
  };

  return (
    <div className="menu-tab-container">
      <h1>Menu</h1>

      <MenuChoice
        setMenuType={setMenuType}
        setMenuPrice={setMenuPrice}
        resetMenu={resetMenu}
      />

      {menuType && menuConfig[menuType] && (
        <>
          {menuConfig[menuType].map(({title, category}, index) => (
            <div key={index}>
              <MenuProductsCategory
                menuType={menuType}
                title={title}
                products={products}
                productCategory={
                  category === "secondAccompaniment"
                    ? Categories.accompaniment
                    : Categories[category]
                }
                updateSelection={updateSelection(category)}
                selectedProduct={selectedProducts[category]}
                handleNoSelection={() => handleNoSelection(category)}
              />
              <hr />
            </div>
          ))}
          {menuType === MenuTypes.MenuSandwich && (
            <SandwichSauce
              setSauceSelected={setSauceSelected}
              sauceSelected={sauceSelected}
            />
          )}
          <div className="add-note">
            <Textarea
              label="Ajouter une note"
              name="productNote"
              id="productNote"
              value={productNote}
              updateText={setProductNote}
            />
          </div>
        </>
      )}

      <CreatedMenu
        menu={menu}
        menuType={menuType}
        clearMenu={resetMenu}
        setMenuType={setMenuType}
      />
    </div>
  );
};

export default MenuTab;
