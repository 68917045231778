import {handleReducerProductsUpdate} from './productHelpers';
import productTypes from './productTypes';

const INITIAL_STATE = {
  products: [],
  productErrors: [],
};

const productReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case productTypes.SET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };
    case productTypes.CLEAR_PRODUCTS:
      return {
        ...state,
        products: [],
      };

    case productTypes.PRODUCT_ERROR:
      return {
        ...state,
        productErrors: [...state.productErrors, action.payload],
      };
    case productTypes.RESET_PRODUCT_ERROR:
      return {
        ...state,
        productErrors: [],
      };

    case productTypes.UPDATE_PRODUCTS:
      return {
        ...state,
        products: handleReducerProductsUpdate(state.products, action.payload),
      };
    default:
      return state;
  }
};

export default productReducer;
