import React from "react";

import "./styles.scss";
import {
  renderProductPrice,
  renderProductTitle,
} from "../../../../../../utilities/functions";

const TicketItem = ({product}) => {
  const renderUnitPrice = () => {
    const {selectedField} = product;
    const {salePrice, productPrice} = selectedField;

    const formattedProductPrice = parseFloat(productPrice).toFixed(2) + "€/u";
    const formattedSalePrice = salePrice
      ? parseFloat(salePrice).toFixed(2) + "€/u"
      : null;

    if (salePrice) {
      return (
        <>
          <span className="old-price">{formattedProductPrice}</span>{" "}
          <span className="sale-price">{formattedSalePrice}</span>
        </>
      );
    }

    return formattedProductPrice;
  };
  return (
    <li className="ticket-item">
      <div className="ticket-item-name-and-unit-price">
        {renderProductTitle(product)}
        <div className="ticket-item-unit-price">{renderUnitPrice()}</div>
      </div>
      <div className="ticket-item-quantity-and-price">
        <div className="ticket-item-quantity">
          Quantité: x{product.cartQuantity}
        </div>
        <div className="order-item-prices">{renderProductPrice(product)}</div>
      </div>
    </li>
  );
};

export default TicketItem;
