import React, {useState} from "react";
import {useDispatch} from "react-redux";

import Receipt from "../../assets/receipt.png";
import Modal from "../Modal";

import {apiInstance} from "../../utilities";
import {setFeedbackMessage} from "../../redux/app/appActions";
import {formatDate} from "../../utilities/functions";

import "./styles.scss";
import {PulseLoader} from "react-spinners";

const MailOrderButton = ({currentOrder}) => {
  const dispatch = useDispatch();
  const [hideModal, setHideModal] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const toggleModal = () => {
    setHideModal(!hideModal);
  };

  const sendTicketToCustomer = () => {
    if (!currentOrder.customer.email) return;

    setIsLoading(true);

    const orderDate = formatDate(currentOrder.orderCreatedDate);

    return new Promise((resolve, reject) => {
      apiInstance
        .post("/send-order-ticket", {currentOrder, orderDate})
        .then(async (r) => {
          if (r.status === 200) {
            setIsLoading(false);
            dispatch(
              setFeedbackMessage({
                type: "success",
                text: "Ticket envoyé au client",
              })
            );
            toggleModal();
            resolve();
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  if (
    !currentOrder.customer ||
    !currentOrder.orderStatus ||
    !currentOrder.orderPaid
  )
    return;

  return (
    <>
      <div className="send-ticket-to-customer-container">
        <button onClick={() => toggleModal()}>
          <img src={Receipt} alt="ticket" />
        </button>
      </div>

      <Modal hideModal={hideModal} toggleModal={toggleModal}>
        <div className="send-ticket-confirm-message">
          {isLoading ? (
            <PulseLoader />
          ) : (
            <>
              <p>Envoyer le ticket au client ?</p>
              <div className="buttons">
                <button
                  className="confirm-button"
                  onClick={() => sendTicketToCustomer()}
                >
                  Oui
                </button>{" "}
                <button
                  className="decline-button"
                  onClick={() => toggleModal()}
                >
                  Non
                </button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default MailOrderButton;
