import React, {useState, useEffect} from "react";

import "./styles.scss";

const Clock = () => {
  const [dateState, setDateState] = useState(new Date());

  useEffect(() => {
    setInterval(() => setDateState(new Date()), 30000);
  }, []);

  return (
    <p className="clock">
      {dateState.toLocaleString("fr-FR", {
        hour: "numeric",
        minute: "numeric",
        hour12: false,
      })}
    </p>
  );
};

export default Clock;
