const userTypes = {
  SIGN_UP_START: 'SIGN_UP_START',
  EMAIL_SIGN_IN_START: 'EMAIL_SIGN_IN_START',
  GOOGLE_SIGN_IN_START: 'GOOGLE_SIGN_IN_START',
  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
  SIGN_OUT_START: 'SIGN_OUT_START',
  SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',

  FETCH_CUSTOMER_LIST_START: 'FETCH_CUSTOMER_LIST_START',
  SET_CUSTOMER_LIST: 'SET_CUSTOMER_LIST',
  CLEAR_CUSTOMER_LIST: 'CLEAR_CUSTOMER_LIST',

  SET_CUSTOMER_LOYALTY: 'SET_CUSTOMER_LOYALTY',
  RESET_CUSTOMER_LOYALTY: 'RESET_CUSTOMER_LOYALTY',
};

export default userTypes;
