import React from 'react';

import MoonLoader from 'react-spinners/MoonLoader';

import './styles.scss';

const Loader = () => {
  return <MoonLoader className="loader" />;
};

export default Loader;
