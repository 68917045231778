import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import KitchenOrders from "./components/KitchenOrders";
import KitchenColumn from "./components/KitchenColumn";

import {getPendingOrdersStart} from "../../redux/order/orderActions";

import "./styles.scss";

const Kitchen = () => {
  const dispatch = useDispatch();
  const {pendingOrders} = useSelector((mapState) => mapState.orderData);

  useEffect(() => {
    dispatch(getPendingOrdersStart());
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getPendingOrdersStart());
    }, 20000);
    return () => clearInterval(interval);
  }, [pendingOrders.length]);

  return (
    <div className="kitchen">
      <KitchenOrders orders={pendingOrders} />
      <KitchenColumn orders={pendingOrders} />
    </div>
  );
};

export default Kitchen;
