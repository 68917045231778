import React from "react";
import "./styles.scss";

const FormInput = ({
  handleChange,
  label,
  name,
  hasError,
  halfWidth,
  displayValue,
  suffix,
  ...otherProps
}) => {
  return (
    <div
      className={`formRow ${halfWidth && "halfWidth"} ${
        hasError && "form-error"
      }`}
    >
      <label htmlFor={name}>
        {label}{" "}
        <input
          className="formInput"
          autoComplete="off"
          onChange={handleChange}
          {...otherProps}
        />
        {displayValue && (
          <span className="display-value">{otherProps.value}</span>
        )}
        {(otherProps.id && otherProps.id === "productPrice") ||
          (otherProps.id === "salePrice" && (
            <span className="currency-symbol"> €</span>
          ))}
        {suffix && <span className="suffix">{suffix}</span>}
      </label>
    </div>
  );
};

export default FormInput;
