import React from "react";

import TicketMenu from "./components/TicketMenu";
import TicketItem from "./components/TicketItem";

import "./styles.scss";
import {
  extractOrderNumber,
  formatDateOnly,
  formatHour,
  isToday,
} from "../../../../../../utilities/functions";
import Dots from "../../../../../../components/Dots";

const OrderTicket = ({order}) => {
  const renderOrderedMenus = () => {
    if (order.menus.length < 1) return;

    return order.menus.map((menu, index) => {
      return <TicketMenu key={index} menu={menu} />;
    });
  };

  const renderOrderedItems = () => {
    if (order.orderItems.length < 1) return;

    return order.orderItems.map((product, index) => {
      return <TicketItem key={index} product={product} />;
    });
  };

  const renderCustomer = () => {
    if (order.customer) {
      return (
        <p className="ticket-order-customer">
          Pour {order.customer.firstName} {order.customer.lastName}
        </p>
      );
    }
  };

  const renderReward = () => {
    if (!order.reward) return;
    return (
      <div className="reward-item">
        <p>{order.reward.element}</p>
      </div>
    );
  };

  return (
    <div
      className={`order-ticket ${
        order.scheduled && !isToday(order.scheduled) && "order-for-later"
      } ${order.orderTakenOnTheSpot && "order-taken-on-spot"} `}
    >
      <div className="title-and-customer">
        <h6>Commande {extractOrderNumber(order.orderNumber)}</h6>
        {renderCustomer()}
        {order.scheduled ? (
          !isToday(order.scheduled) ? (
            <p className="time">pour le {formatDateOnly(order.scheduled)}</p>
          ) : (
            <p className="time">Pour {formatHour(order.scheduled)}</p>
          )
        ) : (
          <p className="time">à {formatHour(order.orderCreatedDate)}</p>
        )}
      </div>

      <ul className="order-item-list">
        {renderOrderedMenus()}
        {renderOrderedItems()}
        {renderReward()}
      </ul>
      {/* <hr />
      <p className="order-total">Total: {order.orderTotal} €</p> */}
    </div>
  );
};

export default OrderTicket;
