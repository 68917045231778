import {combineReducers} from 'redux';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import orderReducer from './order/orderReducer';
import productReducer from './product/productReducer';
import appReducer from './app/appReducer';
import userReducer from './user/userReducer';
import rewardReducer from './reward/rewardReducer';

export const rootReducer = combineReducers({
  orderData: orderReducer,
  productData: productReducer,
  appData: appReducer,
  userData: userReducer,
  rewardData: rewardReducer,
});

const configStorage = {
  key: 'root',
  storage,
  whitelist: ['userData'],
};

export default persistReducer(configStorage, rootReducer);
