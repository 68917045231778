import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import DatePicker from "react-date-picker";

import AdminOrderList from "./components/AdminOrderList";
import Modal from "../../../../components/Modal";
import OrdersData from "./components/OrdersData";

import {
  fetchOrdersByDate,
  resetDaysOrders,
} from "../../../../redux/order/orderActions";

import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

import "./styles.scss";

const AdminOrders = () => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);

  const [isInterval, setIsInterval] = useState(false);

  const [searched, hasSearched] = useState(false);
  const {daysOrders} = useSelector((mapState) => mapState.orderData);

  const [hideModal, setHideModal] = useState(true);

  const toggleModal = () => {
    setHideModal(!hideModal);
  };

  const configModal = {hideModal, toggleModal};

  useEffect(() => {
    dispatch(resetDaysOrders());
  }, []);

  const renderDate = () => {
    if (startDate) {
      return startDate.toLocaleString("fr-FR", {
        day: "numeric",
        month: "short",
        year: "numeric",
      });
    }
  };

  const handleClick = () => {
    if (!startDate) return;

    hasSearched(true);

    startDate.setHours(0, 0, 0, 0);

    dispatch(fetchOrdersByDate(startDate, endDate));
  };

  const handleEndDate = (date) => {
    if (Date.parse(date) < startDate) {
      setEndDate(null);
    } else {
      setEndDate(date);
    }
  };

  const handleIntervalClick = () => {
    setEndDate(null);
    setIsInterval(!isInterval);
  };

  return (
    <div className="admin-orders">
      <h2>Commandes</h2>

      {isInterval && <span className="date-text">Du</span>}
      <DatePicker onChange={(date) => setStartDate(date)} value={startDate} />

      {isInterval && (
        <span className="end-date">
          <span className="date-text">au</span>
          <DatePicker
            onChange={(date) => handleEndDate(date)}
            value={endDate}
          />
        </span>
      )}

      <button className="interval-button" onClick={() => handleIntervalClick()}>
        {isInterval ? "Date fixe" : "Interval de date"}
      </button>

      <button className="get-orders-button" onClick={() => handleClick()}>
        Récupérer les commandes du {renderDate()}
      </button>

      {daysOrders.length > 0 ? (
        <>
          <button onClick={() => toggleModal()}>Voir détails</button>
          <AdminOrderList orders={daysOrders} />
        </>
      ) : (
        searched && (
          <p className="no-order-day">
            Aucune commande à la date selectionnée.
          </p>
        )
      )}

      <Modal {...configModal} size="big">
        <OrdersData orders={daysOrders} />
      </Modal>
    </div>
  );
};

export default AdminOrders;
