import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import FormSelect from "../../../../../../components/FormSelect";
import FormInput from "../../../../../../components/FormInput";
import FormButton from "../../../../../../components/FormButton";
import FileUpload from "../../../../../../components/FileUpload";
import MultipleInputArray from "../../../../../../components/MultipleInputArray";

import {addRewardStart} from "../../../../../../redux/reward/rewardActions";
import {uploadImage} from "../../../../../../services/firestore";
import {MenuTypes} from "../../../../../../utilities/enums";

import "./styles.scss";

const AddLoyaltyReward = ({hideModal, rewards}) => {
  const dispatch = useDispatch();
  const [rewardCategory, setRewardCategory] = useState("");
  const [rewardName, setRewardName] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [rewardThumbnail, setRewardThumbnail] = useState("");
  const [hasOptions, setHasOptions] = useState(false);
  const [rewardOptions, setRewardOptions] = useState([]);
  const [rewardAvailability, setRewardAvailability] = useState(true);
  const [rewardLevelAvailable, setRewardLevelAvailable] = useState(1);
  const [rewardPrice, setRewardPrice] = useState(20);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(false);
  }, [rewards.length]);

  const removeOptions = (optionToRemove) => {
    setRewardOptions((prevOptions) =>
      prevOptions.filter((option) => option !== optionToRemove)
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const reward = {
      category: rewardCategory,
      name: rewardName,
      quantity: parseInt(quantity),
      hasOptions,
      options: rewardOptions,
      availableAtAllLevel: rewardAvailability,
      levelAvailable: rewardLevelAvailable,
      cost: parseInt(rewardPrice),
      enabled: true,
    };

    try {
      const downloadUrl = await uploadImage("rewardImages", rewardThumbnail);
      reward.thumbnail = downloadUrl;
      dispatch(addRewardStart(reward));
      hideModal(true);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const categoryOptions = [
    {value: "", disabled: true, name: "Selectionner une catégorie"},
    {value: "loyaltyLevel", name: "Récompense de fidélité"},
    {value: "menu", name: "Menu"},
    {value: "product", name: "Produit"},
  ];

  const menuOptions = [
    {value: "", disabled: true, name: "Selectionner un menu"},
    {value: MenuTypes.MenuFilao, name: MenuTypes.MenuFilao},
    {value: MenuTypes.MenuSandwich, name: MenuTypes.MenuSandwich},
    {value: MenuTypes.MenuKonple, name: MenuTypes.MenuKonple},
    {value: MenuTypes.TiMenu, name: MenuTypes.TiMenu},
  ];

  return (
    <div className="add-loyalty-reward-form">
      <form onSubmit={handleSubmit}>
        <h2>Ajout d'une récompense</h2>
        <div className="block1">
          <div className="left">
            <FileUpload
              setFile={setRewardThumbnail}
              label="Image de récompense"
              name="rewardThumbnail"
              id="rewardThumbnail"
              value={rewardThumbnail}
            />
          </div>
          <div className="right">
            <FormSelect
              label="Categorie"
              options={categoryOptions}
              value={rewardCategory}
              handleChange={(e) => setRewardCategory(e.target.value)}
            />
            {rewardCategory !== "menu" && (
              <FormInput
                label="Nom de la récompense"
                id="rewardName"
                name="rewardName"
                type="text"
                value={rewardName}
                placeholder="Nom de la récompense"
                handleChange={(e) => setRewardName(e.target.value)}
              />
            )}
            {rewardCategory === "menu" && (
              <FormSelect
                label="Choix du menu"
                options={menuOptions}
                value={rewardName}
                handleChange={(e) => setRewardName(e.target.value)}
              />
            )}
            <div className="checkboxes">
              <div className="available-checkbox">
                <p>Disponible à tous les niveaux ?</p>
                <div className="checkbox">
                  <button
                    type="button"
                    className={rewardAvailability ? "active" : ""}
                    onClick={() => setRewardAvailability(true)}
                  >
                    Oui
                  </button>
                  <button
                    type="button"
                    className={!rewardAvailability ? "active" : ""}
                    onClick={() => setRewardAvailability(false)}
                  >
                    Non
                  </button>
                </div>
              </div>
              {!rewardAvailability && (
                <div className="availability-level-checkbox">
                  <p>Disponible au niveau</p>
                  <div className="checkbox">
                    {[1, 2].map((level) => (
                      <button
                        key={level}
                        type="button"
                        className={
                          rewardLevelAvailable === level ? "active" : ""
                        }
                        onClick={() => setRewardLevelAvailable(level)}
                      >
                        {level}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <FormInput
              label="Points requis"
              type="number"
              min={20}
              max={200}
              step={5}
              id="rewardPrice"
              name="rewardPrice"
              value={rewardPrice}
              handleChange={(e) => setRewardPrice(e.target.value)}
            />
          </div>
        </div>
        {rewardCategory === "product" && (
          <>
            <div className="block2">
              <div className="left">
                <FormInput
                  label="Quantité"
                  type="number"
                  min={1}
                  max={12}
                  step={1}
                  id="quantity"
                  name="quantity"
                  value={quantity}
                  handleChange={(e) => setQuantity(e.target.value)}
                />
              </div>
              <div className="right">
                <div className="checkboxes">
                  <div className="options-checkbox">
                    <p>Options ?</p>
                    <div className="checkbox">
                      <button
                        type="button"
                        className={hasOptions ? "active" : ""}
                        onClick={() => setHasOptions(true)}
                      >
                        Oui
                      </button>
                      <button
                        type="button"
                        className={!hasOptions ? "active" : ""}
                        onClick={() => setHasOptions(false)}
                      >
                        Non
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {hasOptions && (
              <MultipleInputArray
                label="Options"
                name="rewardOptions"
                title="Liste des options"
                placeholder="Ajouter une option"
                array={rewardOptions}
                addToArray={setRewardOptions}
                removeFromArray={removeOptions}
              />
            )}
          </>
        )}
        <FormButton type="submit" disabled={isLoading}>
          {isLoading ? "Envoi en cours..." : "Ajouter la récompense"}
        </FormButton>
      </form>
    </div>
  );
};

export default AddLoyaltyReward;
