import React, {useEffect, useState} from "react";

import CreditCard from "./assets/credit-card.png";
import ApplePay from "./assets/apple-pay.png";
import GooglePay from "./assets/google-pay.png";
import Cash from "./assets/cash.png";

import "./styles.scss";
import PaymentIcon from "./components/PaymentIcon";
import {useDispatch} from "react-redux";
import {setPaymentMethod} from "../../../../redux/order/orderActions";
import {PaymentMethodEnum} from "../../../../utilities/enums";

const PaymentMethod = ({order}) => {
  const dispatch = useDispatch();
  const [selectedIcon, setSelectedIcon] = useState("");

  useEffect(() => {
    if (selectedIcon !== "") {
      dispatch(setPaymentMethod(selectedIcon));
    }
  }, [selectedIcon]);

  if (order.orderPaid)
    return (
      <div className="payment-already-processed">Commande déjà payée.</div>
    );

  return (
    <div className="payment-method">
      <h2>Selectionner le mode de paiement</h2>
      <div className="payment-method-list">
        <PaymentIcon
          icon={CreditCard}
          title={PaymentMethodEnum.CreditCard}
          selectedIcon={selectedIcon}
          setSelectedIcon={setSelectedIcon}
        />
        <PaymentIcon
          icon={Cash}
          title={PaymentMethodEnum.Cash}
          selectedIcon={selectedIcon}
          setSelectedIcon={setSelectedIcon}
        />
        <PaymentIcon
          icon={ApplePay}
          title={PaymentMethodEnum.ApplePay}
          selectedIcon={selectedIcon}
          setSelectedIcon={setSelectedIcon}
        />
        <PaymentIcon
          icon={GooglePay}
          title={PaymentMethodEnum.GPay}
          selectedIcon={selectedIcon}
          setSelectedIcon={setSelectedIcon}
        />
      </div>
    </div>
  );
};

export default PaymentMethod;
