import React from "react";
import {
  getAllProductsOfOrder,
  translateCategoryName,
} from "../../../../../../utilities/functions";
import "./styles.scss";
import {Categories} from "../../../../../../utilities/enums";

const OrdersData = ({orders}) => {
  const renderProductsOrdered = () => {
    const productsOrdered = getAllProductsOfOrder(orders);

    // Group products by category
    const productsByCategory = {};
    Object.entries(productsOrdered).forEach(([productName, productData]) => {
      const {category, quantity} = productData;
      if (!productsByCategory[category]) {
        productsByCategory[category] = [];
      }
      productsByCategory[category].push({name: productName, quantity});
    });

    return Object.entries(productsByCategory).map(([category, products]) => (
      <div key={category} className="category-group">
        <h6>{translateCategoryName(category)}</h6>
        {products.map((product) => (
          <p key={product.name} className="product-quantity-ordered">
            {product.name}: <span className="quantity">{product.quantity}</span>
          </p>
        ))}
      </div>
    ));
  };

  const untransformedProductsTotal = () => {
    let total = 0;

    orders.forEach((order) => {
      order.orderItems.forEach((item) => {
        if (item.isUntransformed) {
          total += item.selectedField.productPrice;
        }
      });
    });
    console.log(total);
    return parseFloat(total).toFixed(2);
  };

  return (
    <>
      <p className="number-of-orders">Total des commandes: {orders.length}</p>
      <h4>Quantité de produits commandés</h4>
      <div className="orders-data">{renderProductsOrdered()}</div>
      <div className="untransformed-container">
        Valeur produits non transformés:{" "}
        <span>{untransformedProductsTotal()}€</span>
      </div>
    </>
  );
};

export default OrdersData;
