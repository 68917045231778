import React, {useState} from "react";

import "./styles.scss";
import Modal from "../../../../../../components/Modal";
import {PaymentMethodEnum} from "../../../../../../utilities/enums";
import ChangeDue from "./ChangeDue";

const PaymentIcon = ({icon, title, selectedIcon, setSelectedIcon}) => {
  const [hideModal, setHideModal] = useState(true);

  const toggleModal = () => {
    setHideModal(!hideModal);
  };
  const configModal = {hideModal, toggleModal};

  const handleClick = () => {
    if (title === PaymentMethodEnum.Cash) {
      toggleModal();
    }
    setSelectedIcon(title);
  };

  return (
    <>
      <div
        className={`payment-icon ${selectedIcon === title && "selected"}`}
        onClick={() => handleClick()}
      >
        <img src={icon} alt={title} />
        <h6>{title}</h6>
      </div>
      <Modal {...configModal}>
        <ChangeDue toggleModal={toggleModal} />
      </Modal>
    </>
  );
};

export default PaymentIcon;
