import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {
  selectMenuTotal,
  selectOrderTotal,
} from '../../../../redux/order/orderSelectors';
import {setFeedbackMessage} from '../../../../redux/app/appActions';
import {calculateTotal} from '../../../../utilities/functions';

import './styles.scss';
import {closeOrderStart} from '../../../../redux/order/orderActions';
import {useNavigate} from 'react-router-dom';

const mapState = createStructuredSelector({
  cartTotal: selectOrderTotal,
  menuTotal: selectMenuTotal,
});

const OrderTakenConfirmMessage = ({closeModal, currentOrder}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {cartTotal, menuTotal} = useSelector(mapState);

  const handleConfirmButton = () => {
    let newOrder = {...currentOrder};

    newOrder.orderTotal = newOrder.orderTotal
      ? newOrder.orderTotal
      : calculateTotal(cartTotal, menuTotal);
    newOrder.orderPaid = true;

    if (!newOrder.paymentMethod) {
      dispatch(
        setFeedbackMessage({
          type: 'danger',
          text: 'Veuillez selectionner un mode de paiement.',
        })
      );
      closeModal();
      return;
    }

    if (
      (newOrder.orderItems.length < 1 && newOrder.menus.length < 1) ||
      !newOrder.orderTotal ||
      !newOrder.orderPaid
    ) {
      dispatch(
        setFeedbackMessage({
          type: 'danger',
          text: 'Problème lors de la commande',
        })
      );
      closeModal();
      return;
    }

    dispatch(closeOrderStart(newOrder));
    closeModal();
    return navigate('/');
  };

  return (
    <div className="order-paid-confirm-message">
      <p>Le paiement a été effectué et le client a récupéré sa commande ?</p>
      <div className="buttons">
        <button
          className="confirm-button"
          onClick={() => handleConfirmButton()}
        >
          Oui
        </button>{' '}
        <button className="decline-button" onClick={() => closeModal()}>
          Non
        </button>
      </div>
    </div>
  );
};

export default OrderTakenConfirmMessage;
