import {createSelector} from "reselect";

export const selectOrderData = (state) => state.orderData;

export const selectOrderItems = createSelector(
  [selectOrderData],
  (orderData) => orderData.currentOrder.orderItems
);

export const selectOrderTotal = createSelector(
  [selectOrderItems],
  (orderItems) => {
    if (!orderItems) return 0;

    return orderItems.reduce((cartQuantity, orderItem) => {
      if (orderItem.selectedField) {
        const {salePrice, productPrice} = orderItem.selectedField;
        const parsedSalePrice = parseFloat(salePrice);
        const parsedProductPrice = parseFloat(productPrice);
        const itemPrice =
          !isNaN(parsedSalePrice) && parsedSalePrice > 0
            ? parsedSalePrice
            : parsedProductPrice;

        return cartQuantity + orderItem.cartQuantity * itemPrice;
      }

      return cartQuantity;
    }, 0);
  }
);

export const selectOrderTotalWithoutSale = createSelector(
  [selectOrderItems],
  (orderItems) =>
    orderItems &&
    orderItems.reduce((total, orderItem) => {
      const productPrice = orderItem.selectedField?.productPrice;
      const parsedProductPrice =
        productPrice != null ? parseFloat(productPrice) : 0;
      return total + orderItem.cartQuantity * parsedProductPrice;
    }, 0)
);

export const selectMenuItems = createSelector(
  [selectOrderData],
  (orderData) => orderData.currentOrder.menus
);

export const selectMenuTotal = createSelector(
  [selectMenuItems],
  (menus) => menus && menus.reduce((price, menu) => price + menu.price, 0)
);
