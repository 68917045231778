import React from "react";
import {useDispatch} from "react-redux";
import {removeMenuFromOrder} from "../../../../redux/order/orderActions";
import {renderProductNameAndQty} from "../../../../utilities/functions";

import "./styles.scss";

const OrderMenu = ({menu}) => {
  const dispatch = useDispatch();

  const handleRemoveMenu = () => {
    dispatch(removeMenuFromOrder(menu.id));
  };
  console.log(menu);
  return (
    <li className="order-menu">
      <div className="order-menu-type-and-price">
        <span className="order-menu-type">{menu.type}</span>
        <span className="order-menu-price">{menu.price.toFixed(2)}€</span>
      </div>
      <div className="order-menu-items">
        {menu.snack && (
          <div className="order-menu-snack">
            {renderProductNameAndQty(menu.snack, menu.type)}
          </div>
        )}
        {menu.meal && (
          <div className="order-menu-meal">
            {renderProductNameAndQty(menu.meal, menu.type)}
          </div>
        )}
        {menu.accompaniment && (
          <div className="order-menu-accompaniment">
            {renderProductNameAndQty(menu.accompaniment, menu.type)}
          </div>
        )}
        {menu.secondAccompaniment && (
          <div className="order-menu-accompaniment">
            {renderProductNameAndQty(menu.secondAccompaniment, menu.type)}
          </div>
        )}
        {menu.sandwich && (
          <div className="order-menu-sandwich">
            {renderProductNameAndQty(menu.sandwich, menu.type)}
          </div>
        )}
        {menu.sauce && (
          <div className="order-menu-sauce">Sauce: {menu.sauce}</div>
        )}
        {menu.drink && (
          <div className="order-menu-drink">
            {renderProductNameAndQty(menu.drink, menu.type)}
          </div>
        )}
        {menu.dessert && (
          <div className="order-menu-dessert">
            {renderProductNameAndQty(menu.dessert, menu.type)}
          </div>
        )}
        {menu.seasoning && (
          <div className="order-menu-seasoning">
            {renderProductNameAndQty(menu.seasoning, menu.type)}
          </div>
        )}
        {menu.note && <div className="order-menu-note">Note: {menu.note}</div>}
      </div>
      <div className="order-menu-delete-button">
        <button className="order-menu-delete-button" onClick={handleRemoveMenu}>
          Supprimer
        </button>
      </div>
    </li>
  );
};

export default OrderMenu;
