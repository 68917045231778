import React from "react";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

import "./styles.scss";
import {useNavigate} from "react-router-dom";

const ConfirmButton = ({isDisabled, orderId, classname}) => {
  const navigate = useNavigate();
  return (
    <button
      className={`confirm-button ${classname}`}
      disabled={isDisabled}
      onClick={() => navigate("/commandes/recap/" + orderId)}
    >
      {isDisabled ? (
        "Panier vide"
      ) : (
        <>
          Valider{" "}
          {classname && (
            <KeyboardDoubleArrowRightIcon className="double-arrow-icon" />
          )}
        </>
      )}
    </button>
  );
};

export default ConfirmButton;
