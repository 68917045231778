import React from "react";

const Dots = () => {
  return (
    <div className="dots">
      ........................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................
    </div>
  );
};

export default Dots;
