import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import OrderToManage from './components/OrderToManage';

import {getPendingOrdersStart} from '../../../../redux/order/orderActions';

import './styles.scss';

const ManagePendingOrders = () => {
  const dispatch = useDispatch();
  const {pendingOrders} = useSelector((mapState) => mapState.orderData);

  useEffect(() => {
    dispatch(getPendingOrdersStart());
  }, []);

  return (
    <div className="manage-pending-orders-container">
      {pendingOrders.length > 0 ? (
        <ul className="manage-pending-order-list">
          {pendingOrders.toReversed().map((order, index) => {
            return <OrderToManage key={index} order={order} />;
          })}
        </ul>
      ) : (
        <p className="no-pending-orders">Aucune commande en attente.</p>
      )}
    </div>
  );
};

export default ManagePendingOrders;
