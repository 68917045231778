import React from 'react';
import FormSelect from '../../../../../../../../components/FormSelect';

import './styles.scss';

const SandwichSauce = ({sauceSelected, setSauceSelected}) => {
  const categoryOptions = [
    {
      value: '',
      disabled: true,
      name: 'Selectionner une sauce',
    },
    {value: 'Mayonnaise', name: 'Mayonnaise'},
    {value: 'Ketchup', name: 'Ketchup'},
  ];

  return (
    <div className="sandwich-sauce-category">
      <div className="sauce-list">
        <FormSelect
          className="sauce-select"
          label="Choisir Sauce"
          options={categoryOptions}
          value={sauceSelected}
          handleChange={(e) => setSauceSelected(e.target.value)}
        />
      </div>
    </div>
  );
};

export default SandwichSauce;
