import React from "react";

import "./styles.scss";
import {useDispatch} from "react-redux";
import {
  addProductToCurrentOrder,
  reduceOrderItem,
  removeProductFromCurrentOrder,
} from "../../../../redux/order/orderActions";
import {Categories} from "../../../../utilities/enums";
import {
  renderProductPrice,
  renderProductTitle,
} from "../../../../utilities/functions";

const OrderItem = ({product}) => {
  const dispatch = useDispatch();

  const handleAddProduct = () => {
    dispatch(addProductToCurrentOrder(product, 1));
  };

  const handleReduceProduct = () => {
    dispatch(reduceOrderItem(product));
  };

  const handleRemoveCartItem = () => {
    dispatch(removeProductFromCurrentOrder(product));
  };

  return (
    <li className="order-item">
      <div className="order-item-name-and-quantity">
        {renderProductTitle(product)}
        <div className="order-item-cart-quantity">
          <span className="arrow arrow-left" onClick={handleReduceProduct}>
            &#10094;
          </span>
          <span>x{product.cartQuantity}</span>
          <span className="arrow arrow-right" onClick={handleAddProduct}>
            &#10095;
          </span>
          <p className="delete-item" onClick={handleRemoveCartItem}>
            Supprimer
          </p>
        </div>
      </div>
      <div className="order-item-prices">{renderProductPrice(product)}</div>
    </li>
  );
};

export default OrderItem;
