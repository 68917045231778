import {takeLatest, put, all, call} from "redux-saga/effects";

import productTypes from "./productTypes";
import {
  clearProducts,
  resetProductError,
  setProducts,
  updateProductList,
} from "./productActions";
import {
  handleAddProduct,
  handleDeleteProduct,
  handleEditProduct,
  handleFetchProducts,
} from "./productHelpers";
import {auth} from "../../services/firestore";
import {setFeedbackMessage} from "../app/appActions";

/** ANCHOR fetch products */
export function* fetchProducts() {
  try {
    const products = yield handleFetchProducts();
    yield put(clearProducts());
    yield put(setProducts(products));
  } catch (err) {
    console.log(err);
  }
}

export function* onFetchProductsStart() {
  yield takeLatest(productTypes.FETCH_PRODUCTS_START, fetchProducts);
}

/** ANCHOR add product */
export function* addProduct({payload}) {
  try {
    const timestamp = new Date();

    yield handleAddProduct({
      ...payload,
      createdBy: auth.currentUser.uid,
      createdDate: timestamp,
    });

    yield put(resetProductError());
    yield put(clearProducts());
  } catch (err) {
    console.log(err);
  }
}

export function* onAddProductStart() {
  yield takeLatest(productTypes.ADD_NEW_PRODUCT_START, addProduct);
}

/** ANCHOR Edit one product */
export function* editProduct({payload}) {
  try {
    const editedProduct = yield handleEditProduct(payload);

    yield put(resetProductError());
    yield put(updateProductList(editedProduct));
  } catch (err) {
    console.log(err);
  }
}

export function* onEditProductStart() {
  yield takeLatest(productTypes.EDIT_PRODUCT_START, editProduct);
}

/** ANCHOR delete one product */
export function* deleteProduct({payload}) {
  try {
    yield handleDeleteProduct(payload);
    yield put(clearProducts());
    yield put(
      setFeedbackMessage({
        type: "success",
        text: "Produit supprimé",
      })
    );
  } catch (err) {
    console.log(err);
  }
}

export function* onDeleteProductStart() {
  yield takeLatest(productTypes.DELETE_PRODUCT_START, deleteProduct);
}

/** ANCHOR product SAGAS */
export default function* productSagas() {
  yield all([
    call(onFetchProductsStart),
    call(onAddProductStart),
    call(onEditProductStart),
    call(onDeleteProductStart),
  ]);
}
