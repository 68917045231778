import {Categories} from "../../../../../../utilities/enums";

export const formatQuantity = (product) => {
  if (!product) return;

  let text = "";
  if (product.weight !== 0) {
    text = " (" + product.weight + "g)";
  } else {
    if (product.quantity) {
      text = " (x" + product.quantity + ")";
    }
  }

  return text;
};

export const formatCategory = (product) => {
  if (!product) return;

  let category = "";

  switch (product.category) {
    case Categories.snack: {
      category = "Snack";
      break;
    }
    case Categories.appetizer: {
      category = "Entrée";
      break;
    }
    case Categories.meal: {
      category = "Plat";
      break;
    }
    case Categories.accompaniment: {
      category = "Accompagnement";
      break;
    }
    case Categories.dessert: {
      category = "Dessert";
      break;
    }
    case Categories.drink: {
      category = "Boisson";
      break;
    }
    case Categories.jar: {
      category = "Bocal";
      break;
    }
    case Categories.frozen: {
      category = "Surgelé";
      break;
    }
    case Categories.seasoning: {
      category = "Assaisonnement";
      break;
    }
    case Categories.jam: {
      category = "Confiture";
      break;
    }
    case Categories.sweet: {
      category = "Sucrerie";
      break;
    }
  }

  return category;
};

export const renderQuantity = (product, addToCart) => {
  if (!product) return;

  if (
    product.category === Categories.snack ||
    product.category === Categories.sweet ||
    product.category === Categories.dessert
  ) {
    if (!product.quantityFields) return;

    return product.quantityFields.map((field, index) => (
      <div
        className="card-item-fields"
        key={index}
        onClick={() => addToCart(product, field)}
      >
        <p className="card-item-fields-quantity">x{field.productQuantity}</p>
        <p className="card-item-fields-price">
          {field.salePrice != null && field.salePrice !== 0 ? (
            <>
              <span className="sale-price">
                {parseFloat(field.salePrice).toFixed(2)}€
              </span>
              <span className="base-price-sale">
                {parseFloat(field.productPrice).toFixed(2)}€
              </span>
            </>
          ) : (
            <span className="base-price">
              {parseFloat(field.productPrice).toFixed(2)}€
            </span>
          )}
        </p>
      </div>
    ));
  } else if (
    product.category === Categories.meal ||
    product.category === Categories.accompaniment ||
    product.category === Categories.jar
  ) {
    if (!product.weightFields) return;

    return product.weightFields.map((field, index) => (
      <div
        className="card-item-fields"
        key={index}
        onClick={() => addToCart(product, field)}
      >
        <p className="card-item-fields-quantity">{field.productWeight}g</p>
        <p className="card-item-fields-price">
          {field.salePrice !== 0 ? (
            <>
              <span className="sale-price">
                {parseFloat(field.salePrice).toFixed(2)}€
              </span>
              <span className="base-price-sale">
                {parseFloat(field.productPrice).toFixed(2)}€
              </span>
            </>
          ) : (
            <span className="base-price">
              {parseFloat(field.productPrice).toFixed(2)}€
            </span>
          )}
        </p>
      </div>
    ));
  } else {
    if (!product.weightFields) return;
    return product.weightFields.map((field, index) => (
      <div
        className="card-item-fields"
        key={index}
        onClick={() => addToCart(product, field)}
      >
        <p className="card-item-fields-price alone">
          {field.salePrice !== 0 ? (
            <>
              <span className="sale-price">
                {parseFloat(field.salePrice).toFixed(2)}€
              </span>
              <span className="base-price-sale">
                {parseFloat(field.productPrice).toFixed(2)}€
              </span>
            </>
          ) : (
            <span className="base-price">
              {parseFloat(field.productPrice).toFixed(2)}€
            </span>
          )}
        </p>
      </div>
    ));
  }
};
