import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Socials from './components/socials';
import FormInput from '../../components/FormInput';
import FormButton from '../../components/FormButton';

import {emailSignInStart} from '../../redux/user/userActions';

import './styles.scss';
import {useNavigate} from 'react-router-dom';
import {checkIfUserIsAdmin} from '../../utilities/functions';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {currentUser} = useSelector((mapState) => mapState.userData);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (currentUser) navigate('/');
  }, [currentUser]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(emailSignInStart({email, password}));
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit}>
        <FormInput
          label="Email:"
          type="email"
          name="email"
          id="email"
          value={email}
          placeholder="Email"
          handleChange={(e) => setEmail(e.target.value)}
        />

        <FormInput
          label="Mot de passe:"
          type="password"
          name="password"
          id="password"
          value={password}
          placeholder="Password"
          handleChange={(e) => setPassword(e.target.value)}
        />
        <FormButton type="submit">Connexion</FormButton>
        <Socials />
      </form>
    </div>
  );
};

export default Login;
