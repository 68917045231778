import React from "react";
import {useSelector} from "react-redux";

import ProductCard from "./components/ProductCard";
import MenuTab from "./components/MenuTab";

import {Categories} from "../../../../utilities/enums";

import "./styles.scss";

const ProductList = ({activeCard}) => {
  const {products} = useSelector((mapState) => mapState.productData);

  const renderProducts = () => {
    if (activeCard === Categories.offer) {
      return products.map((product, index) => {
        if (product.offer) {
          return <ProductCard key={index} product={product} />;
        }
      });
    } else if (activeCard === Categories.menu) {
      return <MenuTab products={products} />;
    } else {
      return products.map((product, index) => {
        if (product.category === activeCard) {
          return <ProductCard key={index} product={product} />;
        }
      });
    }
  };

  return (
    <div className="product-list-container">
      <ul className="product-list">{renderProducts()}</ul>
    </div>
  );
};

export default ProductList;
