import React from "react";

import Clock from "./components/Clock";

import "./styles.scss";
import {
  getAllProductsOfOrder,
  isLessThanXMinutes,
  isToday,
} from "../../../../utilities/functions";
import OrderProductsContainer from "./components/OrderProductsContainer";

const KitchenColumn = ({orders}) => {
  /**
   * Get the currents && next X minutes pending orders
   * @param {int} minutes
   * @returns {array} of orders
   */
  const getNextXMinutesOrders = (minutes) => {
    const nextOrders = [];

    orders.map((order) => {
      if (order.scheduled && isLessThanXMinutes(minutes, order.scheduled)) {
        nextOrders.push(order);
      }

      if (!order.scheduled) nextOrders.push(order);
    });
    return nextOrders;
  };

  return (
    <div className="kitchen-column">
      <Clock />
      {orders.length > 0 && (
        <ul className="kitchen-order-products">
          {orders.map((order, index) => {
            if (order.scheduled && !isToday(order.scheduled)) return;
            const orderProducts = getAllProductsOfOrder(order);
            return (
              <OrderProductsContainer
                order={order}
                orderProducts={orderProducts}
                key={index}
              />
            );
          })}
        </ul>
      )}

      {/* <div className="next next-30">
        <h4>D'ici 30 minutes</h4>
        <ul>{renderProductsOrdered(30)}</ul>
      </div>
      <div className="next next-300">
        <h4>D'ici 5 heures</h4>
        <ul>{renderProductsOrdered(300)}</ul>
      </div> */}
    </div>
  );
};

export default KitchenColumn;
