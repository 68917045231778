import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import AdminBar from './Components/AdminBar';
import AdminProducts from './Components/AdminProducts';
import AdminOrders from './Components/AdminOrders';
import AdminEmail from './Components/AdminEmail';
import AdminMessages from './Components/AdminMessages';

import {fetchMessages} from '../../redux/app/appActions';

import './styles.scss';
import {fetchProductsStart} from '../../redux/product/productActions';
import {clearCurrentOrder} from '../../redux/order/orderActions';
import AdminLoyalty from './Components/AdminLoyalty';

const Admin = () => {
  const dispatch = useDispatch();
  const {customerMessages} = useSelector((mapState) => mapState.appData);
  const {products} = useSelector((mapState) => mapState.productData);
  const [activeItem, setActiveItem] = useState('products');

  useEffect(() => {
    dispatch(clearCurrentOrder());
  }, []);

  useEffect(() => {
    dispatch(fetchMessages());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (products.length < 1) {
      dispatch(fetchProductsStart());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  const renderPage = () => {
    switch (activeItem) {
      case 'products':
        return <AdminProducts products={products} />;
      case 'orders':
        return <AdminOrders />;
      case 'email':
        return <AdminEmail />;
      case 'messages':
        return <AdminMessages customerMessages={customerMessages} />;
      case 'loyalty':
        return <AdminLoyalty></AdminLoyalty>;
      default:
        return <AdminProducts />;
    }
  };

  return (
    <div className="admin-page">
      <AdminBar
        activeItem={activeItem}
        setActiveItem={setActiveItem}
        numberOfMessages={customerMessages.length}
      />
      {renderPage()}
    </div>
  );
};

export default Admin;
