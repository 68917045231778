import React, {useEffect, useState} from "react";
import {Categories, MenuTypes} from "../../../../../../../../utilities/enums";
import MenuProductCard from "./components/MenuProductCard";
import ClearIcon from "@mui/icons-material/Clear";
import Modal from "../../../../../../../../components/Modal";

import "./styles.scss";
import {translateCategory} from "../../../../../../../../utilities/functions";
import MixingContainer from "./components/MixingContainer";

const MenuProductsCategory = ({
  menuType,
  title,
  productCategory,
  products,
  updateSelection,
  selectedProduct,
  handleNoSelection,
}) => {
  const [hideModal, setHideModal] = useState(true);
  const [snacks, setSnacks] = useState(null);

  const toggleModal = () => {
    setHideModal(!hideModal);
  };

  const configModal = {hideModal, toggleModal};

  useEffect(() => {
    if (productCategory === Categories.snack && snacks === null) {
      setSnacks(
        products.filter((product) => product.category === Categories.snack)
      );
    }
  }, [snacks, productCategory, setSnacks]);

  const renderCategoryProducts = (category) => {
    let productSet = new Set();
    let productsByCategory = products.filter(
      (product) => product.category === category
    );

    return (
      <>
        {productsByCategory.map((product, index) => {
          if (productSet.has(product.slug)) return null;
          if (!product.inMenu) return null;
          productSet.add(product.slug);

          if (productCategory === "meal" && menuType === MenuTypes.SeaMenu) {
            if (!product.isSea) return;
          } else if (
            productCategory === "meal" &&
            menuType !== MenuTypes.SeaMenu
          ) {
            if (product.isSea) return;
          }

          return (
            <MenuProductCard
              menuType={menuType}
              key={product.slug}
              product={product}
              handleClick={updateSelection}
              selectedProduct={selectedProduct}
            />
          );
        })}
        {productsByCategory.length > 0 && (
          <>
            {category === Categories.snack && (
              <button
                className={`menu-product-card-item mix-button ${
                  Array.isArray(selectedProduct) && "menu-product-active"
                }`}
                onClick={() => toggleModal()}
              >
                Mélange de snacks
              </button>
            )}
            <button
              className={`menu-product-card-item no-product-button ${
                typeof selectedProduct === "string" && "menu-product-active"
              }`}
              onClick={handleNoSelection}
            >
              <ClearIcon className="icon" /> Pas {translateCategory(category)}
            </button>
          </>
        )}
      </>
    );
  };

  const renderProducts = () => {
    switch (productCategory) {
      case Categories.snack:
        return renderCategoryProducts(Categories.snack);
      case Categories.meal:
        return renderCategoryProducts(Categories.meal);
      case Categories.accompaniment:
        return renderCategoryProducts(Categories.accompaniment);
      case Categories.dessert:
        return renderCategoryProducts(Categories.dessert);
      case Categories.drink:
        return renderCategoryProducts(Categories.drink);
      case Categories.sandwich:
        return renderCategoryProducts(Categories.sandwich);
      case Categories.seasoning:
        return renderCategoryProducts(Categories.seasoning);
      default:
        return null;
    }
  };

  return (
    <div className="menu-products-category">
      <h2>Choisir {title}</h2>
      <div className="menu-product-list">{renderProducts()}</div>

      <Modal {...configModal}>
        <MixingContainer
          snacks={snacks}
          menuType={menuType}
          handleClick={updateSelection}
          toggleModal={toggleModal}
        />
      </Modal>
    </div>
  );
};

export default MenuProductsCategory;
