import React from "react";
import ChiliLevel from "../../../../../ProductCard/components/ChiliLevel";

import Halal from "../../../../../../../../../../assets/halal.png";
import Veggie from "../../../../../../../../../../assets/veggie.png";

import "./styles.scss";

const MenuProductCard = ({
  product,
  handleClick,
  selectedProduct,
  isSmall = false,
}) => {
  if (!product || !product.forSale) return;
  return (
    <li
      className={`menu-product-card-item ${
        selectedProduct === product && "menu-product-active"
      } ${isSmall && "menu-product-small"}`}
      onClick={() => !isSmall && handleClick(product)}
    >
      <div className="menu-product-card-item-name">
        <div className="menu-card-item-img">
          <img
            src={product.thumbnail}
            alt={product.name}
            className="main-picture"
          />
          <ChiliLevel chiliLevel={product.spiciness} size={"small"} />
        </div>

        <div className="food-habits-icons">
          {product.isHalal && (
            <img src={Halal} alt="halal" className="halal-icon" />
          )}

          {product.isVeggie && (
            <img src={Veggie} alt="veggie" className="veggie-icon" />
          )}
        </div>
        <h5 className="menu-card-item-name">{product.name}</h5>
      </div>
    </li>
  );
};

export default MenuProductCard;
