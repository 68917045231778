import {takeLatest, all, call, put} from "redux-saga/effects";

import orderTypes from "./orderTypes";
import {
  checkOrderNumberExists,
  getLastOrderNumber,
  handleAddOrder,
  handleDeleteOrder,
  handleGetOrder,
  handleGetOrdersByDate,
  handleGetPendingOrders,
  handleUpdateOrder,
  registerCustomer,
} from "./orderHelpers";
import {
  clearCurrentOrder,
  clearOrders,
  saveCurrentOrderUser,
  setOrder,
  setOrderNumber,
  setOrdersByDate,
  setPendingOrders,
} from "./orderActions";
import {setFeedbackMessage} from "../app/appActions";

import {OrderStatus, PaymentMethodEnum} from "../../utilities/enums";
import {
  addLoyaltyPoints,
  handleSetLoyaltyPointsUsage,
  removeLoyaltyPoints,
} from "../user/userHelpers";
import {fetchCustomerListStart} from "../user/userActions";
import {
  formatOrderNumber,
  getYearAndMonth,
  isEven,
} from "../../utilities/functions";

/** ANCHOR get pending orders */
export function* getPendingOrders({payload}) {
  try {
    const orders = yield handleGetPendingOrders(payload);
    yield put(setPendingOrders(orders));
  } catch (err) {
    console.log(err);
  }
}

export function* onGetPendingOrdersStart() {
  yield takeLatest(orderTypes.GET_PENDING_ORDERS_START, getPendingOrders);
}

/** ANCHOR send order */
export function* sendOrder({payload}) {
  try {
    const timeStamp = new Date();

    const orderNumber = yield call(getOrderNumber);

    if (!orderNumber) {
      throw new Error("Failed to generate order number");
    }

    const orderToAdd = {
      ...payload,
      orderTakenOnTheSpot: true,
      orderStatus: OrderStatus.Pending,
      orderCreatedDate: timeStamp,
      orderNumber: orderNumber,
    };

    if (orderToAdd.paymentMethod === PaymentMethodEnum.Cash) {
      const orderNumberDigits = parseInt(orderNumber.split("-")[1], 10);

      if (isEven(orderNumberDigits)) {
        orderToAdd.hidden = true;
      }
    }

    yield handleAddOrder(orderToAdd);
    // console.log(orderToAdd);
    if (orderToAdd.customer && orderToAdd.reward) {
      yield handleSetLoyaltyPointsUsage(orderToAdd.customer.id);
    }

    yield put(
      setFeedbackMessage({
        type: "success",
        text: "Commande envoyée en cuisine.",
      })
    );

    yield put(clearCurrentOrder());
  } catch (err) {
    console.log(err);
  }
}

export function* onSendOrderStart() {
  yield takeLatest(orderTypes.SEND_ORDER_START, sendOrder);
}

export function* closeOrder({payload}) {
  try {
    const timeStamp = new Date();

    console.log(payload);

    let orderToUpdate = {
      ...payload,
      orderStatus: OrderStatus.Completed,
    };

    if (!payload.orderNumber) {
      const orderNumber = yield call(getOrderNumber);

      if (!orderNumber) {
        throw new Error("Failed to generate order number");
      }

      orderToUpdate.orderNumber = orderNumber;
    }

    if (
      orderToUpdate.paymentMethod === PaymentMethodEnum.Cash &&
      !orderToUpdate.hidden
    ) {
      const orderNumberDigits = parseInt(
        orderToUpdate.orderNumber.split("-")[1],
        10
      );

      if (isEven(orderNumberDigits)) {
        orderToUpdate.hidden = true;
      }
    }

    if (!orderToUpdate.hasOwnProperty("orderTakenOnTheSpot")) {
      orderToUpdate.orderTakenOnTheSpot = true;
    }

    if (!orderToUpdate.orderCreatedDate) {
      orderToUpdate.orderCreatedDate = timeStamp;
    }

    yield handleUpdateOrder(orderToUpdate);

    if (orderToUpdate.reward) {
      yield removeLoyaltyPoints(
        orderToUpdate.customer.id,
        orderToUpdate.reward.cost
      );
    }

    if (orderToUpdate.customer) {
      yield addLoyaltyPoints(
        orderToUpdate.customer.id,
        Math.floor(orderToUpdate.orderTotal)
      );
    }

    yield put(
      setFeedbackMessage({
        type: "success",
        text: "Commande payée et récupérée par le client.",
      })
    );

    yield put(clearCurrentOrder());
  } catch (err) {
    console.log(err);
  }
}

export function* onCloseOrderStart() {
  yield takeLatest(orderTypes.CLOSE_ORDER_START, closeOrder);
}

/** ANCHOR user sign up */
export function* signUp({payload: {firstName, lastName, email}}) {
  try {
    const customer = yield registerCustomer(firstName, lastName, email);

    if (customer.isNewCustomer) {
      yield put(
        setFeedbackMessage({type: "success", text: "Inscription réussie"})
      );
      yield put(fetchCustomerListStart());
    } else {
      yield put(
        setFeedbackMessage({type: "warning", text: "Client déjà inscrit"})
      );
    }

    yield saveCurrentCustomer(
      customer.documentId,
      customer.email,
      customer.firstName,
      customer.lastName
    );
  } catch (err) {
    console.log(err.code);
  }
}

export function* onSignUpStart() {
  yield takeLatest(orderTypes.CUSTOMER_SIGN_UP_START, signUp);
}

export function* saveCurrentCustomer(customerId, email, firstName, lastName) {
  try {
    const customer = {
      id: customerId,
      email: email,
      firstName: firstName,
      lastName: lastName,
    };

    yield put(saveCurrentOrderUser(customer));
  } catch (err) {
    console.log(err);
  }
}

export function* fetchOrder({payload}) {
  try {
    const order = yield handleGetOrder(payload);
    yield put(setOrder(order));
  } catch (err) {
    console.log(err);
  }
}

export function* onFetchOrderStart() {
  yield takeLatest(orderTypes.FETCH_ORDER_START, fetchOrder);
}

export function* fetchOrderByDate({payload}) {
  try {
    const orders = yield handleGetOrdersByDate(payload);
    yield put(setOrdersByDate(orders));
  } catch (err) {
    console.log(err);
  }
}

export function* onFetchOrdersByDateStart() {
  yield takeLatest(orderTypes.FETCH_ORDERS_BY_DATE, fetchOrderByDate);
}

export function* deleteOrder({payload}) {
  try {
    yield handleDeleteOrder(payload);
    yield put(clearOrders());
  } catch (err) {
    console.log(err);
  }
}

export function* onDeleteOrderStart() {
  yield takeLatest(orderTypes.DELETE_ORDER, deleteOrder);
}

export function* getOrderNumber() {
  try {
    // Attempt to get the last order number
    const lastOrderNumber = yield call(getLastOrderNumber);
    const newOrderNumber = lastOrderNumber + 1;
    const orderDate = getYearAndMonth();
    const orderNumber = formatOrderNumber("S", orderDate, newOrderNumber);

    // Ensure the order number is unique before setting it
    const orderNumberExists = yield call(checkOrderNumberExists, orderNumber);
    if (orderNumberExists) {
      throw new Error("Order number already exists");
    }

    return orderNumber;
  } catch (err) {
    console.error("Error generating order number:", err);
    return null;
    // yield put(setOrderNumberError("Error generating order number"));
    // Fallback handling: retry logic or other mechanisms can be added here
  }
}

export function* onGetOrderNumberStart() {
  yield takeLatest(orderTypes.GET_ORDER_NUMBER_START, getOrderNumber);
}

export default function* orderSagas() {
  yield all([
    call(onGetPendingOrdersStart),
    call(onSendOrderStart),
    call(onSignUpStart),
    call(onFetchOrderStart),
    call(onCloseOrderStart),
    call(onFetchOrdersByDateStart),
    call(onDeleteOrderStart),
    call(onGetOrderNumberStart),
  ]);
}
