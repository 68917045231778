import React, {useState} from "react";
import {useDispatch} from "react-redux";
import FormSelect from "../../../../../../../../components/FormSelect";
import FormInput from "../../../../../../../../components/FormInput";
import FileUpload from "../../../../../../../../components/FileUpload";
import FormButton from "../../../../../../../../components/FormButton";
import MultipleInputArray from "../../../../../../../../components/MultipleInputArray";
import {editRewardStart} from "../../../../../../../../redux/reward/rewardActions";
import {uploadImage} from "../../../../../../../../services/firestore";
import {MenuTypes} from "../../../../../../../../utilities/enums";

import "./styles.scss";

const EditReward = ({hideModal, reward}) => {
  const dispatch = useDispatch();

  const [rewardCategory, setRewardCategory] = useState(reward.category);
  const [rewardName, setRewardName] = useState(reward.name);
  const [quantity, setQuantity] = useState(reward.quantity);
  const [rewardThumbnail, setRewardThumbnail] = useState(reward.thumbnail);
  const [hasOptions, setHasOptions] = useState(reward.hasOptions);
  const [rewardOptions, setRewardOptions] = useState(reward.options);
  const [rewardAvailability, setRewardAvailability] = useState(
    reward.availableAtAllLevel
  );
  const [rewardLevelAvailable, setRewardLevelAvailable] = useState(
    reward.levelAvailable
  );
  const [rewardPrice, setRewardPrice] = useState(reward.cost);
  const [isLoading, setIsLoading] = useState(false);

  const removeOptions = (optionToRemove) => {
    setRewardOptions((prevOptions) =>
      prevOptions.filter((option) => option !== optionToRemove)
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const editedReward = {
      category: rewardCategory,
      name: rewardName,
      quantity: quantity,
      hasOptions: hasOptions,
      options: rewardOptions,
      availableAtAllLevel: rewardAvailability,
      levelAvailable: rewardLevelAvailable,
      cost: parseInt(rewardPrice),
      documentId: reward.documentId,
    };

    try {
      if (rewardThumbnail instanceof File) {
        const downloadUrl = await uploadImage("rewardImages", rewardThumbnail);
        editedReward.thumbnail = downloadUrl;
      } else {
        editedReward.thumbnail = rewardThumbnail;
      }

      dispatch(editRewardStart(editedReward));
      hideModal(true);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const categoryOptions = [
    {value: "", disabled: true, name: "Selectionner une catégorie"},
    {value: "loyaltyLevel", name: "Récompense de fidélité"},
    {value: "menu", name: "Menu"},
    {value: "product", name: "Produit"},
  ];

  const menuOptions = [
    {value: "", disabled: true, name: "Selectionner un menu"},
    {value: MenuTypes.MenuFilao, name: MenuTypes.MenuFilao},
    {value: MenuTypes.MenuSandwich, name: MenuTypes.MenuSandwich},
    {value: MenuTypes.MenuKonple, name: MenuTypes.MenuKonple},
    {value: MenuTypes.TiMenu, name: MenuTypes.TiMenu},
  ];

  return (
    <div className="add-loyalty-reward-form">
      <form onSubmit={handleSubmit}>
        <h2>Éditer une récompense</h2>
        <div className="block1">
          <div className="left">
            <FileUpload
              setFile={setRewardThumbnail}
              label="Image de récompense"
              name="rewardThumbnail"
              id="rewardThumbnail"
              value={rewardThumbnail}
            />
          </div>

          <div className="right">
            <FormSelect
              label="Categorie"
              options={categoryOptions}
              value={rewardCategory}
              handleChange={(e) => setRewardCategory(e.target.value)}
            />

            {rewardCategory !== "menu" && (
              <FormInput
                label="Nom de la récompense"
                id="rewardName"
                name="rewardName"
                type="text"
                value={rewardName}
                placeholder="Nom de la récompense"
                handleChange={(e) => setRewardName(e.target.value)}
              />
            )}

            {rewardCategory === "menu" && (
              <FormSelect
                label="Choix du menu"
                options={menuOptions}
                value={rewardName}
                handleChange={(e) => setRewardName(e.target.value)}
              />
            )}

            <div className="checkboxes">
              <div className="available-checkbox">
                <p>Disponible à tous les niveaux ?</p>
                <div className="checkbox">
                  <button
                    type="button"
                    className={rewardAvailability ? "active" : ""}
                    onClick={() => setRewardAvailability(true)}
                  >
                    Oui
                  </button>
                  <button
                    type="button"
                    className={!rewardAvailability ? "active" : ""}
                    onClick={() => setRewardAvailability(false)}
                  >
                    Non
                  </button>
                </div>
              </div>
              {!rewardAvailability && (
                <div className="availability-level-checkbox">
                  <p>Disponible au niveau</p>
                  <div className="checkbox">
                    {[1, 2].map((level) => (
                      <button
                        key={level}
                        type="button"
                        className={
                          rewardLevelAvailable === level ? "active" : ""
                        }
                        onClick={() => setRewardLevelAvailable(level)}
                      >
                        {level}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>

            <FormInput
              label="Points requis"
              type="number"
              min={20}
              max={200}
              step={5}
              id="rewardPrice"
              name="rewardPrice"
              value={rewardPrice}
              handleChange={(e) => setRewardPrice(e.target.value)}
            />
          </div>
        </div>

        {rewardCategory === "product" && (
          <>
            <div className="block2">
              <div className="left">
                <FormInput
                  label="Quantité"
                  type="number"
                  min={1}
                  max={12}
                  step={1}
                  id="quantity"
                  name="quantity"
                  value={quantity}
                  handleChange={(e) => setQuantity(e.target.value)}
                />
              </div>

              <div className="right">
                <div className="checkboxes">
                  <div className="options-checkbox">
                    <p>Options ?</p>
                    <div className="checkbox">
                      <button
                        type="button"
                        className={hasOptions ? "active" : ""}
                        onClick={() => setHasOptions(true)}
                      >
                        Oui
                      </button>
                      <button
                        type="button"
                        className={!hasOptions ? "active" : ""}
                        onClick={() => setHasOptions(false)}
                      >
                        Non
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {hasOptions && (
              <MultipleInputArray
                label="Options"
                name="rewardOptions"
                title="Liste des options"
                placeholder="Ajouter une option"
                array={rewardOptions}
                addToArray={setRewardOptions}
                removeFromArray={removeOptions}
              />
            )}
          </>
        )}

        <FormButton type="submit" disabled={isLoading}>
          {isLoading ? "Envoi en cours..." : "Editer la récompense"}
        </FormButton>
      </form>
    </div>
  );
};

export default EditReward;
