import {productError} from "../redux/product/productActions";
import {store} from "../redux/createStore";

export const hasAddProductFormErrors = (product) => {
  let error = false;
  // console.log(product);

  if (!product.category) {
    store.dispatch(
      productError({
        input: "category",
        text: "Selectionner une catégorie",
      })
    );
    error = true;
  }

  if (!product.description) {
    store.dispatch(
      productError({
        input: "description",
        text: "Saisir une description",
      })
    );
    error = true;
  }

  if (!product.name) {
    store.dispatch(
      productError({
        input: "name",
        text: "Saisir un nom",
      })
    );
    error = true;
  }

  return error;
};

export const hasInputError = (errors, field) => {
  if (Array.isArray(errors) && errors.length > 0) {
    return errors.find((error) => error.input === field);
  }
  return false;
};
