import React from "react";
import "./styles.scss";

const PointsTier = ({
  activePoints,
  changeActivePoints,
  rewards,
  setIsLoading,
  customerPoints,
}) => {
  const renderPointList = () => {
    if (rewards.length < 1) return;

    const pointList = [];

    rewards.forEach((reward) => {
      if (!pointList.includes(reward.cost) && reward.enabled) {
        pointList.push(reward.cost);
      }
    });

    pointList.sort((a, b) => a - b);

    return pointList.map((point, index) => (
      <li
        key={index}
        className={`tier-item ${activePoints === point ? "active" : ""} ${
          customerPoints < point ? "disabled" : ""
        }`}
        onClick={() => {
          if (customerPoints >= point) {
            setIsLoading(true);
            changeActivePoints(point);
            setIsLoading(false);
          }
        }}
      >
        {point} points
      </li>
    ));
  };

  return <ul className="points-tier">{renderPointList()}</ul>;
};

export default PointsTier;
