import {Categories} from './enums';

export const calculatePercentageUsed = (product) => {
  if (product.salePrice) {
    const totalPercentage = (product.salePrice * 100) / product.price;
    const percentage = 100 - totalPercentage;

    return percentage;
  }
  return 0;
};

export const returnPercentageReduction = (percentage, productPrice) => {
  const percentageReduction = (percentage * productPrice) / 100;
  return percentageReduction;
};

export const formatCategory = (product) => {
  if (!product) return;

  let category = '';

  switch (product.category) {
    case Categories.Menu: {
      category = 'Menu';
      break;
    }
    case Categories.snack: {
      category = 'Snack';
      break;
    }
    case Categories.sandwich: {
      category = 'Sandwich';
      break;
    }
    case Categories.appetizer: {
      category = 'Entrée';
      break;
    }
    case Categories.meal: {
      category = 'Plat';
      break;
    }
    case Categories.accompaniment: {
      category = 'Accompagnement';
      break;
    }
    case Categories.dessert: {
      category = 'Dessert';
      break;
    }
    case Categories.drink: {
      category = 'Boisson';
      break;
    }
    case Categories.jar: {
      category = 'Bocal';
      break;
    }
    case Categories.frozen: {
      category = 'Surgelé';
      break;
    }
    case Categories.seasoning: {
      category = 'Assaisonnement';
      break;
    }
    case Categories.jam: {
      category = 'Confiture';
      break;
    }
    case Categories.sweet: {
      category = 'Sucrerie';
      break;
    }
  }

  return category;
};

export const iconConfig = {
  style: {
    fontSize: '20px',
    marginRight: '2px',
    position: 'relative',
    top: '5px',
  },
};
