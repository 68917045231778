import React from "react";
import {useDispatch} from "react-redux";

import ChiliLevel from "./components/ChiliLevel";
import Halal from "../../../../../../assets/halal.png";
import Veggie from "../../../../../../assets/veggie.png";

import {formatCategory, renderQuantity} from "./helpers";
import {addProductToCurrentOrder} from "../../../../../../redux/order/orderActions";

import "./styles.scss";

const ProductCard = ({product}) => {
  const dispatch = useDispatch(); //added

  //added
  const handleAddToCart = (product, productField) => {
    if (!product || !productField) return;
    product.selectedField = productField;

    dispatch(addProductToCurrentOrder(product, 1));
  };

  if (!product || !product.forSale) return;

  return (
    <>
      <li className="product-card-item">
        <div className="card-item-picture">
          <figure
            className="card-item-pic-wrap"
            data-category={formatCategory(product)}
          >
            <div className="food-habits-icons">
              {product.isHalal && (
                <img src={Halal} alt="halal" className="halal-icon" />
              )}

              {product.isVeggie && (
                <img src={Veggie} alt="veggie" className="veggie-icon" />
              )}
            </div>

            <img
              src={product.thumbnail}
              alt={product.name}
              className="card-item-img"
            />
            <ChiliLevel chiliLevel={product.spiciness} size={"small"} />
          </figure>
          <div className="card-item-info">
            <h5 className="card-item-name">{product.name}</h5>
            <div className="card-item-quantity-and-price">
              {renderQuantity(product, handleAddToCart)}
            </div>
          </div>
        </div>
      </li>
    </>
  );
};

export default ProductCard;
