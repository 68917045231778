import React from "react";
import CategoryCard from "./components/CategoryCard";
import {Categories} from "../../../../utilities/enums";

import "./styles.scss";
import {checkIfCategoryHasProducts} from "../../../../utilities/functions";

const MenuCategories = ({activeCard, updateActiveCard, products}) => {
  const renderCategories = () => {
    const categories = Object.keys(Categories);
    return categories.map((category, index) => {
      if (category !== Categories.offer) {
        // Check if category has products
        if (
          checkIfCategoryHasProducts(category, products).length < 1 &&
          category !== Categories.menu
        )
          return;
        return (
          <CategoryCard
            key={index}
            category={category}
            handleClick={() => updateActiveCard(category)}
            activeCard={activeCard}
          />
        );
      }
    });
  };
  return <div className="menu-categories">{renderCategories()}</div>;
};

export default MenuCategories;
