import React from "react";
import {Outlet} from "react-router-dom";

const KitchenLayout = (props) => {
  return (
    <div className="kitchen-main">
      {props.children ? props.children : <Outlet />}
    </div>
  );
};

export default KitchenLayout;
