import appTypes from './appTypes';

/** ANCHOR Feedback messages */
export const setFeedbackMessage = (message) => ({
  type: appTypes.SET_FEEDBACK_MESSAGE,
  payload: message,
});

export const resetFeedbackMessage = () => ({
  type: appTypes.RESET_FEEDBACK_MESSAGE,
});

/** ANCHOR Customer messages */
export const fetchMessages = () => ({
  type: appTypes.FETCH_MESSAGE_START,
});

export const setMessages = (messages) => ({
  type: appTypes.SET_MESSAGES,
  payload: messages,
});

export const deleteMessage = (messageId) => ({
  type: appTypes.DELETE_MESSAGE_START,
  payload: messageId,
});

/** ANCHOR Error messages */
export const messageError = (errorCode) => ({
  type: appTypes.MESSAGE_ERROR,
  payload: errorCode,
});

export const resetMessageError = () => ({
  type: appTypes.RESET_MESSAGE_ERROR,
});
