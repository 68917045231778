import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";

import OrderCheckoutTicket from "./components/OrderCheckoutTicket";
import PreviousButton from "./components/PreviousButton";
import CustomerSelect from "./components/CustomerSelect";
import PaymentMethod from "./components/PaymentMethod";
import CheckoutButtons from "./components/CheckoutButtons";
import Modal from "../../components/Modal";
import OrderPaidConfirmMessage from "./components/OrderPaidConfirmMessage";
import OrderUnpaidConfirmMessage from "./components/OrderUnpaidConfirmMessage";

import "./styles.scss";
import {
  clearCustomerList,
  fetchCustomerListStart,
} from "../../redux/user/userActions";
import {isCartEmpty} from "../../utilities/functions";
import {fetchOrderStart} from "../../redux/order/orderActions";
import OrderTakenConfirmMessage from "./components/OrderTakenConfirmMessage";
import Loader from "../../components/Loader";
import DeleteButton from "./components/DeleteButton";

const OrderCheckout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {order} = useParams();
  const isNewOrder = order === "nouvelle-commande";

  const {customerList} = useSelector((mapState) => mapState.userData);
  const {currentOrder} = useSelector((mapState) => mapState.orderData);

  const [hidePaidModal, setHidePaidModal] = useState(true);
  const [hideUnpaidModal, setHideUnpaidModal] = useState(true);
  const [hideOrderTakenModal, setHideOrderTakenModal] = useState(true);

  const togglePaidModal = () => {
    setHidePaidModal(!hidePaidModal);
  };

  const toggleUnpaidModal = () => {
    setHideUnpaidModal(!hideUnpaidModal);
  };

  const toggleOrderTakenModal = () => {
    setHideOrderTakenModal(!hideOrderTakenModal);
  };

  useEffect(() => {
    if (order !== "nouvelle-commande") {
      dispatch(fetchOrderStart(order));
    }
  }, []);

  useEffect(() => {
    if (customerList && customerList.length < 1) {
      dispatch(fetchCustomerListStart());
    }
  }, [customerList]);

  useEffect(() => {
    // console.log(currentOrder);
    // if (!currentOrder.orderNumber) {
    //   dispatch(getOrderNumber());
    // }

    return () => {
      dispatch(clearCustomerList());
    };
  }, []);

  useEffect(() => {
    if (isCartEmpty(currentOrder) && order === "nouvelle-commande") {
      return navigate("/");
    }
  }, [currentOrder]);

  if (isCartEmpty(currentOrder)) return <Loader />;

  return (
    <>
      <div className="order-checkout-container">
        <OrderCheckoutTicket />
        <div className="order-checkout">
          <PreviousButton />
          <DeleteButton order={currentOrder} />

          <div className="order-checkout-functions">
            <CustomerSelect currentOrder={currentOrder} />
            <PaymentMethod order={currentOrder} />
            <CheckoutButtons
              isNewOrder={isNewOrder}
              togglePaidModal={togglePaidModal}
              toggleUnpaidModal={toggleUnpaidModal}
              toggleOrderTakenModal={toggleOrderTakenModal}
            />
          </div>
        </div>
      </div>
      <Modal
        hideModal={hidePaidModal}
        toggleModal={togglePaidModal}
        additionnalClass={"noverflow"}
      >
        <OrderPaidConfirmMessage
          closeModal={togglePaidModal}
          currentOrder={currentOrder}
        />
      </Modal>

      <Modal
        hideModal={hideUnpaidModal}
        toggleModal={toggleUnpaidModal}
        additionnalClass={"noverflow"}
      >
        <OrderUnpaidConfirmMessage
          closeModal={toggleUnpaidModal}
          currentOrder={currentOrder}
        />
      </Modal>

      <Modal
        hideModal={hideOrderTakenModal}
        toggleModal={toggleOrderTakenModal}
      >
        <OrderTakenConfirmMessage
          closeModal={toggleOrderTakenModal}
          currentOrder={currentOrder}
        />
      </Modal>
    </>
  );
};

export default OrderCheckout;
