import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {resetFeedbackMessage} from '../../redux/app/appActions';

import './styles.scss';

const FeedbackMessage = () => {
  const dispatch = useDispatch();
  const {feedbackMessage} = useSelector((mapState) => mapState.appData);

  useEffect(() => {
    if (feedbackMessage) {
      const updateFeedbackMessage = setTimeout(() => {
        dispatch(resetFeedbackMessage());
      }, 5000);

      return () => clearTimeout(updateFeedbackMessage);
    }
  }, [feedbackMessage]);

  if (!feedbackMessage) return;

  return (
    <div
      className={`feedback-message feedback-message-${feedbackMessage.type}`}
    >
      {feedbackMessage.text}
    </div>
  );
};

export default FeedbackMessage;
