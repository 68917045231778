import React, {useEffect, useState} from "react";

import "./styles.scss";
import FormButton from "../../../../components/FormButton";
import Modal from "../../../../components/Modal";
import AddLoyaltyReward from "./components/AddLoyaltyReward";
import RewardList from "./components/RewardList";
import {useDispatch, useSelector} from "react-redux";
import {fetchRewardsStart} from "../../../../redux/reward/rewardActions";

const AdminLoyalty = () => {
  const dispatch = useDispatch();
  const [hideModal, setHideModal] = useState(true);

  const {rewards} = useSelector((mapState) => mapState.rewardData);

  useEffect(() => {
    dispatch(fetchRewardsStart());
  }, [rewards.length]);

  const toggleModal = () => {
    setHideModal(!hideModal);
  };

  const configModal = {hideModal, toggleModal};

  return (
    <div className="admin-loyalty">
      <h2>Programme fidélité</h2>
      <div className="admin-button">
        <FormButton onClick={() => toggleModal()}>
          Ajouter une récompense
        </FormButton>
      </div>

      <Modal {...configModal} size="big">
        <AddLoyaltyReward hideModal={setHideModal} rewards={rewards} />
      </Modal>

      <RewardList rewards={rewards} />
    </div>
  );
};

export default AdminLoyalty;
