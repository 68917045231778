import {firestore} from '../../services/firestore';

export const handleFetchMessages = () => {
  return new Promise((resolve, reject) => {
    firestore
      .collection('messages')
      .get()
      .then((snap) => {
        const data = [
          ...snap.docs.map((doc) => {
            return {
              ...doc.data(),
              documentId: doc.id,
            };
          }),
        ];
        resolve([...data]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleDeleteMessage = (messageId) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection('messages')
      .doc(messageId)
      .delete()
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};
