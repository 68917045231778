export const Categories = {
  offer: "offer",
  menu: "menu",
  snack: "snack",
  meal: "meal",
  accompaniment: "accompaniment",
  sandwich: "sandwich",
  dessert: "dessert",
  drink: "drink",
  jar: "jar",
  frozen: "frozen",
  seasoning: "seasoning",
  jam: "jam",
  sweet: "sweet",
};

export const OrderStatus = {
  Pending: "pending",
  Completed: "completed",
};

export const MenuTypes = {
  TiMenu: "Menu Marmaille",
  MenuFilao: "Menu Filao",
  MenuKonple: "Menu Konplé",
  MenuSandwich: "Menu Américain",
  SeaMenu: "Menu de la Mer",
};

export const PaymentMethodEnum = {
  CreditCard: "Carte bancaire",
  Cash: "Espèces",
  ApplePay: "Apple Pay",
  GPay: "Google Pay",
  Paypal: "Paypal",
};

export const MenuPrice = {
  MenuSandwich: 9.9,
  MenuFilao: 12.9,
  MenuKonple: 16.9,
  TiMenu: 5,
  SeaMenu: 19.9,
};

export const MenuSnackQuantity = {
  MenuFilao: 4,
  MenuKonple: 6,
  TiMenu: 2,
  SeaMenu: 4,
};

export const MenuOption = {
  Snack: "Choix du snack",
  Meal: "Choix du plat",
  Accompaniment: "Choix de l'accompagnement",
  Dessert: "Choix du dessert",
  Sandwich: "Choix du sandwich",
  Drink: "Choix de la boisson",
  Sauce: "Choix de la sauce",
};
