import React, {useState} from "react";
import {
  MenuOption,
  Categories,
  MenuTypes,
} from "../../../../../../../../utilities/enums";
import {useSelector} from "react-redux";
import "./styles.scss";

const MenuOptions = ({
  selectedReward,
  menuOption,
  setSandwich,
  setDrink,
  setSauce,
  setSnack,
  setMeal,
  setAccompaniment,
  setSecondAccompaniment,
  setDessert,
}) => {
  const {products} = useSelector((state) => state.productData);
  const [selectedItem, setSelectedItem] = useState(null);

  const renderQuantity = (categories) => {
    if (categories !== Categories.snack) return;

    switch (selectedReward.name) {
      case MenuTypes.TiMenu:
        return 2;
      case MenuTypes.MenuFilao:
        return 4;
      case MenuTypes.MenuKonple:
        return 6;
      default:
        return 1;
    }
  };

  const setMenuProduct = (categories, productName) => {
    setSelectedItem(productName); // Update the selected item

    switch (categories) {
      case Categories.snack:
        return setSnack(renderQuantity(categories) + " " + productName);
      case Categories.meal:
        return setMeal(productName);
      case Categories.accompaniment:
        return setAccompaniment(productName);
      case Categories.secondAccompaniment:
        return setSecondAccompaniment(productName);
      case Categories.sandwich:
        return setSandwich(productName);
      case Categories.drink:
        return setDrink(productName);
      case Categories.dessert:
        return setDessert(productName);
      case "sauce":
        return setSauce(productName);
      default:
        return;
    }
  };

  const returnProducts = (categories) => {
    if (products.length < 1) return [];

    return products
      .filter(
        (product) =>
          product.category === categories && product.forSale && product.inMenu
      )
      .map((product, index) => (
        <li
          key={index}
          className={`reward-menu-option-item ${
            selectedItem === product.name ? "selected" : ""
          }`}
          onClick={() => setMenuProduct(categories, product.name)}
        >
          {renderQuantity(categories)} {product.name}
        </li>
      ));
  };

  const renderRewardMenuOptions = (menuOption) => {
    if (!menuOption) return;

    switch (menuOption) {
      case MenuOption.Snack:
        return returnProducts(Categories.snack);
      case MenuOption.Meal:
        return returnProducts(Categories.meal);
      case MenuOption.Accompaniment:
        return returnProducts(Categories.accompaniment);
      case MenuOption.Dessert:
        return returnProducts(Categories.dessert);
      case MenuOption.Sandwich:
        return returnProducts(Categories.sandwich);
      case MenuOption.Drink:
        return returnProducts(Categories.drink);
      case MenuOption.Sauce:
        return (
          <>
            <li
              className={`reward-menu-option-item ${
                selectedItem === "Mayonnaise" ? "selected" : ""
              }`}
              onClick={() => setMenuProduct("sauce", "Mayonnaise")}
            >
              Mayonnaise
            </li>
            <li
              className={`reward-menu-option-item ${
                selectedItem === "Ketchup" ? "selected" : ""
              }`}
              onClick={() => setMenuProduct("sauce", "Ketchup")}
            >
              Ketchup
            </li>
          </>
        );
      default:
        return;
    }
  };

  return (
    selectedReward &&
    menuOption && (
      <ul className="reward-menu-options">
        {renderRewardMenuOptions(menuOption)}
      </ul>
    )
  );
};

export default MenuOptions;
