import React, {useState} from 'react';
import Modal from '../../../../components/Modal';

import {useDispatch} from 'react-redux';
import {setFeedbackMessage} from '../../../../redux/app/appActions';
import {useNavigate} from 'react-router-dom';
import {deleteOrderStart} from '../../../../redux/order/orderActions';

import './styles.scss';

const DeleteButton = ({order}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [hideModal, setHideModal] = useState(true);

  const toggleModal = () => {
    setHideModal(!hideModal);
  };

  const deleteOrder = () => {
    if (order.orderPaid) return;

    if (order.orderStatus) {
      dispatch(deleteOrderStart(order));
    }

    dispatch(
      setFeedbackMessage({
        type: 'success',
        text: 'Commande supprimée',
      })
    );
    toggleModal();
    navigate('/');
  };

  if (order.orderPaid) return;

  return (
    <>
      <button className="delete-order-button" onClick={() => toggleModal()}>
        X
      </button>
      <Modal hideModal={hideModal} toggleModal={toggleModal}>
        <div className="send-ticket-confirm-message">
          <p>
            <span className="warning-icon">/ ! \</span> Êtes-vous sur de vouloir
            supprimer cette commande ?
          </p>
          <div className="buttons">
            <button className="confirm-button" onClick={() => deleteOrder()}>
              Oui
            </button>{' '}
            <button className="decline-button" onClick={() => toggleModal()}>
              Non
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeleteButton;
