import React from 'react';

import './styles.scss';

const CheckoutButtons = ({
  togglePaidModal,
  toggleUnpaidModal,
  toggleOrderTakenModal,
  isNewOrder,
}) => {
  return (
    <div className="checkout-buttons">
      {isNewOrder && (
        <>
          <button onClick={() => togglePaidModal()}>Commande payée</button>
          <button onClick={() => toggleUnpaidModal()}>
            Régler la commande à la reception
          </button>
        </>
      )}
      <button onClick={() => toggleOrderTakenModal()}>
        Commande payée et récupérée
      </button>
    </div>
  );
};

export default CheckoutButtons;
