import React, {useEffect} from 'react';

import './styles.scss';
import Homebar from '../../components/Homebar';
import ManagePendingOrders from './components/ManagePendingOrders';
import {useDispatch} from 'react-redux';
import {clearCurrentOrder} from '../../redux/order/orderActions';

const ManageOrders = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearCurrentOrder());
  }, []);

  return (
    <div className="manage-orders">
      <Homebar title={'Gestion des commandes'} />
      <ManagePendingOrders />
    </div>
  );
};

export default ManageOrders;
