import React from 'react';
import {useNavigate} from 'react-router-dom';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import './styles.scss';
import ColorCaption from '../ColorCaption';

const Homebar = ({title}) => {
  const navigate = useNavigate();

  return (
    <div className="homebar">
      <div className="title-and-caption">
        <h1>{title}</h1>
        <ColorCaption />
      </div>
      <div
        className="take-order-button"
        onClick={() => navigate('/commandes/prise-de-commande')}
      >
        <span>Prendre une commande</span>
        <AddCircleOutlineIcon className="add-order-button" />
      </div>
    </div>
  );
};

export default Homebar;
