import React, {useEffect, useState} from 'react';

import Modal from '../../../../components/Modal';
import CustomerSigninForm from './components/CustomerSigninForm';
import SearchBar from './components/SearchBar';

import './styles.scss';
import {useDispatch, useSelector} from 'react-redux';
import LoyaltyProductsButton from './components/LoyaltyProductsButton';
import {removeRewardFromOrder} from '../../../../redux/order/orderActions';

const CustomerSelect = ({currentOrder}) => {
  const dispatch = useDispatch();
  const {customerLoyalty} = useSelector((mapState) => mapState.userData);
  const [hideModal, setHideModal] = useState(true);

  useEffect(() => {
    if (currentOrder.customer) {
    }
  }, [currentOrder.customer]);

  const toggleModal = () => {
    setHideModal(!hideModal);
  };

  const configModal = {hideModal, toggleModal};

  return (
    <>
      <div className="customer-select">
        {currentOrder.orderStatus ? (
          <p className="customer-already-valid">
            Client déjà validé pour cette commande.
          </p>
        ) : (
          <>
            <h2>Selectionner le client</h2>
            <SearchBar />
            <div className="points-and-sign-in">
              <div>
                {currentOrder.customer && customerLoyalty.loyaltyLevel > 0 && (
                  <div className="customer-loyalty-points">
                    Fidélité niveau <span>{customerLoyalty.loyaltyLevel}</span>{' '}
                    --- <span>{customerLoyalty.loyaltyPoints}</span> points.
                    <LoyaltyProductsButton customerLoyalty={customerLoyalty} />
                    {currentOrder.reward && (
                      <button
                        className="remove-reward-button"
                        onClick={() => dispatch(removeRewardFromOrder())}
                      >
                        Retirer
                      </button>
                    )}
                  </div>
                )}
              </div>

              <div className="button-container">
                <button onClick={() => toggleModal()}>
                  Inscrire un client
                </button>
              </div>
            </div>

            <Modal {...configModal}>
              <CustomerSigninForm
                toggleModal={toggleModal}
                currentOrder={currentOrder}
              />
            </Modal>
          </>
        )}
      </div>
    </>
  );
};

export default CustomerSelect;
