import {takeLatest, call, all, put} from 'redux-saga/effects';

import userTypes from './userTypes';
import {GoogleProvider, auth, handleAdminLogin} from '../../services/firestore';
import {setCustomerList, signInSuccess} from './userActions';
import {getAllCustomers, handleAddCustomerPoints} from './userHelpers';

/** ANCHOR Google Sign In */
export function* googleSignIn() {
  try {
    const {user} = yield auth.signInWithPopup(GoogleProvider);

    yield getSnapshotFromUserAuth(user);
  } catch (err) {
    console.log(err);
  }
}

export function* onGoogleSignInStart() {
  yield takeLatest(userTypes.GOOGLE_SIGN_IN_START, googleSignIn);
}

/** ANCHOR User Sign In */
export function* getSnapshotFromUserAuth(user) {
  try {
    const userRef = yield call(handleAdminLogin, {
      userAuth: user,
    });
    const snapshot = yield userRef.get();

    if (snapshot.data().userRoles.includes('admin')) {
      yield put(signInSuccess({id: snapshot.id, ...snapshot.data()}));
    }
  } catch (err) {
    console.log(err);
  }
}

/** ANCHOR Customer list */
export function* fetchCustomerList() {
  try {
    const customers = yield getAllCustomers();
    yield put(setCustomerList(customers));
  } catch (err) {
    console.log(err);
  }
}

export function* onFetchCustomerListStart() {
  yield takeLatest(userTypes.FETCH_CUSTOMER_LIST_START, fetchCustomerList);
}

/** ANCHOR user SAGAS */
export default function* userSagas() {
  yield all([call(onGoogleSignInStart), call(onFetchCustomerListStart)]);
}
