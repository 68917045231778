import React from 'react';
import {Categories} from '../../../../../../utilities/enums';
import './styles.scss';

const CategoryCard = ({category, handleClick, activeCard}) => {
  const renderCategoryName = () => {
    switch (category) {
      case Categories.offer:
        return 'Bons plans';
      case Categories.menu:
        return 'Menus';
      case Categories.snack:
        return 'Snacks / Entrées';
      case Categories.meal:
        return 'Plats';
      case Categories.accompaniment:
        return 'Accompagnements';
      case Categories.sandwich:
        return 'Sandwichs';
      case Categories.dessert:
        return 'Desserts';
      case Categories.drink:
        return 'Boissons';
      case Categories.jar:
        return 'Bocaux';
      case Categories.frozen:
        return 'Surgelés';
      case Categories.seasoning:
        return 'Assaisonnements';
      case Categories.jam:
        return 'Confitures';
      case Categories.sweet:
        return 'Sucreries';
      default:
        return 'Nom de catégorie';
    }
  };

  return (
    <div
      className={`category-card ${category} ${
        category === activeCard && 'active-category'
      }`}
      onClick={handleClick}
    >
      {renderCategoryName()}
    </div>
  );
};

export default CategoryCard;
