import React from 'react';

import './styles.scss';

const Modal = ({hideModal, toggleModal, additionnalClass, children, size}) => {
  if (hideModal) return null;

  return (
    <>
      <div className="modal-overlay" onClick={() => toggleModal()} />
      <div className="modal-wrap">
        <div
          className={`modal ${additionnalClass && additionnalClass} ${
            size === 'big' && 'large-modal'
          }`}
        >
          {children}
        </div>
      </div>
    </>
  );
};

export default Modal;
