import {firestore} from "../../services/firestore";
import {doc, updateDoc} from "firebase/firestore";
import {stringToSlug} from "../../utilities/functions";

export const handleFetchProducts = () => {
  return new Promise((resolve, reject) => {
    let ref = firestore.collection("productsv2").orderBy("createdDate");

    ref
      .get()
      .then((snapshot) => {
        const data = [
          ...snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              documentId: doc.id,
            };
          }),
        ];
        resolve([...data]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const isProductThumbnailUsed = (thumbnail) => {
  const productCollection = firestore.collection("productsv2");

  productCollection.get().then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      if (doc.data().thumbnail === thumbnail) {
        console.log("thumbnail used");
        return true;
      }
    });
  });
  return false;
};

export const handleEditProduct = async (product) => {
  const productRef = doc(firestore, "productsv2", product.documentId);

  const slug = stringToSlug(product.name);
  product.slug = slug;
  return new Promise((resolve, reject) => {
    updateDoc(productRef, product)
      .then(() => {
        resolve(product);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleAddProduct = (product) => {
  const slug = stringToSlug(product.name);

  const productToAdd = {...product, offer: false, slug: slug};
  return new Promise((resolve, reject) => {
    firestore
      .collection("productsv2")
      .doc()
      .set(productToAdd)
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleDeleteProduct = (productId) => {
  return new Promise((resolve, reject) => {
    try {
      let productRef = firestore.collection("productsv2").doc(productId);
      productRef.delete();
      resolve();
    } catch (err) {
      reject(err);
    }
  });
};

export const handleReducerProductsUpdate = (productList, editedProduct) => {
  const elementToRemove = (element) =>
    element.documentId === editedProduct.documentId;
  const index2 = productList.findIndex(elementToRemove);

  if (index2 > -1) {
    console.log("test");
    // only splice array when item is found
    productList.splice(index2, 1, editedProduct); // 2nd parameter means remove one item only
  }

  return productList;
};
