import React from "react";
import "./styles.scss";

const RewardChoice = ({
  activePoints,
  customerLoyalty,
  setSelectedReward,
  selectedReward,
  rewards,
}) => {
  const renderRewardChoice = (pointsRequired) => {
    if (rewards.length < 1 || activePoints === 0) return;

    return rewards.map((reward, index) => {
      if (reward.cost === pointsRequired && reward.enabled) {
        return (
          <li
            key={index}
            className={`reward-choice-item ${
              selectedReward === reward ? "selected" : ""
            } ${
              activePoints > customerLoyalty.loyaltyPoints ? "disabled" : ""
            }`}
            onClick={() => selectReward(reward)}
          >
            <img
              src={reward.thumbnail}
              alt={reward.name}
              className="reward-image"
            />
            <div className="reward-details">
              <span className="reward-name">
                {reward.quantity > 1
                  ? `${reward.quantity} ${reward.name}`
                  : reward.name}
              </span>
              <span className="reward-cost">{reward.cost} points</span>
            </div>
          </li>
        );
      }
    });
  };

  const selectReward = (reward) => {
    if (activePoints > customerLoyalty.loyaltyPoints) return;
    setSelectedReward(reward);
  };

  return <ul className="reward-choices">{renderRewardChoice(activePoints)}</ul>;
};

export default RewardChoice;
