import React from "react";

import "./styles.scss";

const ProductPrice = ({product, size = "normal"}) => {
  const renderWithSale = () => {
    if (
      product.category === "snack" ||
      product.category === "sugar" ||
      product.category === "dessert"
    ) {
      const hasSalePrice = product.quantityFields.some(
        (field) => field.salePrice && field.salePrice !== 0
      );
      if (hasSalePrice) {
        return <p className="in-sale">En Promotion</p>;
      }
    } else {
      const hasSalePrice = product.weightFields.some(
        (field) => field.salePrice !== 0
      );
      if (hasSalePrice) {
        return <p className="in-sale">En Promotion</p>;
      }
    }
  };

  const renderBasePrice = () => {
    if (
      product.category === "snack" ||
      product.category === "sugar" ||
      product.category === "dessert"
    ) {
      const prefix = product.quantityFields.length > 1 ? "à partir de " : "";
      return (
        <p>
          {prefix}
          {parseFloat(product.quantityFields[0].productPrice).toFixed(2)}€
        </p>
      );
    } else {
      const prefix = product.weightFields.length > 1 ? "à partir de " : "";
      return (
        <p>
          {prefix} {parseFloat(product.weightFields[0].productPrice).toFixed(2)}
          €
        </p>
      );
    }
  };

  return (
    <div className="card-item-price">
      {renderWithSale()}
      {renderBasePrice()}
    </div>
  );
};

export default ProductPrice;
